<template>
  <v-row>
    <v-col lg="12" md="12" sm="12" align="center">
      <div class="login-panel">
        <div class="body-container">
          <div class="drop-page-responsive">
            <div>
              <img style="width:100px;" src="@/assets/error12.svg" @error="replaceByDefault" />
            </div>
            <div class="text-left" style="color: #000000; font-size: 20px; font-weight: 600;max-width:360px;margin-left:50px;padding-top:18px; ">
              {{errorMessage}}
            </div>
            <!-- <p class="error-message" style="color: #000000; font-size: 19px; font-weight: 600 ">{{errorMessage}}</p> -->
          </div>
          <div v-if="showIllus" style="text-align:center;margin-top:5px;margin-bottom:30px; max-width: 100%">
            <img src="@/assets/stream_error.svg" style="max-width: 100%" alt="stream not accessible" />
          </div>
          <div 
            :style="{
              marginTop: '50px',
              color:`${$store.getters.callConfig.textColor || '#2F75C7'}`,
              textAlign:'center',
              fontSize:'16px'
            }"
          >
            We are sorry for your inconvenience.
          </div>
          <div v-if="showReschedule" style="text-align:center;font-size:16px;margin-top:5px;margin-bottom:30px;">
            <span style="font-weight: 600;font-size:20px;">Don’t worry,</span> you can choose below option to proceed.
          </div>
          <v-row v-if="showRouteOptions">
              <v-col>
                  <v-btn
                    @click="redirectTo(redirectOptions[0])"
                    class="px-5 py-5"
                    :style="{
                      width: '175px',
                      backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || '#1976d2'}!important`,
                      color:`${$store.getters.callConfig.buttonTextColor || '#fff'}!important`
                    }"
                  >{{redirectOptions[0]}}</v-btn>
                  <div style="max-width:380px;margin-top:10px;font-size:14px;">
                    You will be taken back to the device checks, but don’t worry! We’ll connect you to the next available {{displayName}} representative.
                  </div>
              </v-col>
              <v-col v-if="showReschedule">
                  <v-btn
                    @click="redirectTo(redirectOptions[1])"
                    class="px-5 py-5"
                    :style="{
                      width: '175px',
                      backgroundColor: `${$store.getters.callConfig.buttonOutlineColor || '#1976d2'}!important`,
                      color:`${$store.getters.callConfig.buttonTextColor || '#fff'}!important`
                    }"
                  >{{redirectOptions[1]}}</v-btn>
                  <div style="max-width:380px;margin-top:10px;font-size:14px;">
                    Choose for rescheduling your call for any other time of your availability.
                  </div>
              </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import fallbackImg from "../assets/error12_img.png";
import { errorProcessor } from "@/flux/errorProcessor.flux.js";
import { end_points } from "@/config";

export default {
  data: () => ({
    showRouteOptions: false,
    showReschedule: true,
    errorMessage: "You were disconnected from the session!",
    redirectOptions: [],
    showIllus: false,
    errorKey: "unexpectedError",
    displayName: ""
  }),
  methods: {
    replaceByDefault(e) {
      e.target.src = fallbackImg
    },
    isLoadedInIframe() {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    redirectTo(route) {
        switch (route) {
            case "Retry" :
                (() => {
                    if (sessionStorage.getItem("callAgainHelper")) {
                        this.$router.replace({
                            path: `${sessionStorage.getItem("callAgainHelper")}`
                        });
                    } else {
                        eventBus.$emit("vueSnack", "Something went wrong!");
                    }
                })();
                break;
            case "Reschedule" :
                (() => {
                    if (sessionStorage.getItem("callAgainHelper")) {
                      let route = sessionStorage.getItem("callAgainHelper");

                      let newRoute = route.replace('/instructions', '');
                        this.$router.replace({
                            path: `${newRoute}/reschedule/queue`,
                            query: { from: 'dropHandler', reason: this.$route.params.reason }
                        });
                    } else {
                        eventBus.$emit("vueSnack", "Something went wrong!");
                    }
                })();
                break;
            default:
                (() => {
                    console.log("redirection happens here");
                })();
                break;
        }
    },
  },
  async created() {
    if (this.$route.params.reason) {
      this.errorKey = this.$route.params.reason;
      if(typeof this.displayName == "undefined") {
        this.displayName = sessionStorage.getItem("displayName");
      }
      switch (this.$route.params.reason) {
        case "ReDrop":
          this.errorMessage = `Your ${this.displayName} representative got disconnected due to some technical issues.`;
          this.showRouteOptions = true;
          this.redirectOptions = ["Retry", "Reschedule"];
          break;
        case "noStream":
          this.errorMessage = "We are not able to access your camera feed/microphone.";
          this.showRouteOptions = true;
          this.showIllus = true;
          this.redirectOptions = ["Retry", "Reschedule"];
          break;
        case "heartbeatFailed":
          this.errorMessage = "Your call got disconnected due to some technical issues.";
          this.showRouteOptions = true;
          this.redirectOptions = ["Retry", "Reschedule"];
          break;
        case "networkTooSlow":
          this.errorMessage = "Your call got disconnected because of some network issues.";
          this.showRouteOptions = true;
          this.redirectOptions = ["Retry", "Reschedule"];
          break;
        case "abrupt":
          this.errorMessage = "Uh oh! Looks like your Video KYC call was not completed successfully!";
          this.showRouteOptions = true;
          this.redirectOptions = ["Retry", "Reschedule"];
          break;
        default:
          this.errorKey = "unexpectedError";
          this.errorMessage =
            "There was a glitch.";
          break;
      }
    } else {
      this.errorKey = "unexpectedError";
    }
      this.showReschedule = sessionStorage.getItem("showScheduling") == "false" ? false: true;
      
      if (this.isLoadedInIframe()) {
        await errorProcessor(this.errorKey).then((resp) => {
          console.log("Sending error post message!!!!", resp, ",", this.errorKey);
          window.parent.postMessage(
            { 
              message: "videoUploadingFailed",
              error: {
                errorCode: resp,
                errorKey: this.errorKey, 
                errorMessage: this.errorMessage, 
              }
            }, "*");

          if(sessionStorage.getItem("requestId")){
            axiosInstance.post(end_points.trigger_call_back(sessionStorage.getItem("requestId")), {
              eventFor: "videoUploadingFailed",
              error: {
                errorCode: resp,
                errorKey: this.errorKey, 
                errorMessage: this.errorMessage, 
              }
            });
          }
        }).catch((err) => {
          // Returning VKYC_E_049
          console.log("Some error happened", err);
          window.parent.postMessage(
            { 
              message: "videoUploadingFailed",
              error: {
                errorCode: "VKYC_E_049",
                errorKey: this.errorKey, 
                errorMessage: this.errorMessage, 
              }
            }, "*");
          
          if(sessionStorage.getItem("requestId")){
            axiosInstance.post(end_points.trigger_call_back(sessionStorage.getItem("requestId")), {
              eventFor: "videoUploadingFailed",
              error: {
                errorCode: "VKYC_E_049",
                errorKey: this.errorKey, 
                errorMessage: this.errorMessage, 
              }
            });
          }
        })
    }
  },
};
</script>
<style scoped>
.login-bg {
  background-color: #1891cf;
  background-size: cover;
  /* background-image: url("~@/assets/vcip_it_admin.svg"), */
  /* linear-gradient(#313545, #242835); */
}
.lower-description {
  position: absolute;
  margin-left: 20px;
  z-index: 1;
  color: white;
}
.login-panel {
  position: relative;
}
.body-container {
  position: relative;
  width: 80%;
}
.signzy-logo {
  height: 80px;
}
.error-message {
  font-size:32px; 
  width: 50%; 
  margin: 0 auto;
  margin-bottom: 15px;
}
@media screen and (max-width: 700px) {
  .error-message {
    width: 90%; 
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .drop-page-responsive{
    display: flex;
    justify-content: center;
  }
  .text-left{
    text-align: left;
  }
  .login-panel {
    top: 20%;
  }
  .body-container {
    top: 80px;
  }
}
@media screen and (max-width: 767px) {
  .text-left{
    text-align: center !important; 
    margin-left: 0 !important;
  }
}
</style>