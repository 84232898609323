<template>
  <v-row>
    <v-col
      lg="8"
      md="8"
      sm="0"
      class="align-content-end flex-wrap login-bg hidden-sm-and-down d-none d-md-flex d-lg-flex d-xl-flex"
    >
      <div class="lower-description">
        <p class="display-2">Welcome to Video Conference</p>
        <p class="headline">Login to your account to Create/ Schedule calls</p>
      </div>
    </v-col>
    <v-col lg="4" md="4" sm="12" align="center">
      <div class="login-panel">
        <div v-if="!autoLogin" class="login-form">
          <v-text-field
            label="Username"
            v-model="username"
            outlined
            autocomplete="off"
            :readonly="readOnly"
            @focusin="toggleReadOnly()"
            @focusout="toggleReadOnly()"
          ></v-text-field>
          <v-text-field
            label="Password"
            v-model="password"
            :type="showPass ? 'text' : 'password'"
            :append-icon="showPass ? 'far fa-eye' : 'far fa-eye-slash'"
            @click:append="showPass = !showPass"
            :readonly="readOnly"
            @focusin="toggleReadOnly()"
            @focusout="toggleReadOnly()"
            @keyup.enter="signIn()"
            outlined
          ></v-text-field>
          <p v-if="showLoginError && numberOfLoginTries" style="color:red;text-align:left;">{{`Incorrect Password: ${numberOfLoginTries} ${numberOfLoginTries == 1 ? "attempt" : "attempts" } left.`}}</p>
          <p v-if="showLoginError && numberOfLoginTries == 0" style="color:red;text-align:left;">You have exceeded the limit of Login attempts. Try resetting the password.</p>
          <p v-if="showPasswordExpiryError" style="color:red;text-align:left;">{{`Your Password is expired. Please reset your password to login.`}}</p>
          <v-btn rounded color="primary" @click="signIn()" block>Proceed</v-btn>
          <v-row justify="space-between" class="ma-0" >
            <v-btn
               rounded color="primary"
              class=" mt-5"
              @click="forgotDialog = true"
              v-if="(loginType == 'itAdmin' || 'admin' ) && ((showLoginError && numberOfLoginTries == 0) || showPasswordExpiryError)"
              >Reset Password</v-btn
            >
              <v-btn
            outlined rounded
            class="primary--text mt-5"
            @click="forgotDialog = true"
            v-if="loginType == 'itAdmin'"
            color="primary"
            >Forgot Password</v-btn
          >
          </v-row>

        </div>
        <div v-else>
          <v-progress-circular
            :size="50"
            color="signzy_color"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </v-col>
    <v-dialog v-model="otpDialog" persistent max-width="580">
      <v-card>
        <v-card-title class="title mb-3">{{ otpMessage }}</v-card-title>
        <v-card-text>
          <v-text-field
            label="OTP"
            v-model="otp"
            type="text"
            counter
            :rules="otpRules"
            maxlength="6"
            outlined
          ></v-text-field>
          <div class="text-right">
            <div>
              <!-- <v-btn text
              color="green darken-1" @click="resendOTP()">
              Resend
            </v-btn> -->
              <v-btn
              color="red darken-1"
              text
              @click="
                otpDialog = false;
                otp = '';
              "
              >Cancel</v-btn
            >
            <v-btn color="green darken-1" text @click="verifyOTP()"
              >Submit</v-btn
            >
            </div>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="forgotDialog" persistent max-width="580" width="100%">
      <v-card>
        <v-card-title class="title mb-3">{{ otpMessage }}</v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="forgotEmailFormValid" >
            <v-text-field
              label="Enter UserName Associated with your account"
              :rules="validUsernameRule"
              v-model="forgotUserName"
              type="text"
              outlined
            />
            <v-text-field
              label="Enter E-Mail ID Associated with your account"
              :rules="validEmailRule"
              v-model="forgotEmail"
              type="text"
              outlined
            />
            <div class="text-right">
              <v-btn color="red darken-1" text @click="forgotDialog = false">
                Cancel
              </v-btn>
              <v-btn color="green darken-1" text @click="forgotPassword()"  :disabled="!forgotEmailFormValid">
                Submit
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { end_points, vcip_end_points, constants } from "@/config";
import enLib from "crypto-js/aes";
import cjsEnc from "crypto-js/enc-utf8";
let axiosInstance = window.axiosInstance;
let eventBus = window.eventBus;
//let cookieStore = new SignzyStorageService("cookie");
export default {
  data: () => ({
    username: "",
    password: "",
    showPass: false,
    otp: "",
    otpDialog: false,
    forgotUserName:"",
    forgotEmail: "",
    forgotDialog: false,
    loginData: {},
    otpMessage: "",
    loginUrl: "",
    loginType: "",
    autoLogin: false,
    otpRules: [(v) => /^[0-9]{6}/.test(v) || "Not a proper OTP!"],
    forgotEmailFormValid:false,
    validEmailRule:[(value)=> /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value)|| "Please Enter a Valid Email"],
    validUsernameRule:[(value)=>value?.trim()?.length > 0 || 'Please Enter a Valid Username'] ,
    readOnly: true,
    showLoginError: false,
    showPasswordExpiryError: false,
    numberOfLoginTries: "",
    otpSms: false,
    otpEmail: false,
  }),
  methods: {
    encrypt(text) {
      return enLib.encrypt(text, "SIGNZYROCKZ").toString();
    },
    toggleReadOnly(event) {
      this.readOnly = !this.readOnly
    },
    async getCustomerData(userId, token) {
      axiosInstance.defaults.headers.common["Authorization"] = token;
      if (userId) {
        let customerData = await axiosInstance
          .get(end_points.get_customer_data(userId))
          .catch((error) => {
            console.log(error);
          });

        customerData.otpSms = customerData.data.otpSms ? customerData.data.otpSms : false;
        customerData.otpEmail = customerData.data.otpEmail ? customerData.data.otpEmail : false;
        customerData.firstTimeChangePassword = customerData.data.firstTimeChangePassword ? customerData.data.firstTimeChangePassword : false;

        if(customerData.firstTimeChangePassword){
          this.$router.push({
            name: 'updatepass',
            params:{
              adminRedirect: true
            }
          });
        } else{
            if (customerData.data && customerData.otpSms == this.otpSms && customerData.otpEmail == this.otpEmail) {
              this.$store.commit("setUserData", {
                token: token,
                userId: userId,
                admin: true,
                isVideoKYC: customerData.data.isVideoKYC || false,
                isVideoConference: customerData.data.isVideoConference || false,
                isScreenshare: customerData.data.isScreenshare || false,
                ...customerData
              });
              if (
                !customerData.data.isVideoKYC &&
                !customerData.data.isVideoConference
              ) {
                this.$router.replace({
                  path: "/error/noGrant",
                });
              } else {
                this.$router.replace("/admin/control");
              }
            }else{
              eventBus.$emit("vueSnack", "OTP is required to login");
            }
          }
      }
    },
    // async resendOTP() {
    //   try {
    //       let url;
    //       if (this.loginType == "admin") {
    //         url = end_points.otp_resend_customer(this.loginData.userId);
    //       } else {
    //         url = end_points.otp_resend_backops(this.loginData.userId);
    //       }
    //       let res = await axiosInstance.get(url,{
    //           headers: {
    //             Authorization: this.loginData.token,
    //           },
    //         });
    //       if (res.data) {
    //         if (res.data.status == "Success") {
    //             eventBus.$emit("vueSnack", 'New otp has been sent!');
    //         } else {
    //           eventBus.$emit("vueSnack", res.data.message);
    //         }
    //       }
    //     } catch (error) {
    //       console.log(error);
    //       eventBus.$emit("vueSnack", "Something went wrong!");
    //     }
    // },
    async verifyOTP() {
      if (this.otp) {
        try {
          let url;
          if (this.loginType == "admin") {
            url = end_points.otp_verify_customer(this.loginData.userId);
          } else {
            url = end_points.otp_verify_backops(this.loginData.userId);
          }
          let res = await axiosInstance.post(
            url,
            {
              otp: enLib.encrypt(this.otp, this.$store.getters.envValues.otpEncKey).toString(),
            },
            {
              headers: {
                Authorization: this.loginData.token,
              },
            }
          );
          if (res.data) {
              res.data.status = enLib.decrypt(res.data.status, this.$store.getters.envValues.otpEncKey).toString(cjsEnc);
            if (res.data.status == "Success") {
              if (this.loginType == "admin") {
                await this.getCustomerData(
                  this.loginData.userId,
                  this.loginData.token
                );
              } else {
                this.relatedToIt();
              }
            } else {
              eventBus.$emit("vueSnack", res.data.message);
            }
          }
        } catch (error) {
          console.log(error);
          eventBus.$emit("vueSnack", "Something went wrong!");
        }
      }
    },
    async forgotPassword() {
      let options = {
        method: "POST",
        url: this.loginType == 'admin'? end_points.forgotAdminPass : end_points.forgotPass,
        data: {
          email: this.forgotEmail,
          username: this.forgotUserName.trim()
        },
      };

      try {
        let resp = await axiosInstance(options);
        let data = resp.data;
        eventBus.$emit(
          "vueSnack",
          // "Please check your email for password reset instructions!"
          "An email with password reset instructions will be sent to the specified mail if it matches our records"
        );
      } catch (err) {
        console.error("ERROR OCCURED WHILE PASSWORD REST", err);

        if(err.response.status===500){
          eventBus.$emit(
            "vueSnack",
            "There was an error sending password reset instructions!"
          );
        }
        else if (err.response.status === 404 || err.response.status === 401) {
          eventBus.$emit(
            "vueSnack",
            // "Email Not Found!"
            "An email with password reset instructions will be sent to the specified mail if it matches our records" 
          );
        }
        else if (err.response.status === 400) {
          eventBus.$emit(
            "vueSnack",
            "Email and Username are required fields" 
          );
        }
        else{
          console.error("ERROR PASSWORD RESET",err.response);
          eventBus.$emit(
            "vueSnack",
            "Something went wrong!"
          );
        }
      }

      this.forgotDialog = false;
      this.forgotEmail = "";
      this.forgotUserName = "";
    },
    superAdminWorker(data) {
      //Change the logo and alll the shits
      this.$store.commit("setUserData", {
        token: data.id,
        userId: data.userId,
        superAdmin: true
      });
      axiosInstance.defaults.headers.common["Authorization"] = data.id;
      this.$router.push("/super/panel");
    },
    itAdminWorker(data) {
      this.loginData = {
        token: data.id,
        userId: data.userId,
        belongsTo: data.belongsTo,
        customerData: data.customerData,
        firstLogin: data.firstLogin || false,
        role: data.role || "N/A",
        subAdminUserName: data.username || "",
        subAdminEmail: data.email || ""
      };

      this.$store.commit("setSubAdminAccessControl", {
        assignedFlows: data.assignedFlows || [],
        allowFlowCreation: data.allowFlowCreation || false,
        allowFlowDeletion: data.allowFlowDeletion || false,
        allowEditingSubAdminAccess: data.allowEditingSubAdminAccess || false
      });

      let otpArray = [];
      if (data["otpSms"]){
        otpArray.push("mobile");
        this.otpSms = data["otpSms"];
      }
      if (data["otpEmail"]) {
        otpArray.push("email");
        this.otpEmail = data["otpEmail"];
      }

      if (otpArray.length > 0) {
        this.otpMessage = `Please enter the otp received on ${otpArray.join(
          " and "
        )}`;
        this.otpDialog = true;
      } else {
        this.relatedToIt();
      }
    },
    async adminWorker(data) {
      let otpArray = [];
      if (data["otpSms"]) {
        otpArray.push("mobile");
        this.otpSms = data["otpSms"];
      }
      if (data["otpEmail"]){
        otpArray.push("email");
        this.otpEmail = data["otpEmail"];
      }

      if (otpArray.length > 0) {
        this.otpMessage = `Please enter the otp received on ${otpArray.join(
          " and "
        )}`;
        this.loginData = {
          userId: data.userId,
          token: data.id,
        };
        this.otpDialog = true;
      } else {
        await this.getCustomerData(data.userId, data.id);
      }
    },
    relatedToIt() {
      if(this.loginData && this.loginData.role == "admin"){
        this.loginData.admin = true;
      } else if(this.loginData && this.loginData.belongsTo == ""){
        this.loginData.manager = true;
      } else {
        this.loginData.user = true;
      }
      this.$store.commit("setUserData", this.loginData);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = this.loginData.token;
      window.removeEventListener("popstate", this.handleNav);
      if (this.loginData.firstLogin) {
        this.$router.push({
          name: 'updatepass',
          params:{
            adminRedirect: false
          }
        });
      } else if (this.loginData.role === "admin") {
          this.$router.replace("/admin/control");
      }else if (
        this.loginData.belongsTo != undefined &&
        this.loginData.belongsTo == 0
      ) {
        this.$router.push("/teams");
      } else {
        this.$router.push("/dashboard");
      }
    },
    fabricatePassword(password) {
      if (this.loginType == "itAdmin") {
        return this.encrypt(password);
      } else {
        return password;
      }
    },

    async handleBackopsLoginResp(res){
      if(res.data.statusCode == 401){
          this.showLoginError = true;
          this.numberOfLoginTries = res.data.tries;
          this.showPasswordExpiryError = false;
          eventBus.$emit("vueSnack", "Incorrect Credentials");
      }else if(res.data.statusCode == 405){
        this.showLoginError = true;
        this.numberOfLoginTries = 0;
        eventBus.$emit("vueSnack", "Account Blocked");
      }else if(res.data.statusCode == 403){
        this.showPasswordExpiryError = true;
        this.showLoginError = false;
        eventBus.$emit("vueSnack", "Your password is expired. Please reset your password to continue")
      }else{
        sessionStorage.userId = res.data.userId;
        this.showLoginError = false;
        this.showPasswordExpiryError = false;

        for(let key in res.data){
          let decryptKey = enLib.decrypt(key, this.$store.getters.envValues.otpEncKey).toString(cjsEnc);
          if(decryptKey && decryptKey == 'otpSms'){
            let otpSmsVal =  enLib.decrypt(res.data[key], this.$store.getters.envValues.otpEncKey).toString(cjsEnc);
            res.data.otpSms = otpSmsVal && otpSmsVal == 'true' ? true : false;
          }
          if(decryptKey && decryptKey == 'otpEmail'){
            let otpEmailVal = enLib.decrypt(res.data[key], this.$store.getters.envValues.otpEncKey).toString(cjsEnc);
            res.data.otpEmail = otpEmailVal && otpEmailVal == 'true' ? true : false;
          }
        }

        if(res.data.userId && res.data.id) {
          if (this.loginType == "super") {
            this.superAdminWorker(res.data);
          } else if (this.loginType == "admin") {
            this.adminWorker(res.data);
          } else {
            this.itAdminWorker(res.data);
          }
        }else {
          eventBus.$emit("vueSnack", "Failed to login.");
        } 
      }
    },

    async signIn() {
      this.otpSms = false;
      this.otpEmail = false;

      if (this.username && this.password) {
        try {
          let res = await axiosInstance.post(this.loginUrl, {
            username: this.username,
            password: this.fabricatePassword(this.password),
          });
          if (res.data) {
            this.handleBackopsLoginResp(res)
            }
        } catch (error) {
          console.log("error ", error)
          if (error.response.status == 409)
            eventBus.$emit("vueSnack", "Multiple sessions not allowed!");
          else eventBus.$emit("vueSnack", "Incorrect Credentials");
        }
      } else {
        eventBus.$emit("vueSnack", "Username and Password are required");
      }
    },
    handleNav () {
      this.$router.push({
        path: "/",
      })
      sessionStorage.setItem("cancelLogin", true);
    }
  },
  async created() {
    window.addEventListener("popstate", this.handleNav);

    let path = this.$route.path;
    if (path.includes("/private/")) {
      this.loginType = "admin";
      this.loginUrl = end_points.customerLogin;
    }
    else if (path.includes("/admin/")) {
      this.loginType = "admin";
      this.loginUrl = end_points.customerLogin;
    }
    else if (path.includes("/super/")) {
      this.loginType = "super";
      this.loginUrl = end_points.superAdminLogin;
    } else {
      this.loginUrl = end_points.agentLogin;
      this.loginType = "itAdmin";
    }

    if (this.$route.params.jwt) {
      this.autoLogin = true;
      try {
        let { login, sessionId } = (
          await axiosInstance.post(vcip_end_points.verify_token, {
            token: this.$route.params.jwt,
          })
        ).data;
        [this.username, this.password] = login.split("/");
        this.signIn();
      } catch (error) {
        console.log(error);
      }
    }

    if (this.$route.params.backopUserJwt){
      eventBus.$emit("vueLoader", true);
      // verify the jwt in the API and then go ahead
      try {
        let res = await axiosInstance.post(end_points.backopAdminVerifyJwt, {
          jwt: this.$route.params.backopUserJwt
        });
        if (res.data) {
          console.log("resp from verify autologin ", res.data);
          this.handleBackopsLoginResp(res)
        }
      }
      catch(error) {
      console.log("error ", error)
      if (error.response.status == 409)
        eventBus.$emit("vueSnack", "Multiple sessions not allowed!");
      else eventBus.$emit("vueSnack", "URL is invalid");
      }
      finally{
        eventBus.$emit("vueLoader", false);
      }
    }

    if (this.$route.params.uid && this.$route.params.token) {
      sessionStorage.userId = this.$route.params.uid;
      this.getCustomerData(this.$route.params.uid, this.$route.params.token);
    }
  },
};
</script>
<style scoped>
.login-bg {
  background-color: #1891cf;
  background-size: cover;
  background-image: url("~@/assets/vcip_it_admin.svg"),
    linear-gradient(#313545, #242835);
}
.lower-description {
  position: absolute;
  margin-left: 20px;
  z-index: 1;
  color: white;
}
.login-panel {
  top: 30vh;
  position: relative;
}
.login-form {
  position: relative;
  width: 80%;
}
.signzy-logo {
  height: 80px;
}
</style>
