<template>
  <v-content>
    <v-toolbar color="signzy_color">
      <v-toolbar-title>
        <!-- add some info later -->
      </v-toolbar-title>
      <v-menu offset-y v-if="hideVCIPBtn">
        <template v-slot:activator="{ on }">
          <v-btn depressed dark color="signzy_color" class="mr-2" v-on="on">
            <v-icon class="mr-2">fas fa-globe-americas</v-icon>
            {{ language }}
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in languages"
            :key="index"
            @click="setLanguage(item)"
          >
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <p
        v-if="hideVCIPBtn && !isVideoConference"
        class="title helperBar text-center pt-3"
      >
        {{ instructions[step].text }}
      </p>
      <v-spacer></v-spacer>
      <v-btn
        v-if="isStart && hideVCIPBtn"
        @click="navigate(-1)"
        color="primary"
        class="mr-2"
      >
        Prev
      </v-btn>
      <v-btn
        v-if="!isFinish && hideVCIPBtn && !isVideoConference"
        @click="navigate(1)"
        color="success"
        class="ml-2"
      >
        Next
      </v-btn>
      <v-btn
        class="ml-5"
        v-if="isFinish && hideVCIPBtn && !isVideoConference"
        @click="preValidatingChunks()"
        color="success"
        >Submit Application</v-btn
      >
      <!-- <v-btn
        class="ml-5"
        v-if="isFinish && hideVCIPBtn && isVideoConference"
        @click="checkForScreenshots()"
        color="success"
      >End Call</v-btn>-->
    </v-toolbar>

    <v-row class="ma-0 pa-0" style="width: 100%; height: 85%">
      <v-col
        class="ma-0 pa-0 col-sm-9 col-md-9 col-lg-9 col-xl-9 col-9"
        style
        align="center"
      >
        <!-- <div class="ma-0 pa-0">
          <v-row class="ma-0 pa-0 helperBar" justify="center">
            <v-col class="ma-0 pa-0" cols="10" sm="10" md="10" lg="10" xl="10">
              <p class="title text-center pt-3">{{instructions[step].text}}</p>
            </v-col>
          </v-row>
        </div>-->
        <v-overlay :value="showMissedError" :absolute="true" opacity="0">
          <v-row
            class="ma-0 pa-3"
            style="background-color: #fcfcfc; border-radius: 8px"
            justify="center"
            align="center"
          >
            <v-col align="center" style="width: 100%">
              <img class="pb-3" src="@/assets/error.svg" @error="fallBackErrorImg" style="width: 75px" />
              <p
                class="mb-4"
                style="
                  color: #2f75c7;
                  letter-spacing: 0.64px;
                  font-size: 24px;
                  font-weight: 400;
                "
              >
                {{errorOverlayHeading}}
              </p>
              <p
                style="
                  color: #0f0f0f;
                  font-size: 18px;
                  letter-spacing: 0px;
                  font-weight: 500;
                "
              >
                {{errorOverlaySubHeading}}
              </p>
              <p style="color: #0f0f0f; font-size: 14px; letter-spacing: 0px">
                {{errorOverlayMessage}}
              </p>
              <v-btn
                @click="rgbCheckFailed ? goToDashboard() : showMissedError = false "
                class="px-5"
                style="width: 170px"
                color="#2f75c7"
                >{{rgbCheckFailed ? "Go to Dashboard" : "Got it"}}</v-btn
              >
            </v-col>
          </v-row>
        </v-overlay>

        <v-overlay :value="rejoining">
          <v-btn
            v-if="showCancelRejoin"
            @click="cancelRejoin()"
            id="cancelRejoin">
            Cancel Rejoining
          </v-btn>
        </v-overlay>

        <!-- <v-dialog v-model="showRetryDataSave" opacity="0" persistent width="50%"> -->
        <v-dialog v-model="showRetryDataSave" opacity="0" persistent max-width="550">
          <v-row
            class="ma-0 pa-3"
            style="background-color: #fcfcfc; border-radius: 8px"
            justify="center"
            align="center"
          >
            <v-col align="center" style="width: 100%">
              <img class="pb-3" src="@/assets/error.svg" @error="fallBackErrorImg" style="width: 75px" />
              <p
                class="mb-4"
                style="
                  color: #2f75c7;
                  letter-spacing: 0.64px;
                  font-size: 24px;
                  font-weight: 400;
                "
              >
                Something went wrong!
              </p>
              <p
                style="
                  color: #0f0f0f;
                  font-size: 18px;
                  letter-spacing: 0px;
                  font-weight: 500;
                "
              >
                We encountered some error in saving this call's data.
              </p>
              <p style="color: #0f0f0f; font-size: 14px; letter-spacing: 0px">
                Don't worry the data is still safe. Please click on retry to try submitting the data again.
                If the issue still persists, please contact support.
              </p>
              <v-btn
                @click="dataSaveCollectData ? collectData() : hangUp()"
                class="px-5"
                style="width: 170px"
                color="#2f75c7"
                >Retry </v-btn
              >
            </v-col>
          </v-row>
        </v-dialog>

        <!-- User connection error -->
        <!-- <v-overlay v-show="showUserOffMessage" :absolute="true" opacity="0">
          <v-row
            class="ma-0 pa-3"
            style=" backgroundColor: #FCFCFC; border-radius: 8px"
            justify="center"
            align="center"
          >
            <v-col align="center" style="width: 100%">
              <p
                class="mb-4"
                style="color: #FF0000; letter-spacing: 0.64px; font-size: 24px; font-weight:400"
              >Customer has lost connection.</p>
              <p
                style="color: #0F0F0F; font-size: 18px; letter-spacing: 0px; font-weight: 500"
              >Please wait while we try to reconnect customer to the call.</p>
              <p
                style="color: #0F0F0F; font-size: 14px; letter-spacing: 0px"
              >If we are unable to reconnect in <span id="timer-cont"> 30  </span > seconds, call will get disconnected.</p>
            </v-col>
          </v-row>
        </v-overlay> -->


        <img
          v-if="isBoxRequired && hideVCIPBtn"
          class="hidden-xs-and-up hidden-sm-and-down"
          style="
            position: absolute;
            width: 33%;
            z-index: 3;
            top: 33%;
            left: 21%;
          "
          src="@/assets/rectangle.png"
        />
        <!-- <div id="videos"></div>
        <div class="removeVideo">
          <video v-show="!removeVideoMuted" id="reflection" autoplay playsinline></video>
          <p class="inactiveMessage" v-if="!presenterCame">Please wait while the end user connects!</p>
          <p
            class="inactiveMessage"
            v-if="removeVideoMuted && presenterCame"
          >End user has turned off the camera!</p>
        </div> -->
        <audio id="audioTracks" autoplay='1' muted='false'></audio>
        <!-- <video id="vid2" autoplay playsinline></video> -->
        <div class="removeVideo">
          <video
            v-show="!removeVideoMuted"
            id="vid2"
            autoplay
            playsinline
          ></video>
            <!-- Feedback menu -->
            <div v-if="showFeedback" style="z-index:1;position:absolute;right:0px; width:30%; top : 0; height: calc(90vh - 40px);">
              <v-row
                class="ma-0"
                style="
                  background-color: #fcfcfc;
                  height: inherit;
                  padding:3.5%;

                "
              >
                <v-col align="center" class="px-0 pb-0" style="width: 100%; height: inherit;">
                  <div
                    class="headline pb-2 px-4"
                    style="color: #0f0f0f; font-size: 20px; font-weight: 550"
                    align="left"
                  >
                    Please provide feedback
                  </div>
                  <v-divider
                    class="mb-3"
                    style="border-color: #e0e0e0; width: 100%"
                  ></v-divider>
                  <div style="height: 60vh; overflow-y: auto; overflow-x: none;  scrollbar-width: thin; padding-bottom: 2.887vh">
                    <div v-show="screenshots && screenshots[step] && screenshots[step].screenshots && screenshots[step].screenshots.length > 0" class="px-4">
                      <br>
                      <div align="center" justify="center">
                        <div class="pb-0">
                          <p style="color: #2f75c7" align="left">
                            Screenshot captured:
                          </p>
                        </div>
                        <div style="overflow-y: auto; overflow-x: hidden; scrollbar-width: thin; max-height: 20vh">
                          <v-row
                            v-if="
                              screenshots &&
                              screenshots[step] &&
                              screenshots[step].screenshots &&
                              screenshots[step].screenshots.length > 0
                            "
                          >
                            <figure
                              v-for="(i, index) in dataScreenshots[step].screenshots"
                              :key="'s' + index"
                              style="width: 75%;max-height:170px; overflow: hidden"
                              class="mr-2 mb-2"
                            >
                              <img
                                style="max-width: 100%; height: auto; max-height: 100%"
                                :src="i"
                              />
                            </figure>
                          </v-row>
                          <p v-else style="color: #0f0f0f" align="left">
                            No images found for this category!
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-for="i in feedbackFields" :key="i.variable" class="px-4">
                      <br>
                      <div
                        v-if="i.elementType == 'multiSelectDropdown' && !i.conditionalRendering"
                        align="center"
                        justify="center"
                      >
                        <div class="pb-0">
                          <p
                            class="mb-0"
                            style="color: #2f75c7; text-transform: capitalize; word-wrap:break-word;"
                            align="left"
                          >
                            {{ i.placeHolder }}:
                          </p>
                        </div>
                        <div class="pb-0">
                          <v-container fluid>
                              <v-select v-if="typeof i.options =='string'"
                              v-model="feedbackVariables[i.variable]"
                              :items="dropDownOptions[i.options]"
                              :id="i.options"
                              :label="i.placeHolder"
                              item-text="text"
                              @click="giveWidth(1)"
                              @focus="setTypeId"
                              class="i_am_feedback_dropdown"
                              item-value="value"
                              @change="checkFeedback()"
                              chips
                              multiple
                              light >
                                <template v-slot:prepend-item>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-text-field v-model="searchTerm" placeholder="Search" @input="search"></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                              </v-select>
                            <v-select v-else
                              v-model="feedbackVariables[i.variable]"
                              :items="i.options"
                              :label="i.placeHolder"
                              item-text="text"
                              item-value="value"
                              @click="giveWidth(1)"
                              class="i_am_feedback_dropdown"
                              chip
                              @change="checkFeedback()"
                              multiple
                              light
                            ></v-select>
                          </v-container>
                        </div>
                      </div>
                      <div class="single-select-dropdown-container"
                        v-else-if="i.elementType == 'dropdown'&& !i.conditionalRendering"
                        align="center"
                        justify="center"
                      >
                        <div class="pb-0">
                          <p
                            class="mb-0"
                            style="color: #2f75c7; text-transform: capitalize; word-wrap:break-word;"
                            align="left"
                          >
                            {{ i.placeHolder }}:
                          </p>
                        </div>
                        <div class="pb-0">
                          <v-container fluid>
                              <v-select v-if="typeof i.options =='string'"
                              :items="dropDownOptions[i.options]"
                              v-model="feedbackVariables[i.variable]"
                              :id="i.options"
                              :label="i.placeHolder"
                              item-text="text"
                              @click="giveWidth(1)"
                              @focus="setTypeId"
                              class="i_am_feedback_dropdown"
                              item-value="value"
                              @change="checkFeedback()"
                              light >
                                <template v-slot:prepend-item>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-text-field v-model="searchTerm" placeholder="Search" @input="search"></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                              </v-select>
                              <v-select v-else
                                v-model="feedbackVariables[i.variable]"
                                :label="i.placeHolder"
                                :items="i.options"
                                item-text="text"
                                @click="giveWidth(1)"
                                class="i_am_feedback_dropdown"
                                item-value="value"
                                @change="checkFeedback()"
                                light
                              ></v-select>
                          </v-container>
                        </div>
                      </div>
                      <div
                        v-else-if="i.elementType == 'inputField' && !i.conditionalRendering"
                        align="center"
                        justify="center"
                      >
                        <div class="pb-0">
                          <p
                            class="mb-0"
                            style="color: #2f75c7; text-transform: capitalize; word-wrap:break-word;"
                            align="left"
                          >
                            {{ i.placeHolder }}:
                          </p>
                        </div>
                        <div class="pb-0">
                          <v-text-field
                            v-model="feedbackVariables[i.variable]"
                            class="x"
                            :placeholder="i.placeHolder"
                            @keyup="checkFeedback()"
                            light
                          ></v-text-field>
                         <div class="hide" style="font-size: 12px;background: #F5F5F5; word-wrap:break-word">{{feedbackVariables[i.variable]}}</div>
                        </div>
                      </div>
                      <div
                        v-else-if="i.elementType == 'number' && !i.conditionalRendering"
                        align="center"
                        justify="center"
                      >
                        <div class="pb-0">
                          <p
                            class="mb-0"
                            style="color: #2f75c7; text-transform: capitalize; word-wrap:break-word;"
                            align="left"
                          >
                            {{ i.placeHolder }}:
                          </p>
                        </div>
                        <div class="pb-0">
                          <v-text-field
                            v-model="feedbackVariables[i.variable]"
                            class="x"
                            :placeholder="i.placeHolder"
                            @keyup="checkFeedback(true, i.variable)"
                            light
                          ></v-text-field>
                         <div class="hide" style="font-size: 12px;background: #F5F5F5; word-wrap:break-word">{{feedbackVariables[i.variable]}}</div>
                        </div>
                      </div>
                      <div
                        v-else-if="i.elementType == 'textarea' && !i.conditionalRendering"
                        align="center"
                        justify="center"
                      >
                          <div class="pb-0">
                            <p
                              class="mb-0"
                              style="color: #2f75c7; text-transform: capitalize; word-wrap:break-word;"
                              align="left"
                            >
                              {{ i.placeHolder }}:
                            </p>
                          </div>
                          <br>
                          <div class="pb-0">
                            <v-textarea
                              solo
                              @keyup="checkFeedback()"
                              v-model="feedbackVariables[i.variable]"
                              :placeholder="i.placeHolder"
                              no-resize
                              light
                            ></v-textarea>
                          </div>
                     </div>
                    </div>

                  </div>

                  <v-row
                    class="mx-0 py-2"
                    style="background-color: #c7c7c733; width: 100%; position:absolute; bottom:30px;left:0px"
                    justify="center"
                  >
                    <div style="max-width: 100%  ;margin:3%">
                      <v-btn
                        @click="redoInstruction()"
                        class="px-5 py-5"
                        style="font-size:0.8vw;height:50px;text-transform:none !important;"
                        color="#2f75c7"
                        outlined
                        ><span style="padding-left:1.5vw;padding-right:1.5vw">Close</span></v-btn
                      >
                    </div>
                    <div style="max-width: 100% ;margin:3%">
                      <v-spacer></v-spacer>
                      <v-btn
                        @click="completedFeedback()"
                        class="px-5 py-5"
                        style="font-size:0.8vw;height:50px;text-transform:none !important"
                        :disabled="isDisabled"
                        color="primary"
                        light
                        >Next Instruction ></v-btn
                      >
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </div>
          <div class="recorderIndicator pa-3" v-if="timeFromStart > 0">
            <div class="indicatorContainer">
              <div class="roundIndicator mr-3" :style="{backgroundColor: recorderColor}" ></div>
              <p  class="ma-0" style="color: #fff">{{displayTimer}}</p>
            </div>
          </div>
          <div
            v-if="recordingStopped"
            class="pa-2"
            style="display: flex; align-items: center; position: absolute; left: 50%; width: 520px; top: 2%; background-color: rgba(49 ,53 ,69 ,0.45); border-radius: 6px"
          >
            <img
              class="pa-0 mr-2"
              src="@/assets/error.svg"
              @error="fallBackErrorImg"
              style="width: 40px"
            />
            <p class="ma-0" style="color: #fff">
              Recording has stopped due to connectivity issues! Please wait...
              <span>If you're still facing the issue,kindly ask customer to rejoin</span>
            </p>
          </div>
          <div
            v-if="isCustomerMuted"
            class="pa-2"
            style=" display: flex; align-items: center; position: absolute; left: 25%; top: 28%;"
          >
            <p class="ma-0" style="color: rgb(255, 255, 255); font-size: 86px;">User Mutes his Mic
            </p>
          </div>
          <div
            v-if="showMicMuted"
            class="pa-2"
            style="display: flex; align-items: center; position: absolute; left: 50%; top: 2%; background-color: rgba(49 ,53 ,69 ,0.45); border-radius: 6px"
          >
            <img
              class="pa-0 mr-2"
              src="@/assets/mute.svg"
              @error="fallBackErrorImg"
              style="width: 40px"
            />
            <p class="ma-0" style="color: #fff">
              <span>User is muted</span>
            </p>
          </div>
          <!-- <p class="inactiveMessage" v-if="!presenterCame">
            Please wait while the end user connects!
          </p> -->
          <p class="inactiveMessage" v-if="removeVideoMuted && presenterCame && !rgbCheckFailed && !heartbeatFailed && !presenterLeft">
            End user has turned off the camera!
          </p>
          <div class="video-error-message ml-4" v-if=" (rgbCheckFailed || heartbeatFailed || speedThresholdFailed || presenterLeft || locationDenied || REThresholdFailed || endUserMinimized)">
            <div style="display: flex; align-items: center;">
              <div class="loader">Loading...</div>
              <p
                style="color: #fff; font-size: 18px; margin-left: 25px; margin-top: 15px"
              > {{videoErrorMessage}} </p>
            </div>
            <!-- <p v-if="(showDropPrompt || showDropPromptHeartbeat || showKickoutMessage || presenterLeft) && !showInactivePopup && !stayOnCall" style="color: #fff; text-align: left; margin-left: 55px"> -->
            <p v-if="showDropPrompt || showDropPromptHeartbeat || showKickoutMessage || presenterLeft" style="color: #fff; text-align: left; margin-left: 55px">
              <span v-if="!presenterLeft">Still seeing this message?</span>
                <strong
                  @click="finish(true, showDropPrompt ? 'rgbCheckFailed' : showDropPromptHeartbeat ? 'heartbeatFailed' : 'networkTooSlow')"
                  style="cursor: pointer"
                > Click here </strong>
              to end the call.
              <span style="display: block; width: 55%; font-size:13px">Don’t worry! Upon ending this call, the customer will be able to retry and connect back with a {{displayName}} representative to finish Video KYC.</span>
            </p>
          </div>

        </div>
      </v-col>
      <v-col
        class="col-sm-3 col-md-3 col-lg-3 col-xl-3 col-3"
        style="height: 90vh; overflow-y: scroll"
      >
        <video id="reflection" autoplay playsinline></video>
        <v-btn
          v-if="!hideVCIPBtn && presenterCame && !isVideoConference"
          block
          @click="startVcip()"
          :disabled="disableBeginVCIP"
          color="primary"
          >{{startButtonText}}</v-btn
        >
        <v-btn
          v-if="!hideVCIPBtn && presenterCame && isVideoConference && isRecording"
          block
          @click="startVcip()"
          :disabled="disableBeginVCIP"
          color="primary"
          >Start Recording</v-btn
        >
        <v-tabs @change="switchView" v-model="tab" centered>
          <v-tab style="font-size:10px;">Overview</v-tab>
          <v-tab style="font-size:10px;" v-if="!isVideoConference">{{ videoCallDetailsHeader || "KYC Data" }}</v-tab>
          <v-tab v-if="enableChat" style="font-size:10px;" @click="showNotificationIcon = false">Chat
            <v-icon v-if="showNotificationIcon" color="red" style="font-size: inherit; right: 15px; position: absolute;"> fas fa-circle</v-icon>
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-container style="margin-bottom: 70px">
              <v-btn text color="signzy_color" class="mr-2">
                <v-icon small class="mr-2">fas fa-user-tie</v-icon>
                {{ name || "N/A" }}
              </v-btn>
              <br />
              <v-btn text color="signzy_color" class="mr-2">
                <span v-if="!locationFail"><v-icon small class="mr-3">fas fa-map-marker-alt</v-icon>
                {{ ipData.city }} <span v-if="ipData.regionName">{{ ","+ipData.regionName }}</span>
                </span>
                 <span v-else>
                  <v-icon small class="mr-3" style="color: red">fas fa-map-marker-alt</v-icon>
                   <span style="color: red; font-size:11px">Error fetching Location</span>
                  <v-icon small class="mr-3" style="margin-left: 4%" @click="locationRetry">fas fa-sync-alt</v-icon>
                </span>
              </v-btn>
              <br />
              <!-- <v-btn text color="signzy_color" class="mr-2">
                <v-icon x-small class="mr-2">fas fa-wifi</v-icon>
                <p style="text-transform: none" class="ma-0">{{ internetSpeed.Download }} Mbps </p>
              </v-btn> -->
              <v-divider></v-divider>
              <div v-if="additionalData">
                <p class="title">Additional Data</p>
                <div v-for="(value, name) in additionalData" v-bind:key="name">
                  <span v-if="value && value.length > 0">
                    <b>{{ getUpperCaseLabel(name) }}</b>
                    :
                    {{ value }}
                  </span>
                </div>
              </div>
              <p v-if="disableBeginVCIP">
                Please wait while we setup your call!
              </p>
              <!-- <div id="speedBar"> -->
                <v-container v-if="hideSpeedTest">
                  <v-row no-gutters>
                    <v-col>
                      <p id="speedBarTitle">Connection information</p>
                    </v-col>
                  </v-row>
                  <v-row id="endUserSpeed" no-gutters>
                    <v-col sm="4">
                      <p id="speedBarSubtitle">Customer network:</p>
                    </v-col>
                    <v-col sm="8" v-if="endUserSpeedLoading" style="padding-top: 10%">
                      <p class="ma-0" style="color : #0089CC; font-weight: 550"><img src="@/assets/Vectorloading.svg" class="pr-2 ma-0" style="vertical-align: top">Loading...</p>
                    </v-col>
                    <v-col sm="8" v-if="!endUserSpeedLoading && endUserSpeedFailed" style="padding-top: 10%">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <p class="ma-0" style="color : #000000; font-weight: 550" v-bind="attrs" v-on="on"><img src="@/assets/error.svg" class="pr-2 ma-0" v-bind="attrs" v-on="on" style="vertical-align: top; height: 20px">Unavailable</p>
                        </template>
                        <span>
                          Unable to load speed test values.<br>
                          Anyhow, you can proceed with VKYC.<br>
                          Kindly contact support if this issue persists.
                        </span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="8" v-if="!endUserSpeedLoading && !endUserSpeedFailed" class="pa-0 ma-0 endUserSpeedData">
                      <v-row no-gutters>
                        <v-col>
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                               <p :class="speedProps['Strength']['EU'].status"><img :src="getImgUrl(speedProps.Strength)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ speedProps['Strength']['EU'].value }}</p>
                            </template>
                            <span>Strength</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col sm="6">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <p :class="speedProps['Download']['EU'].status"><img :src="getImgUrl(speedProps.Download)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ internetSpeed.Download || 0 }} Mbps</p>
                            </template>
                            <span>Download</span>
                          </v-tooltip>
                        </v-col>
                        <v-col sm="6">
                           <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <p :class="speedProps['Upload']['EU'].status"><img :src="getImgUrl(speedProps.Upload)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ internetSpeed.Upload || 0 }} Mbps</p>
                            </template>
                            <span>Upload</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col sm="6">
                           <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <p :class="speedProps['Ping']['EU'].status"><img :src="getImgUrl(speedProps.Ping)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ internetSpeed.Ping || 0 }} ms</p>
                            </template>
                            <span>Ping</span>
                          </v-tooltip>
                        </v-col>
                        <v-col sm="6">
                           <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <p :class="speedProps['Jitter']['EU'].status"><img :src="getImgUrl(speedProps.Jitter)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ internetSpeed.Jitter || 0 }} ms</p>
                            </template>
                            <span>Jitter</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters id="speedBarMsg"
                    v-if="speedProps['Strength']['EU'].status && styleProps[speedProps['Strength']['EU'].status]['EUMsg'] && !endUserSpeedLoading"
                    :style="styleProps[speedProps['Strength']['EU'].status].filter"
                  >
                    <p class="ma-auto">{{styleProps[speedProps['Strength']['EU'].status]['EUMsg']}}</p>
                  </v-row>
                  <v-row id="reSpeed" no-gutters class="pt-5">
                    <v-col sm="4">
                      <p id="speedBarSubtitle">Your network:</p>
                    </v-col>
                    <v-col sm="8" v-if="reSpeedLoading" style="padding-top: 10%">
                      <p class="ma-0" style="color : #0089CC; font-weight: 550"><img src="@/assets/Vectorloading.svg" class="pr-2 ma-0" style="vertical-align: top" >Loading...</p>
                    </v-col>
                    <v-col sm="8" v-if="!reSpeedLoading && reSpeedFailed" style="padding-top: 10%">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <p class="ma-0" style="color : #000000; font-weight: 550" v-bind="attrs" v-on="on"><img src="@/assets/error.svg" class="pr-2 ma-0" v-bind="attrs" v-on="on" style="vertical-align: top ; height: 20px" >Unavailable</p>
                        </template>
                        <span>
                          Unable to load speed test values.<br>
                          Anyhow, you can proceed with VKYC.<br>
                          Kindly contact support if this issue persists.
                        </span>
                      </v-tooltip>
                    </v-col>
                    <v-col sm="8" v-if="!reSpeedLoading && !reSpeedFailed" class="pa-0 ma-0 reSpeedData">
                      <v-row no-gutters>
                        <v-col>
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                               <p :class="speedProps['Strength']['RE'].status" ><img :src="getImgUrl(speedProps.Strength)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ speedProps['Strength']['RE'].value }}</p>
                            </template>
                            <span>Strength</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col sm="6">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <p :class="speedProps['Download']['RE'].status"><img :src="getImgUrl(speedProps.Download)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ ownInternetSpeed.Download || 0 }} Mbps</p>
                            </template>
                            <span>Download</span>
                          </v-tooltip>
                        </v-col>
                        <v-col sm="6">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <p :class="speedProps['Upload']['RE'].status"><img :src="getImgUrl(speedProps.Upload)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ ownInternetSpeed.Upload || 0 }} Mpbs</p>
                            </template>
                            <span>Upload</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col sm="6">
                          <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                              <p :class="speedProps['Ping']['RE'].status"><img :src="getImgUrl(speedProps.Ping)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ ownInternetSpeed.Ping || 0 }} ms</p>
                            </template>
                            <span>Ping</span>
                          </v-tooltip>
                        </v-col>
                        <v-col sm="6">
                          <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                              <p :class="speedProps['Jitter']['RE'].status"><img :src="getImgUrl(speedProps.Jitter)" class="pr-2 ma-0" v-bind="attrs" v-on="on">{{ ownInternetSpeed.Jitter || 0 }} ms</p>
                            </template>
                            <span>Jitter</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row no-gutters id="speedBarMsg" align="center"
                      v-if="speedProps['Strength']['RE'].status && styleProps[speedProps['Strength']['RE'].status]['REMsg'] && !reSpeedLoading"
                      :style="styleProps[speedProps['Strength']['RE'].status].filter"
                  >
                    <p class="ma-auto">{{styleProps[speedProps['Strength']['RE'].status]['REMsg']}}</p>
                  </v-row>
                </v-container>
                <v-container v-if="enableRecordingPreview && isRecording" class="videoPreview">
                    <v-divider></v-divider><br>
                    <p id="videoPreview">Video Recording Preview</p>
                    <video id="recordingPreview" controls="true" muted autoplay playsinline></video>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <div v-on="on" class="d-inline-block">
                            <v-btn v-if="hideVCIPBtn" id="restartRecording" @click="confirmReRecord = true" color="primary">Restart Recording</v-btn>
                        </div>
                        </template>
                        <span>Error in recording? Use the option to restart recording!</span>
                    </v-tooltip>

                </v-container>
              <!-- </div> -->
              <p class="title" v-if="hideVCIPBtn && isVideoConference">
                <span v-if="stopRecordingTime != 'done' && !presenterLeft">
                  Recording will stop after
                  <b>{{ stopRecordingTime }}</b>
                </span>

                <span v-if="stopRecordingTime === 'done' && !presenterLeft"> Recording finished! </span>
                <span v-if="presenterLeft">
                  Recording is stopped
                </span>
                <span v-else> Recording finished! </span>
              </p>
            </v-container>
          </v-tab-item>
          <v-tab-item>
            <v-container style="padding-bottom: 20%">
              <div class="text-center">
                <p class="title">KYC Details</p>
              </div>
              <v-select
                :items="proofs"
                v-model="currentProof"
                class="mt-2 mb-2"
                label="Select proof"
                @change="changeDisplay()"
                solo
              ></v-select>
              <v-divider class="mt-2 mb-3"></v-divider>
              <div v-if="imageArray.length != 0">
                <viewer :images="images">
                  <img :src="images">
                </viewer>
                <v-row row @click="show(imageSource)" wrap id="documentWrap"></v-row>
                <vue-pdf-app v-if="isDocPdf" style="height: 50vh; margin-bottom: 20px" :pdf="imageSource" :config="toolbarConfig"></vue-pdf-app>
                <v-row row wrap>
                  <v-col cols="6">
                    <v-btn
                      :disabled="docPos == 0"
                      block
                      small
                      outlined
                      color="indigo"
                      @click="moveImage(-1)"
                      >previous</v-btn
                    >
                  </v-col>
                  <v-col cols="6">
                    <v-btn
                      :disabled="docPos == imageArray.length - 1"
                      block
                      small
                      outlined
                      color="indigo"
                      @click="moveImage(1)"
                      >Next</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
              <v-row v-if="imageLink && imageArray.length < 1 && imageLink.length > 0" @click="show(imageLink)">
                <v-col col-sm-12 col-md-12 col-lg-12 col-xl-12 col-12>
                  <img :src="imageLinkDisplay" style="width: 100%" />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  col-sm-12
                  col-md-12
                  col-lg-12
                  col-xl-12
                  col-12
                  pl-2
                  pr-2
                  mt-1
                  style="margin-bottom: 20px"
                >
                  <div v-for="(value, name) in documents" v-bind:key="name">
                    <span v-if="value && value.toString().length > 0">
                      <b>{{ name.toUpperCase() }}</b>
                      :
                      {{ value }}
                    </span>
                  </div>
                </v-col>
              </v-row>
               <div v-if="proofData[currentProof]">

                    <div v-if="documentFacematchScore.length > 0">
                      <b>Facematch Score Details: </b>
                      <div v-for="(data,index) in documentFacematchScore" v-bind:key="index">
                        <span v-for="(val) in data" v-bind:key="val" >
                          - {{val}}
                        </span>%
                      </div>
                    </div>
              </div>
              <br>
            </v-container>
          </v-tab-item>

           <v-tab-item v-if="enableChat">
            <v-container>
              <div class="chatBox" style="height: calc(90vh - 400px);" @click="showNotificationIcon = false; messageSeenAck()">
                <Chat
                  :participants="participants"
                  :myself="myself"
                  :messages="chatMessages"
                  :placeholder="placeholder"
                  :colors="colors"
                  :hide-close-button="true"
                  :displayHeader="false"
                  :send-images="false"
                  :profile-picture-config="profilePictureConfig"
                  :scroll-bottom="scrollBottom"
                  :async-mode="asyncMode"
                  @onMessageSubmit="onMessageSubmit"
                />
              </div>
            </v-container>
          </v-tab-item>


        </v-tabs-items>
      </v-col>
    </v-row>

    <v-footer height="auto" color="signzy_color" fixed style="height:75px;">
      <v-row align="center" justify="center">
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          color="indigo"
          :class="enableChime ? 'text-left hidden-sm-and-down deviceSelection' : 'text-left hidden-sm-and-down'"
        >
          <v-select
            v-if="enableChime"
            :items="microphones"
            label="Microphone"
            item-text="label"
            item-value="deviceId"
            v-model="selectedMicrophone"
            @change="changeMicrophone($event)"
            dense outlined dark
            style="width : 40% ; padding: 0px;padding-top: 18px;padding-left: 10px;">
          </v-select>
          <v-select
            v-if="enableChime"
            :items="videoDevices"
            label="Camera"
            item-text="label"
            item-value="deviceId"
            v-model="selectedVideoDevice"
            @change="changeVideoDevice($event)"
            style="width : 30% ;padding: 0px;padding-top: 18px;padding-left: 10px;"
            dense outlined dark
          ></v-select>
          <v-select
            v-if="enableChime"
            :items="speakers"
            label="Speaker"
            item-text="label"
            item-value="deviceId"
            v-model="selectedSpeaker"
            @change="changeSpeaker($event)"
            style="width : 30%;padding: 0px;padding-top: 18px;padding-left: 10px;padding-right:10px;"
            dense outlined dark
          ></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          color="indigo"
          class="text-center"
        >
          <span class="x">
          <v-btn
            v-if="!isMute"
            @click="muteAudioView()"
            class="mx-2"
            :disabled="disableMuteBtn"
            fab
            dark
            small
            color="primary"
          >
            <v-icon dark small>fas fa-microphone</v-icon>
          </v-btn>
          </span>
          <div v-if="disableMuteBtn" class="hide" style="position:absolute; bottom:60px; left:40%; font-size:11px;background:#F5F5F5; padding:4px; border-radius: 100px; color:red">Mute functionality will be available after the end user joins</div>
          <v-btn
            v-if="isMute"
            @click="muteAudioView()"
            class="mx-2"
            fab
            dark
            small
            color="primary"
          >
            <v-icon dark small>fas fa-microphone-slash</v-icon>
          </v-btn>
          <v-btn
            class="mx-2 hangup"
            fab
            dark
            small
            color="error"
            @click="endCallDialog = true"
          >
            <v-icon small dark>fas fa-phone</v-icon>
          </v-btn>
          <v-btn
            v-if="!isVideoEnabled"
            @click="muteVideoView()"
            class="mx-2"
            fab
            dark
            small
            color="primary"
          >
            <v-icon dark small>fas fa-video</v-icon>
          </v-btn>
          <v-btn
            v-if="isVideoEnabled"
            @click="muteVideoView()"
            class="mx-2"
            fab
            dark
            small
            color="primary"
          >
            <v-icon dark small>fas fa-video-slash</v-icon>
          </v-btn>
          <v-btn
            v-if="
              instructions &&
              instructions[step] &&
              instructions[step].isScreenshotRequired &&
              hideVCIPBtn
            "
            @click="takeImage()"
            :disabled = "disableBtn"
            class="mx-2"
            fab
            dark
            small
            color="primary"

          >
            <v-icon dark small>fas fa-save</v-icon>
          </v-btn>
          <v-btn
            v-if="allowRejoin && !enableChime"
            :disabled="rejoining || rejoinGuard"
            class="mx-2 rejoin"
            fab
            dark
            small
            color="error"
            @click="confirmRejoin = true"
          >
            <v-icon small dark>fas fa-redo</v-icon>

          </v-btn>
          <!-- <img
            class="quickActions ml-1 mr-1 captureImageIn"
            @click="processScreen()"
            v-if="instructions[step].isScreenshotRequired"
            src="@/assets/capture.png"
          />
          <img class="quickActions ml-1 mr-1 captureImageHover" src="@/assets/capture_click.png" />-->
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="4"
          lg="4"
          xl="4"
          color="indigo"
          class="text-right hidden-sm-and-down"
        >
          <v-btn
            v-if="!isMobile && isScreenshare"
            x-large
            depressed
            color="signzy_color"
            class="mr-2"
            dark
            @click="shareScreen()"
          >
            <v-icon class="mr-2">fas fa-tv</v-icon>
            {{ screenShareText }}
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
    <!-- <v-dialog v-model="multiSessionDialog" persistent width="50%"> -->
    <v-dialog v-model="multiSessionDialog" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center">
          <p class="title pt-2">
            Please wait for a while, multiple sessions detected!
          </p>
          <v-spacer></v-spacer>
          <v-btn dark color="signzy_color" @click="multiSessionDialog = false"
            >Ok</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <v-dialog v-if="presenterLeft && !callDropInitiated" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center">
          <p class="title pt-2"> {{userLeftMessage}}</p>
          <v-spacer></v-spacer>
          <v-btn dark color="signzy_color" @click="goToDashboard()"
            >Go to dashboard!</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <!-- <v-dialog v-model="noActiveSession" persistent width="50%"> -->
    <v-dialog v-model="noActiveSession" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center">
          <p class="title pt-5">There is no active session!</p>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="signzy_color"
            @click="
              noActiveSession = false;
              $router.replace({ name: 'dashboardHistory' });
            "
            >Ok</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- Rejoin Call Popup -->
    <!-- <v-dialog v-model="confirmRejoin" persistent width="50%"> -->
    <v-dialog v-model="confirmRejoin" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center">
          <p class="title pt-2">Are you sure you want to re-join this call?</p>
          <p>
            <i>Note: Please wait for atleast 30 seconds after re-joining</i>
          </p>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="signzy_color"
            class="mr-1"
            @click="confirmRejoin = false"
            >No</v-btn
          >
          <v-btn
            dark
            color="signzy_color"
            class="ml-1"
            @click="reJoinCall()"
            >Yes</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Inactive popup -->
    <!-- <v-dialog content-class="custom-dialog" v-model="showInactivePopup" persistent width="50%">
        <v-card>
          <v-card-text class="text-center">
            <p class="title pt-2">Customer is inactive at the moment. Would you like to stay on this call?</p>
            <v-spacer></v-spacer>
            <v-btn
              dark
              color="signzy_color_dark"
              class="mr-1"
              @click="finish(true)"
              >End call</v-btn
            >
            <v-btn
              class="ml-1"
              @click="stayOnCall = true; showInactivePopup = false"
              >Stay On call</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog> -->

    <!-- ReRecord popup -->
    <!-- <v-dialog v-model="confirmReRecord" persistent width="50%"> -->
    <v-dialog v-model="confirmReRecord" persistent max-width="550">
      <v-card>
        <v-card-text class="text-center">
          <p class="title pt-2">Are you sure you want to restart recording?</p>
          <p>
            <i>Note 1: This option is only helpful when you are able to see yourself and the end user on the screen</i>
            <br>
            <i>Note 2: If you are not able to see enduser or yourself, please use rejoin call option</i>
          </p>
          <v-spacer></v-spacer>
          <v-btn
            dark
            color="signzy_color"
            class="mr-1"
            @click="confirmReRecord = false"
            >No</v-btn
          >
          <v-btn
            dark
            color="signzy_color"
            class="ml-1"
            @click="reRecordCall()"
            >Yes</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Screenshot -->
    <v-dialog
      v-model="screenshot"
      persistent
      max-width="750"
      style="height: 80vh"
    >
      <v-card>
        <v-card-title class="title text-center align-center">
          <h3
            style="text-align: center; color: #2f75c7; width: 100%"
          >
            Captured Photo
          </h3>
          <v-spacer></v-spacer>
        </v-card-title>
        <p v-if="!croppingFinished" class="text-center">
          {{
            !imageFailed
              ? "Please select the area to crop"
              : "Please try again :("
          }}
        </p>
        <p
          v-if="croppingFinished && !cropperCheck && !imageFailed"
          class="text-center"
        >
          Scroll on image to zoom.
        </p>
        <p v-if="croppingFinished && !cropperCheck && !imageFailed & isUploadFailed"
        class="text-center" style="color: #DC143C">
          Something went wrong, Please try again.
          <span>If you're still facing the issue,kindly take a screenshot again.</span>
        </p>
        <p v-if="croppingFinished && imageFailed" class="text-center">
          Please try again :(
        </p>

        <v-card-text class="text-center" style="height: 60vh">
          <v-row
            id="canvasSheet"
            style="height: 100%"
            align="center"
            justify="center"
          >
            <canvas
              class="pa-2"
              style="width: 100%; display: none; "
              id="sheet"
            ></canvas>
            <img
              :src="screenshotPreviewSrc"
              :alt="
                imageFailed
                  ? 'Screenshot capture failed'
                  : 'captured screenshot'
              "
              id="capturedSS"
              style="max-width: 100%; height: auto; max-height: 100%"
              @error="imageError"
            />
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <div v-if="croppingFinished && !imageFailed">
            <v-btn
              :disabled="disableSaveBtn"
              class="px-5 py-5 mx-3"
              style="width: 175px"
              color="error"
              light
              outlined
              @click="
                screenshot = false;
                croppingFinished = false;
                disableBtn = false;
              "
              >Discard</v-btn
            >
            <v-btn
              v-if="!isFaceMatchRequired && !imageFailed"
              :disabled="disableSaveBtn"
              class="px-5 py-5 mx-3"
              style="width: 175px"
              color="primary"
              outlined
              light
              @click="saveScreenshot()"
               >{{faceMatchFailed? "Retry Facematch": "Save Image"}}</v-btn
            >
          </div>
          <div v-if="!croppingFinished && cropperCheck && !imageFailed">
            <v-btn
              class="px-5 py-5 mx-3"
              style="width: 175px"
              color="error"
              light
              outlined
              @click="
                screenshot = false;
                croppingFinished = false;
                cropperCheck = false;
                disableBtn = false;
              "
              >Discard</v-btn
            >
            <v-btn
              @click="processCrop()"
              class="px-5 py-5"
              style="width: 175px"
              color="#2f75c7"
              outlined
              >Crop</v-btn
            >
          </div>
          <div v-if="imageFailed && croppingFinished">
            <v-btn
              @click="
                screenshot = false;
                croppingFinished = false;
                imageFailed = false;
                disableBtn = false;
              "
              class="px-5 py-5"
              style="width: 175px"
              color="#2f75c7"
              outlined
              >Close</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Admin Form -->
    <v-dialog
      v-model="formDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card color="signzy_color">
        <v-toolbar dark color="signzy_color">
          <v-spacer/>
          <v-toolbar-title>Submit Feedback</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-card-text style="overflow-y: auto">
          <v-row justify="center">
            <v-col cols="8">
              <v-card class="elevation-12">
                <v-card-text>
                  <div class="text-center form-header mt-4 mb-4">
                    <p class="headline">
                      {{adminFormHeaderText}}
                    </p>
                    <center>
                      <v-divider
                        style="width: 80%; margin-bottom: 20px"
                      ></v-divider>
                    </center>
                  </div>
                  <div class="text-center">
                    <p class="subheading">
                      {{adminFormSubHeaderText}}
                    </p>
                  </div>
                  <v-row justify="center">
                    <v-col cols="8">
                      <div v-for="i in forms" :key="i.variable">
                        <v-container fluid v-if="
                                i.elementType == 'multiSelectDropdown' &&
                                conditionalRender[i.variable]
                                ">
                              <v-select v-if="typeof i.options == 'string'"
                                v-model="dynamicFormVariables[i.variable]"
                                :items="dropDownOptions[i.options]"
                                :id="i.options"
                                item-text="text"
                                item-value="value"
                                attach
                                chips
                                @focus="setTypeId"
                                :label="i.placeHolder"
                                @change="checkFormDisabled()"
                                multiple
                                light >
                                <template v-slot:prepend-item>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-text-field v-model="searchTerm" placeholder="Search" @input="search"></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                              </v-select>
                              <v-select v-else
                                v-model="dynamicFormVariables[i.variable]"
                                :items="i.options"
                                item-text="text"
                                item-value="value"
                                attach
                                chips
                                :label="i.placeHolder"
                                @change="checkFormDisabled()"
                                multiple
                              ></v-select>
                          </v-container>
                          <v-container fluid v-else-if="
                            i.elementType == 'dropdown' &&
                            conditionalRender[i.variable]
                          ">
                              <v-select v-if="typeof i.options == 'string'"
                                v-model="dynamicFormVariables[i.variable]"
                                :items="dropDownOptions[i.options]"
                                item-text="text"
                                item-value="value"
                                :id="i.options"
                                @focus="setTypeId"
                                attach
                                @change="checkFormDisabled()"
                                :label="i.placeHolder"
                                light >
                                <template v-slot:prepend-item>
                                  <v-list-item>
                                    <v-list-item-content>
                                      <v-text-field v-model="searchTerm" placeholder="Search" @input="search"></v-text-field>
                                    </v-list-item-content>
                                  </v-list-item>
                                  <v-divider class="mt-2"></v-divider>
                                </template>
                              </v-select>
                              <v-select v-else
                                v-model="dynamicFormVariables[i.variable]"
                                :items="i.options"
                                item-text="text"
                                item-value="value"
                                attach
                                @change="checkFormDisabled()"
                                :label="i.placeHolder"
                              ></v-select>
                          </v-container>
                        <v-text-field
                          v-else-if="
                            i.elementType == 'inputField' &&
                            conditionalRender[i.variable]
                          "
                          v-model="dynamicFormVariables[i.variable]"
                          @change="checkFormDisabled()"
                          :label="i.placeHolder"
                        ></v-text-field>
                        <v-text-field
                          v-else-if="
                            i.elementType == 'number' &&
                            conditionalRender[i.variable]
                          "
                          v-model="dynamicFormVariables[i.variable]"
                          @change="checkFormDisabled(true, i.variable)"
                          :label="i.placeHolder"
                        ></v-text-field>
                        <v-textarea
                          solo
                          v-else-if="
                            i.elementType == 'textarea' &&
                            conditionalRender[i.variable]
                          "
                          :label="i.placeHolder"
                          @change="checkFormDisabled()"
                          v-model="dynamicFormVariables[i.variable]"
                        ></v-textarea>
                      </div>
                      <div class="text-center">
                        <v-btn
                          :disabled="isDisabled"
                          color="success"
                          @click="collectData()"
                          >Submit Feedback</v-btn
                        >
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions></v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Test selector -->
    <v-dialog
      v-model="agentFinalFeedbackPicker"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="agentFeedbackData">
        <v-toolbar dark color="signzy_color">
          <v-btn icon dark @click="agentFinalFeedbackPicker=false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
          <v-spacer/>
          <v-toolbar-title>Agent Final Feedback</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10">
              <p class="display-1">
                {{agentFeedbackData.questionText}}
              </p>
              <div style="align-items: center">
                <div style="padding: 10px" v-for="item,id in agentFeedbackData.answerOptions" :key="item.value">
                  <input type="radio" :id="id" :value="item.value" v-model="agentFeedbackData.answer">
                  <label class="agent-feedback-text" :for="id" >{{item.text}}</label>
                </div>
                <br>
              </div>
              <v-btn
                style="left:40%"
                elevation="2"
                border= "thin:solid"
                class="primary--text"
                border-color="black"
              @click="resetAgentFeedbackForm()"
              >
                Go back
              </v-btn>
              <v-btn
                style="left:40%"
                class="ma-2"
                @click="submitAgentFeedback()"
                color="primary"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Image selector -->
    <v-dialog
      v-model="imagePicker"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="signzy_color">
          <v-btn icon dark @click="closePicker()">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
          <v-spacer/>
          <v-toolbar-title>Image Selection</v-toolbar-title>
          <v-spacer/>
        </v-toolbar>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="10">
              <p class="display-1">
                Please select the best image from each category:
              </p>
              <div id="sites">
                <div
                  v-for="(i, index) in screenshots"
                  :key="instructions[index].text + '_____' +index"
                >
                  <v-card
                    class="pa-2 mb-3"
                    style="margin-botton: 20px"
                    v-if="instructions[index].isScreenshotRequired"
                  >
                    <p class="title">
                      {{ instructions[index].text.toUpperCase() }}
                    </p>
                    <v-row>
                      <v-col
                        class="col-3"
                        v-for="(j, jIndex) in i['screenshots']"
                        :key="j"
                        align="center"
                        justify="center"
                      >
                        <div style="align-items: center">
                          <div class="imageSelector">
                            <input
                              type="radio"
                              :name="uniqueString(instructions[index].text)"
                              :id="
                                (instructions[index].text + jIndex)
                                  .split(' ')
                                  .join('')
                              "
                              :value="j"
                              v-model="screenshots[index].finalImage"
                            />
                            <figure
                              style="
                                width: 170px;
                                height: 128px;
                                overflow: hidden;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                border: 1px solid rgba(30, 36, 50, 0.3);
                              "
                              class="ml-3"
                            >
                              <img
                                :src="dataScreenshots[index].screenshots[jIndex]"
                                :alt="instructions[index].text"
                                style="
                                  max-width: 100%;
                                  height: auto;
                                  max-height: 100%;
                                "
                              />
                              <div
                                style="
                                  position: absolute;
                                  bottom: 0;
                                  left: 0;
                                  right: 0;
                                  background-color: rgba(30, 36, 50, 0.75);
                                  cursor: pointer;
                                "
                                @click="
                                  previewImage(
                                    dataScreenshots[index].screenshots[jIndex],
                                    instructions[index].text,
                                    (instructions[index].text + jIndex)
                                      .split(' ')
                                      .join(''),
                                    index,
                                    jIndex
                                  )
                                "
                              >
                                <p
                                  class="px-1 py-1 my-0 mx-0"
                                  style="color: #fff; font-size: 12px"
                                >
                                  View Image
                                </p>
                              </div>
                            </figure>
                          </div>
                          <div class="faceMatchDetails"
                               v-if="instructions &&
                                     instructions[index] &&
                                     instructions[index].isFaceMatchRequired &&
                                     screenshots &&
                                     screenshots[index] &&
                                     screenshots[index].facematchDesc &&
                                     screenshots[index].facematchDesc[jIndex]"
                          >
                            <div class="faceMatchTitle"> FaceMatch Score </div>
                            <div
                              v-for="(i,j) in getFaceMatch(index, jIndex)"
                              :key="j"
                              class = "faceMatchValues"
                            >
                                {{ j + " : "  + i}}
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <p class="subheading" v-if="i['screenshots'].length == 0">
                      No images found for this category!
                    </p>
                  </v-card>
                </div>
              </div>

               <div v-if=" (rgbCheckFailed || heartbeatFailed || speedThresholdFailed || presenterLeft) && (!submitInProgress)"><p  style="
                                  color:red
                                  bottom: 0;
                                  text-align:center
                                  left: 0;
                                  right: 0;
                                  font-size:24px;
                                ">End User is either having connectivity issues or disconnected.Please wait while we try to reconnect...</p>

                                <p class="subheading" style="
                                  bottom: 0;
                                  text-align:center
                                  left: 0;
                                  right: 0;
                                ">Still unable to connect? <strong
                  @click="finish(true, 'User Left')"
                  style="cursor: pointer"
                > Click here </strong>  to end the call or Submit the application without the user.</p>
                                </div>
                    <v-btn
                style="left:40%"
                elevation="2"
                border= "thin:solid"
                class="primary--text"
                border-color="black"
               @click="closePicker()"

                >Go back</v-btn
              >
              <v-btn
                style="left:40%"
                class="ma-2"
                @click="finish()"
                color="primary"
                >Submit</v-btn
              >

              <!-- <v-btn
                style="float: right"
                class="ma-2"
                @click="finish()"
                color="primary"
                >Proceed</v-btn
              > -->
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewImage" width="50vw" class="px-2 py-2">
      <figure
        style="
          width: 100%;
          height: 65vh;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          background-color: rgba(30, 36, 50, 1);
        "
      >
        <img
          :src="viewImageDetails.imageSrc"
          :alt="viewImageDetails.instruction"
          style="max-width: 100%; height: auto; max-height: 100%"
        />
        <div
          style="
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            background-color: rgba(30, 36, 50, 0.3);
          "
          class="py-1 px-1"
        >
          <p class="mx-0 my-0 py-1 px-3" style="color: #fff; font-weight: 600">
            {{ viewImageDetails.instruction }}
          </p>
        </div>
        <div
          style="
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(30, 36, 50, 0.7);
          "
          class="py-2 px-3"
        >
          <v-row style="justify-content: flex-end">
            <v-btn
              class="px-5 py-5 mx-3"
              style="width: 135px"
              color="error"
              light
              outlined
              @click="
                viewImage = false;
                viewImageDetails.imageSrc = '';
                viewImageDetails.instruction = '';
                viewImageDetails.id = '';
              "
              >Close</v-btn
            >
            <v-btn
              class="px-5 py-5 mx-3"
              style="width: 135px"
              color="#fff"
              outlined
              light
              @click="selectImageFromView()"
              >Save Image</v-btn
            >
          </v-row>
        </div>
      </figure>
    </v-dialog>

    <!-- <v-dialog v-model="showPermissionDialog" persistent width="50%"> -->
    <v-dialog v-model="showPermissionDialog" persistent max-width="550">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">fas fa-info-circle</v-icon>Permission
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <p class="title pt-2">
              {{ permissionEmail }} wants to share thier screen
            </p>
          </div>
          <div class="text-right">
            <v-btn
              small
              class="mr-2"
              dark
              color="success"
              @click="grantPermission()"
            >
              <v-icon small class="mr-2">fas fa-check</v-icon>Accept
            </v-btn>
            <v-btn
              small
              class="ml-2"
              dark
              color="error"
              @click="showPermissionDialog = false"
            >
              <v-icon small class="mr-2">fas fa-times</v-icon>Reject
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--go to dashboard prompt dialog-->
    <v-dialog v-model="showGotoDashbaordDialog" persistent max-width="550">
      <v-card>
        <v-card-title>
          <!-- <v-icon class="mr-2">fas fa-info-circle</v-icon>Permission -->
        </v-card-title>
        <v-card-text>
          <div class="text-center">
            <p class="title pt-2" style="color:red; font-size:24px !important;">
              Feedback can't be submitted!
            </p>
            <p class="title pt-2" style="font-size: 20px !important;margin-top:15px;margin-bottom:15px;">
              Customer has initiated an another vkyc call with different banker, this session is expired.
            </p>
          </div>
          <div class="text-center">
            <v-btn
              small
              class="mr-2"
              dark
              color="primary"
              @click="goToDashboard()"
            >
              Return To Dashboard
            </v-btn>

          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--end-->
    <div class="text-center">
      <v-dialog
        v-model="ackDialog"
        persistent
        width="45%"
      >
        <v-card
          elevation="2"
          loading
        >
          <v-card-title class="text-h5 grey lighten-2">
            <v-icon class="mr-2 mb-1" style="color: #FFC107">fas fa-exclamation-triangle</v-icon>
            Network issue detected! 
          </v-card-title>
          <br>
          <v-card-subtitle>
            Due to unstable network, we're encountering technical difficulties with your video recording. 
            {{ errorSendingFirstChunk ? "The recorded video maybe corrupted." : "The recorded video may not be clear or it may contain artifacts/glitches." }}
          </v-card-subtitle>
          <v-card-text>
            By clicking on Proceed, a log stating the same will be generated for debugging.
          </v-card-text>
          <v-card-actions style="margin-top: -1rem; padding-bottom: 1rem">
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="success"
              @click="confirmAck()"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div class="text-center">
      <v-dialog
        v-model="endCallDialog"
        persistent
        width="35%"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Are you sure you want to disconnect the call?
          </v-card-title>
          <br>
          <v-card-actions style="margin-top: -1rem; padding-bottom: 1rem">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="endCallDialog = false"
            >
              Close
            </v-btn>
            <v-btn
              depressed
              color="error"
              @click="finish(true)"
            >
              Disconnect
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div>
      <v-tour name="reJoinTour" v-if="showSteps" :steps="reJoinSteps" :options="tourOptions"></v-tour>
    </div>
  </v-content>
</template>

<script>
import {
  end_points,
  base_url,
  vcip_end_points,
  host,
  chime_end_points
} from "@/config";
import axios from "axios";
import axiosRetry from 'axios-retry';
import {uploadFormData, uploadJSONBody, downloadFile} from "@/flux/persist.flux";
import { parseData, getSession } from "@/Plugins/videoProcess";
import { SignzyVideoService } from "@/Plugins/SignzyVideoService";
import SignzyChimeVideoService from "../../Plugins/SignzyChimeVideoService";
import { SignzyMessagingService } from "@/Plugins/SignzyMessagingService";
import { MultiStreamRecorder } from "@/Plugins/multiStreamRecorder";
import { executeFaceMatch, getFaceMatchScoreFromInflow } from "@/flux/facematch.flux";
import {
  generateDocuments,
  getMisData,
  getHoomanDate,
} from "@/flux/documents.flux";
import { convertToRinger } from "@/Plugins/utils.js";
import "cropperjs/dist/cropper.css";
import Cropper from "cropperjs";
import '@/assets/js/wheelZoom.js';
import { calculateSpeed } from "@/Plugins/internetSpeed.js";
import errorImg  from "../../assets/error.png"
import {logReportData}  from  "@/Plugins/reportLogger.js";
import { Chat } from 'vue-quick-chat';
import '../../assets/css/vue-quick-chat.css';
import dummyImage from '../../assets/dummy-profile.png';
import { v4 as uuidv4 } from 'uuid';
import { reAgent } from '@/assets/subStatus.json';
import VueViewer from 'v-viewer';
import Vue from 'vue';
Vue.use(VueViewer);
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";

const axiosInstance = window.axiosInstance;

let signzyStreamService;
let multiStreamRecorder;
let sms;
let cropper;
let offset = (new Date().getTimezoneOffset() / 60) * -1;
export default {
  components : {
    Chat,
    VuePdfApp
  },
  data: () => ({
    consentData: {
      consentText: null,
      consentTimestamp: Date.now(),
      consentRequired: false
    },
    isCallStatusSent: false,
    showRetryDataSave: false,
    faceMatchFailed:false,
    dataSaveCollectData: false,
    isUploadFailed: false,
    FrontDebugLabel: "VideoPage",
    isLoading: false,
    isFrontendDebugLogs: false,
    videoErrorMessage: "Please wait while we try to access end user's camera feed / microphone.",
    showDropPrompt: false,
    showDropPromptHeartbeat: false,
    // showInactivePopup: false,
    // stayOnCall: false,
    heartbeatStarted: false,
    pingSent: false,
    pingPongTimer: null,
    isVideoConference: false,
    // hangUpCall: false,
    stopRecordingTime: "03:00",
    proofs: [],
    tab: null,
    proofData: {},
    customDocuments: [],
    currentProof: "",
    documentFacematchScore: [],
    items: appGlobals.dashboardMenu,
    keepREOnline: false,
    right: null,
    presenterCame: false,
    noActiveSession: false,
    lightBox: false,
    formDialog: false,
    conferenceRecordingTimeoutInstance: null,
    screenshot: false,
    presenterLeft: false,
    isMobile: /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    logoutDialog: false,
    isScreenshare: false,
    host: host,
    imageLink: "",
    imageArray: [],
    name: "",
    sessionId: "",
    documents: {},
    sessionData: {},
    additionalData: undefined,
    st_width: 5,
    language: "ENGLISH",
    languages: [],
    st_color: "Red",
    disableSaveBtn: false,
    isMute: false,
    multiSessionDialog: false,
    disableBtn: false,
    isVideoEnabled: false,
    step: 0,
    isBoxRequired: false,
    imagePicker: false,
    agentFinalFeedbackPicker: false,
    agentFeedbackData: null,
    idPhoto: "",
    allowPixelCheck: true,
    pixelDetectInterval: null,
    userPhoto: "",
    rgbCheckFailed: false,
    screenshots : [],
    dataScreenshots: [],
    imageUrl: {},
    imageToDisplay: "",
    errorText: "",
    isFinish: false,
    isStart: false,
    snackbar: false,
    color: "error",
    mode: "",
    disableBeginVCIP: true,
    headtbeat: {},
    pulseTimeout: 5,
    showPermissionDialog: false,
    permissionEmail: "",
    pusleIdleTimeout: 20,
    timeout: 6000000,
    requestId: "",
    submitInProgress: false,
    isMultipleSessions: false,
    REThresholdFailed: false,
    languages: [],
    sessionObj: {},
    endUserMinimized: false,
    isScreenshareActive: false,
    videoCallDetailsHeader: "",
    screenShareText: "Screen Share",
    x: null,
    y: "top",
    instructions: [
      "Can you please show your PAN card?",
      "Could you please stay still for your photo?",
      "What is your name?",
    ],
    photos: {},
    docPos: 0,
    internetSpeed: {
      speedMbps: "",
    },
    //flow summary step wise
    stepWiseFlowSummary: new Map(),
    ipData: {
      city: "Loading..",
    },
    client: false,
    timeFromStart: 0,
    recorderCounter: false,
    displayTimer: null,
    recorderColor: "#FF4343",
    recordingStopped: false,
    isRecording: false,
    abruptCancel: false,
    dynamicFormVariables: {},
    forms: [],
    hideVCIPBtn: false,
    radioGroup: "",
    conditionalRender: {},
    isDisabled: true,
    stopChecks: false,
    callDropInitiated: false,
    isRecordingInProgress: false,
    removeVideoMuted: false,
    isFaceMatchRequired: false,
    faceMatchScores: [],
    faceMatchDocs: [],
    faceMatchDocModel: {},
    feedback: [],
    showMissedError: false,
    isEndUserCameraDisabled: false,
    maxRecording: 3,
    showFeedback: false, // make it false before pushing
    feedbackFields: [
      {
        elementType: "dropdown",
        isRequired: false,
        conditionalRendering: false,
        variable: "variable1",
        placeHolder: "test",
        options: ["call success", "call fail"],
      },
      {
        elementType: "inputField",
        isRequired: false,
        conditionalRendering: true,
        variable: "variable2",
        placeHolder: "provide checklist",
      },
    ],
    feedbackVariables: {},
    useStunTurn: false,
    persistHost: '',
    screenshotTimeout: {},
    screenshotPreviewSrc: "",
    REInternetSpeedChecker: null,
    EUInternetSpeedChecker: null,
    cropper: null,
    waitForClientToSendImage:false,
    imageFailed: false,
    croppedImageUrl: "",
    croppingFinished: false,
    cropperCheck : false,
    zoomFun: false,
    viewImage: false,
    userLeftMessage: "The end user has left the call!",
    conferenceRecordingCountdown: null,
    virtualHost: undefined,
    viewImageDetails: {
      instruction: "",
      imageSrc: "",
      id: "",
      index: "",
      jIndex: ""
    },
    errorOverlayMessage: "A screenshot is required for this particular step. You can also take multiple screenshots and choose the best one.",
    errorOverlayHeading: "No screenshots detected",
    errorOverlaySubHeading: "Looks like you did not capture any screenshot. Please take a clear screenshot to move ahead.",
    vbind:undefined,
    showUserOffMessage: false,
    adminALiveInterval: null,
    handshakeSucessfull: false,
    locationFail: false,
    // handshakeChecker: null,
    pingResponse: true,
    heartbeatFailed: false,
    speedThreshold: 200,
    speedThresholdFailed: false,
    showKickoutMessage: false,
    initialHeartbeatFailure: true,
    showGotoDashbaordDialog: false,
    disableMuteBtn:true,
    keepWatch: false,
    locationDenied: false,
    endUserMinimized: false,
    ownInternetSpeed: {
      speedMbps: "0",
    },
    typeId: null,
    dropDownOptions: {},
    searchTerm:"",
    isLegacySetting: false,
    isCustomerMuted: false,
    showMicMuted: false,
    agentData: null,
    browserData: "",
    deviceInfoString:"",
    speedProps : {
      Strength: { icon : "Vectorwifi.svg", status : 'loading', RE : {} , EU : {}},
      Connect: {icon: 'Vectorconnect.svg',status : 'loading', RE : {} , EU : {}},
      Ping : {icon: 'Vectorping.svg', status : 'loading', RE : {} , EU : {}},
      Jitter: {icon: 'Vectorjitter.svg', status : 'loading', RE : {} , EU : {}},
      Download: {icon: 'Vectordownload.svg', status : 'loading', RE : {} , EU : {}},
      Upload: {icon: 'Vectorupload.svg', status : 'loading', RE : {} , EU : {}}
    },
    styleProps : {
      poor : {
        filter : "filter: none ; filter: invert(36%) sepia(59%) saturate(6582%) hue-rotate(348deg) brightness(112%) contrast(99%);",
        EUMsg : "Customer has poor connection! The call may get affected...",
        REMsg : "You have an unstable connection! The call may get affected..."
      },
      average: {
        filter : "filter: none ; filter: invert(78%) sepia(89%) saturate(631%) hue-rotate(320deg) brightness(97%) contrast(99%);",
        EUMsg : "Customer has a slow connection! Video call may lag...",
        REMsg : "You have a slow connection! Video call may lag..."
      },
      good : {
        filter : "filter : none ; filter: invert(61%) sepia(14%) saturate(1527%) hue-rotate(93deg) brightness(94%) contrast(86%);"
      },
      loading : {
        filter : "filter : none ; filter : invert(37%) sepia(40%) saturate(2696%) hue-rotate(174deg) brightness(75%) contrast(101%);"
      },
      offline : {
        filter : "none"
      }
    },
    reSpeedLoading: true,
    endUserSpeedLoading: true,
    hideSpeedTest:false,//temp blocking speed test as it is cloaking
    filter: "filter: none",
    sequenceId : 0,
    reSpeedFailed: false,
    endUserSpeedFailed: false,
    rejoin: false,
    rejoining : false,
    rejoinGuard: true,
    allowRejoin : false,
    disableRejoinInCall : false,
    disableJitsiLogs: false,
    confirmRejoin : false,
    confirmReRecord: false,
    androidApp : false,
    tourOptions: {
      startTimeout : 10000
    },
    reJoinSteps: [{
          target: '.rejoin',
          content: 'Facing Connectivity Issues? Use the option to rejoin the same call!'
    },{
          target: '.rejoin',
          content: 'Please wait for atleast 30 seconds after retrying'
    }],
    enableRecordingPreview: false,
    recordingPreviewHtml: undefined,
    participants:[{
      // name : "Enduser", // removed as not present in the design
      id : 2,
      profilePicture: dummyImage
    }],
    myself: {
      // name : "Banker",
      id : 1,
      profilePicture: dummyImage
    },
    placeholder: 'Type a message',
    colors: {
        header: {
            bg: '#d30303',
            text: '#fff'
        },
        message: {
            myself: {
                bg: '#fb4141',
                text: '#fff'
            },
            others: {
                bg: '#fb4141',
                text: '#fff'
            },
            messagesDisplay: {
                bg: '#f7f3f3'
            }
        },
        submitIcon: '#000000',
        submitImageIcon: '#000000',
    },
    chatMessages: [],
    profilePictureConfig: {
        others: true,
        myself: true,
        styles: {
            width: '30px',
            height: '30px',
            borderRadius: '50%'
        }
    },
    scrollBottom: {
        messageSent: true,
        messageReceived: true
    },
    asyncMode: true,
    currentClickedElement: false,
    showNotificationIcon: false,
    clickedOnChat: false,
    notViewedMsgs: [],
    showCancelRejoin: false,
    enableChat: false,
    videoDevices: [],
    speakers: [],
    microphones: [],
    selectedVideoDevice: undefined,
    selectedMicrophone: undefined,
    selectedSpeaker: undefined,
    enableChime : false,
    adminFormHeaderText: '',
    adminFormSubHeaderText: '',
    globalConfigurations : ["enableChime","virtualHost","keepREOnline"],
    endCallDialog: false,
    firstSequenceBlob: null,
    errorSendingFirstChunk: true,
    successfullySentChunks: [],
    ackDialog: false,
    logObject: reAgent.videoPage,
    startButtonText: "Begin Video KYC",
    showSteps: true,
    images: [],
    isDocPdf: false,
    toolbarConfig: {
      sidebar: false,
      secondaryToolbar: {
        secondaryPresentationMode: true,
        secondaryOpenFile: false,
        secondaryPrint: false,
        secondaryDownload: true,
        secondaryViewBookmark: false,
        firstPage: false,
        lastPage: false,
        pageRotateCw: true,
        pageRotateCcw: true,
        cursorSelectTool: false,
        cursorHandTool: false,
        scrollVertical: false,
        scrollHorizontal: false,
        scrollWrapped: false,
        spreadNone: false,
        spreadOdd: false,
        spreadEven: false,
        documentProperties: true,
      },
      toolbar: {
        toolbarViewerLeft: false,
        toolbarViewerRight: {
          presentationMode: true,
          openFile: false,
          print: false,
          download: false,
          viewBookmark: false,
        },
        toolbarViewerMiddle: {
          zoomOut: true,
          zoomIn: true,
          scaleSelectContainer: true,
        },
      },
      errorWrapper: true,
    },
  }),
  watch:{
      ipData: function (value){
          if(this.keepWatch){
            if(value.city!=="Loading.."){
              this.locationFail = false;
              this.disableBeginVCIP = false;
            }
            this.keepWatch = false;
          }
      }
  },
  computed: {
    imageLinkDisplay(){
      return this.imageLink;
    }
  },
  methods: {
    show (doc) {
      if(this.isImage(doc)) {
        this.$viewerApi({
          images: [doc],
        })
      }
    },
    getImgUrl(value) {
      return require('../../assets/' + value.icon);
    },
    //Generating unique name for screenshots
    uniqueString(string){
      return (string + uuidv4());
    },
    // checking the speedData and updating the cosmetics
    changeStyle(speedData, type) {
    try {
      if(speedData) {
         let poor = 0, average = 0;
         for(let key in this.speedProps) {
           this.speedProps[key][type].value = speedData[key] ? speedData[key] : "0";
           if(key == 'Download' || key == 'Upload') {
             if((speedData[key] < 10 && type == 'RE') || (speedData[key] < 3 && type == 'EU')) {
               poor = poor + 1;
               this.speedProps[key][type].status = 'poor';
             } else if((speedData[key] < 20 && speedData[key] >= 10 && type == 'RE') || (speedData[key] < 15 && speedData[key] >= 3 && type == 'EU')) {
               average = average + 1;
               this.speedProps[key][type].status = 'average';
             } else {
               this.speedProps[key][type].status = 'good';
             }
           } else if(key == 'Ping') {
             if(speedData[key] >= 80) {
               poor = poor + 1;
               this.speedProps[key][type].status = 'poor';
             } else if(speedData[key] < 80 && speedData[key] >= 50) {
               average = average + 1;
               this.speedProps[key][type].status = 'average';
             } else {
               this.speedProps[key][type].status = 'good';
             }
           } else if(key == 'Jitter') {
             if(speedData[key] >= 100) {
               poor = poor + 1;
               this.speedProps[key][type].status = 'poor';
             } else if(speedData[key] < 100 && speedData[key] >= 20) {
               average = average + 1;
               this.speedProps[key][type].status = 'average';
             } else {
               this.speedProps[key][type].status = 'good';
             }
           }
         }

         if(poor > 0) {
           this.speedProps['Strength'][type].status = 'poor';
           this.speedProps['Strength'][type].value = 'Poor';
         } else if(average > 0) {
           this.speedProps['Strength'][type].status = 'average';
           this.speedProps['Strength'][type].value = 'Average';
         } else {
           this.speedProps['Strength'][type].status = 'good';
           this.speedProps['Strength'][type].value = 'Good';
         }

       }
    } catch(err) {
      console.log(err);
    }

    },
    jitsiEvents(event, data) {
      try {
        switch(event) {
          case "rejoin" :
              this.rejoin = data;
              break;
          case "logs" :
              this.addLogToReport(data);
              break;
          case "message" :
              if(sms) {
                sms.emit(data.event, data);
              }
              break;
          case "vueSnack" :
              eventBus.$emit("vueSnack", data);
              break;
          default :
              console.warn("Wrong jitsi event: ", event);
        }
      } catch(err) {
        console.log("error in jitsi event::", err);
      }
    },
    guardRejoinButton(){
      this.rejoinGuard = true;
      setTimeout(() => {
        this.rejoinGuard = false;
      }, 8000);
    },
    reJoinCall() {
      try {
        this.confirmRejoin = false;
        this.rejoining = true;
        if(eventBus) {
          eventBus.$emit("vueLoaderTextChange", "Rejoining...");
          eventBus.$emit("vueLoader", true);
        }
        if(sms) {
          sms.emit("snackBarMessage", "Banker retried connecting");
        }
        this.$tours['reJoinTour'].stop();
        this.rejoin = false;

        this.addLogToReport("Manually reJoined call");

        if(!this.enableChime){
          signzyStreamService.leaveRoom().then(()=>{
            sms.emit("RELeftRoom");
          }).catch((err)=>{
            console.error("Error while leaving room!", err);
            this.addLogToReport("Error while leaving room for p2p rejoin!");
            
            //This is a fallback mechanism, just incase anything goes wrong
            signzyStreamService.retryConnecting(this.isMute, this.isVideoEnabled);
          })
        } else {
          signzyStreamService.retryConnecting(this.isMute, this.isVideoEnabled);
        }
        
        setTimeout(() => {
          if(this.rejoining) {
              eventBus.$emit("vueLoaderTextChange", "Rejoining...Its taking longer than usual");
              this.showCancelRejoin = true;
          }
        },15000);
      } catch(err) {
        console.log("Error while rejoining::", err);
      }
    },
    cancelRejoin() {
      eventBus.$emit("vueLoader", false);
      this.rejoining = false;
      this.showCancelRejoin = false;
      eventBus.$emit("vueSnack", "Seems like enduser internet is down");
    },
    reRecordCall() {
      try {
        this.confirmReRecord = false;
        signzyStreamService.workOnArrayStream(this.restartRecording);
        this.addLogToReport("Manually restarted recording");
      } catch(err) {
        console.error("ReRecording failed", err);
      }
    },
    async setGlobalConfigurations(globalConfigs) {
      try {
        if(globalConfigs) {
          for(let i = 0 ; i < this.globalConfigurations.length ; i++) {
            let configName = this.globalConfigurations[i];
            this[configName] = globalConfigs[configName] ? globalConfigs[configName] : this[configName];
          }
          console.log("this is the configuration that is set",this);
        }
      } catch(err) {
        console.error("Error setting global configurations", err);
      }
    },
    async changeMicrophone(deviceId) {
      if(deviceId) {
        await signzyStreamService.setMicrophone(deviceId);
        signzyStreamService.workOnArrayStream(this.restartRecording);
      }
    },
    async changeSpeaker(deviceId) {
      if(deviceId) {
        await signzyStreamService.setSpeaker(deviceId);
        signzyStreamService.workOnArrayStream(this.restartRecording);
      }
    },
    async changeVideoDevice(deviceId) {
      if(deviceId) {
        await signzyStreamService.setVideoInputDevice(deviceId);
        signzyStreamService.workOnArrayStream(this.restartRecording);
      }
    },
    addLogToReport(data) {
      try {
        let initiationId = sessionStorage.getItem('initiationId');
        let formData = {};
        formData.currentProcess = `Video Conference Error - Banker - ${data}`;
        formData.callId = this.requestId ? this.requestId : "";
        logReportData(this.socket,initiationId,formData);
      }
      catch(err) {
        console.error(err);
      }
    },
    onMessageSubmit: function(message) {
      try {
        message.myself = false;
        message.index = this.chatMessages.length; // assigning a message number
        sms.emit("bankerMessage", message);
        message.myself = true;
        this.chatMessages.push(message);
      } catch(err) {
        console.error("Some error sending message : ", err);
      }
    },
    messageSeenAck() {
      sms.emit("endUserMessageSeenAck");
    },
    receivedMessage(data) {
      try {
        let chatObj = {
          content : data.message,
          participantId : 2,
          type: "text",
          myself: false
        }
        this.chatMessages.push(chatObj);
      } catch(err) {
        console.error("Error showing received message:", err);
      }
    },
    onType: function(event) {
      // console.log("banker is typing....");
    },
    getFaceMatch: function(index, jIndex) {
      try {
        let result = {};
        if(this.instructions && this.instructions[index] && this.instructions[index].isFaceMatchRequired) {
          let unit = '%';
          if(this.screenshots[index] && this.screenshots[index].facematchDesc && this.screenshots[index].facematchDesc[jIndex] && this.screenshots[index].facematchDesc[jIndex]) {
            let ssCount = this.screenshots[index].facematchDesc[jIndex].length;
            for(let j = 0 ; j < ssCount ; j++) {
              let documentType = this.screenshots[index].facematchDesc[jIndex][j].documentType;
              let documentFacematchScore = parseFloat(this.screenshots[index].facematchDesc[jIndex][j].facematchScore).toFixed(0);
              documentFacematchScore = documentFacematchScore == 'NaN' ? 0 : documentFacematchScore;
              result[documentType] = documentFacematchScore + unit;
            }
          }
          return result;
        }
        return {};
      } catch(err) {
        console.error("Error setting facematch on ss selection", err);
        return {};
      }
    },
    async search(val) {
      try {
          const response = await axiosInstance.get(base_url + end_points.dropDownFields + this.typeId + "?value=" + this.searchTerm +"&perPage=100");
          let dropDownValues = response.data.data;
          if(response.headers["totalpages"] && response.headers["totalpages"] > 1) {
            for(let i = 2; i<=response.headers["totalpages"]; i++) {
              if(this.searchTerm != val) {
                dropDownValues=[];
                break;
              }
              const values = await axiosInstance.get(base_url + end_points.dropDownFields + this.typeId + "?value=" + val + "&pageNumber=" + i +"&perPage=100");
              dropDownValues = dropDownValues.concat(values.data.data);
            }
          }
          if(this.searchTerm != val) {
            return;
          }
          this.dropDownOptions[this.typeId] = dropDownValues;
      } catch (error) {
        console.log("Something went wrong while searching ::::", error);
      }
    },
    setTypeId(reference) {
      this.typeId = reference.target.id;
      this.searchTerm = "";
    },
    goToDashboard() {
      if(this.isVideoConference){
        window.open(`${this.host}dashboard/conference/new`, "_self");
      }else{
        // window.open(`${this.host}dashboard`, "_self");
        this.$router.replace("/dashboard/history");
      }
    },
    resetAgentFeedbackForm(){
      this.agentFinalFeedbackPicker=false;
      this.$set(this.agentFeedbackData, "answer", "");
    },
    fallBackErrorImg(e) {
      e.target.src = errorImg
    },
    giveWidth(x) {
      if (x === 1) {
        let w = document.querySelectorAll(".i_am_feedback_dropdown")[0].offsetWidth;
        console.log(w);
        setTimeout(() => {
          for (let i = 0; i < document.querySelectorAll(".v-menu__content.menuable__content__active .v-list-item__title").length; i++) {
            document.querySelectorAll(".v-menu__content.menuable__content__active .v-list-item__title")[i].style.whiteSpace = `inherit`;
          }
          document.querySelector(".v-menu__content.menuable__content__active").style.width = `${w}px`;
            document.querySelector(".v-sheet.v-list").style.wordWrap = `break-word`;
          // if(document.querySelector(".v-menu__content.menuable__content__active").style.left == 300)
          document.querySelector(".v-menu__content.menuable__content__active").style.left = '61%';
        }, 50)
      } else {
        let w = document.querySelectorAll(".i_am_admin_feedback_dropdown")[0].offsetWidth;
        console.log(w);
        setTimeout(() => {
          for (let i = 0; i < document.querySelectorAll(".v-menu__content.menuable__content__active .v-list-item__title").length; i++) {
            document.querySelectorAll(".v-menu__content.menuable__content__active .v-list-item__title")[i].style.whiteSpace = `inherit`;
          }
          document.querySelector(".v-menu__content.menuable__content__active").style.width = `${w}px`;
          document.querySelector(".v-sheet.v-list").style.wordWrap = `break-word`;
          document.querySelector(".v-menu__content.menuable__content__active").style.left = '61%';
        }, 50)
      }
    },
    mannualScreenCapture(){
      let videoToCapture = document.getElementById("vid2");
      if (videoToCapture) {
        setTimeout(async () => {
          let canvasAR = videoToCapture.videoWidth / videoToCapture.videoHeight;
          let canvas = document.createElement('canvas')
          canvas.width = 900;
          canvas.height = 900 / canvasAR;
          let ctx = canvas.getContext("2d");
          ctx.drawImage(videoToCapture, 0, 0, canvas.width, canvas.height);
          let dataURL = canvas.toDataURL("image/jpeg");
          this.screenshotPreviewSrc = "";
          if(cropper){
            cropper.destroy();
          }
          if(this.zoomFun) {
            document.getElementById("capturedSS").dispatchEvent(new CustomEvent('wheelzoom.destroy'));
          }
          this.cropperCheck = false;
          this.imageFailed = false;
          this.croppingFinished = false;
          this.waitForClientToSendImage = false;

          this.screenshotPreviewSrc = dataURL;
          window.clearTimeout(this.screenshotTimeout);
          eventBus.$emit("vueLoader", false);
            // this.screenshot = true;
          this.screenshot = true;
          setTimeout(()=>{
            this.enableCrop();
          },300);
        }, 100);
      }
      else{
        //handle video-capturer issue
      }
    },
    async takeImage() {
      eventBus.$emit("vueLoaderTextChange", "Capturing Image...");
      eventBus.$emit("vueLoader", true);
      this.disableBtn= true;
      this.waitForClientToSendImage = true;
      try {
        let resp =  await axiosInstance.post(end_points.temp_auth, {
          requestId: this.requestId
        });
        if (resp.data.token) {
          if(this.androidApp){
              this.mannualScreenCapture()
          }
          else{
            sms.emit("captureScreenshot", {
            tempAuth: resp.data.token
          });
          this.screenshotTimeout = setTimeout(() => {
            eventBus.$emit("vueLoader", false);
            eventBus.$emit("vueSnack", "Something went wrong!");
            this.waitForClientToSendImage = false;
            this.disableBtn= false;
            try {
                let initiationId = sessionStorage.getItem('initiationId');
                let formData = {};
                formData.currentProcess = 'timeout, error in capturing Screenshot';
                formData.callId = this.requestId ? this.requestId : "";
                logReportData(this.socket,initiationId,formData);
           }catch(err) {
              console.log(err);
              }
          },60*1000)

          }
        } else {
            eventBus.$emit("vueLoader", false);
            eventBus.$emit("vueSnack", "Something went wrong!");
            this.waitForClientToSendImage = false;
            this.disableBtn= false;
            try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = 'error in capturing Screenshot';
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
        }
        catch(err) {
              console.log(err);
        }
        }
      } catch (err) {
        console.log("something went wrong", err);
        eventBus.$emit("vueLoader", false);
        eventBus.$emit("vueSnack", "Something went wrong!");
        this.waitForClientToSendImage = false;
        this.disableBtn= false;
         try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = 'error in capturing Screenshot';
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
        }
        catch(err) {
              console.log(err);
        }
      }
    },
    moveImage(pos) {
      this.imageSource = "";
      this.docPos += pos;
      this.imageSource = this.imageArray[this.docPos];
      this.loadDocumentWrap();
    },
    waitForLocation(delay){
      this.keepWatch = true;
      setTimeout(()=>{
        if(this.ipData.city==="Loading.."){
          this.locationFail = true;
        }else{
          if(this.disableBeginVCIP){
            this.disableBeginVCIP = false;
          }
        }
        if(this.keepWatch){
          this.keepWatch = false;
        }
      },delay)
    },
    loadDocumentWrap() {
      setTimeout(async() => {
        if (this.tab == 1) {
          try {
            this.isDocPdf = false;
            let documentWrap = document.getElementById("documentWrap");
            documentWrap.innerHTML = '';
            if (this.isImage(this.imageSource)) {
              let imgSrc;
              let imageDataResp = await downloadFile(this.imageSource);
              if (imageDataResp.status === 200) {
                imgSrc = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`;
              }
              documentWrap.innerHTML = `<img src="${imgSrc}" style="width:100%; cursor:pointer;" class="viewimage"/>`;
            } else if(this.imageSource.includes('.pdf')) {
              let imgSrc;
              this.isDocPdf = true;
              let imageDataResp = await downloadFile(this.imageSource);
              if (imageDataResp.status === 200) {
                imgSrc = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`;
              }
            }
            else {
              documentWrap.innerHTML = `<iframe src="${this.imageSource}" style="width:100%;" />`;
            }
          } catch (error) {
            console.log(error);
          }
        }
      }, 200);
    },
    imageError() {
      console.log("Received a broken Image");
      this.imageFailed = true
      this.croppingFinished = true;
    },
    enableCrop() {
      if(cropper){
        cropper.destroy()
      }
      const image = document.getElementById("capturedSS");
      let self = this;
      cropper = new Cropper(image, {
        ready() {
          self.cropperCheck = true;
          console.log("cropper Ready")
        },
        viewMode: 2,
        crop(event) {
          console.log(event.detail.x);
          console.log(event.detail.y);
          console.log(event.detail.width);
          console.log(event.detail.height);
          console.log(event.detail.rotate);
          console.log(event.detail.scaleX);
          console.log(event.detail.scaleY);
        },
      });
    },
    isImage(item) {
      item = item.toLowerCase();
      if (
        item.includes(".jpeg") ||
        item.includes(".png") ||
        item.includes(".jpg") || 
        item.includes(".svg") || 
        item.includes(".bmp")
      ) {
        return true;
      }
      return false;
    },
    async shareScreen() {
      if (!this.isScreenshareActive) {
        screenPub = await screenShare(this.sessionId);
      } else {
        window.session.unpublish(screenPub);
        addOriginalPublisher();
      }
    },
    grantPermission() {
      this.showPermissionDialog = false;
      sms.emit("grantSharePermission", {
        email: this.permissionEmail,
      });
    },
    checkFeedback(check, idx) {
      // check is true if value is to be treated as number only
      if (check) {
        let result;
        if (this.feedbackVariables[idx][this.feedbackVariables[idx].length - 1] === ".") {
          result = this.feedbackVariables[idx].replace(/[^0-9.]/g, '');
        } else {
          result = parseFloat(this.feedbackVariables[idx].replace(/[^0-9.]/g, ''));
        }
        if (result.toString() !== "NaN") {
          const res = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.feedbackVariables[idx] = res;
        } else {
          this.feedbackVariables[idx] = "";
        }
      }
      for (let i = 0; i < this.feedbackFields.length; i++) {
        let item = this.feedbackFields[i];
        if (item.isRequired) {
          if (this.feedbackVariables[item.variable]) {
            this.isDisabled = false;
          } else {
            this.isDisabled = true;
            break;
          }
        } else {
          this.isDisabled = false;
        }
        //Check for the conditional Rendering
        if (item.conditionalRendering || item.conditionalRenderingConditions) {

          for (let j = 0; j < this.feedbackFields.length; j++) {
            if (this.feedbackFields[j].variable === item.conditionalRenderingConditions.variable) {
              if ( (this.feedbackFields[j].elementType === "dropdown" || this.feedbackFields[j].elementType === "multiSelectDropdown") && this.feedbackFields[j].optionalValuesRequired ) {
                let checker;
                for (let k = 0; k < this.feedbackFields[j].options.length; k++) {
                  if (this.feedbackFields[j].options[k].text === item.conditionalRenderingConditions.data) {
                    checker = this.feedbackFields[j].options[k].value;

                    break;
                  }
                }
                if (
                  this.feedbackVariables[
                    item.conditionalRenderingConditions.variable
                  ] == checker
                ) {
                  item.conditionalRendering = false;
                } else {
                  item.conditionalRendering = true;
                  delete this.feedbackVariables[item.variable];
                }
              } else {
                  if (
                    this.feedbackVariables[
                      item.conditionalRenderingConditions.variable
                    ] == item.conditionalRenderingConditions.data
                  ) {
                    item.conditionalRendering = false;
                  } else {
                    item.conditionalRendering = true;
                    delete this.feedbackVariables[item.variable];
                  }
                }
                break;
            }
          }
        }
      }
      // return this.isDisabled;
    },
    checkFormDisabled(check, val) {
      // check is true if value is to be treated as number only
      if (check) {
        let result;
        if (this.dynamicFormVariables[val][this.dynamicFormVariables[val].length - 1] === ".") {
          result = this.dynamicFormVariables[val].replace(/[^0-9.]/g, '');
        } else {
          result = parseFloat(this.dynamicFormVariables[val].replace(/[^0-9.]/g, ''));
        }
        if (result.toString() !== "NaN") {
          const res = result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          this.dynamicFormVariables[val] = res;
        } else {
          this.dynamicFormVariables[val] = "";
        }
      }
      let selfIns = this;
      for (let i = 0; i < selfIns.forms.length; i++) {
        let item = selfIns.forms[i];
        if (item.isRequired) {
          if (selfIns.dynamicFormVariables[item.variable]) {
            selfIns.isDisabled = false;
          } else {
            selfIns.isDisabled = true;
            break;
          }
        } else {
          selfIns.isDisabled = false;
        }

        //Check for the conditional Rendering
        if (item.conditionalRendering) {
          for (let j = 0; j < selfIns.forms.length; j++) {
            if (selfIns.forms[j].variable === item.conditionalRenderingConditions.variable) {
              if ( (selfIns.forms[j].elementType === "dropdown" || selfIns.forms[j].elementType === "multiSelectDropdown") && selfIns.forms[j].optionalValuesRequired ) {
                let checker;
                for (let k = 0; k < selfIns.forms[j].options.length; k++) {
                  if (selfIns.forms[j].options[k].text === item.conditionalRenderingConditions.data) {
                    checker = selfIns.forms[j].options[k].value;
                    break;
                  }
                }
                if (
                  selfIns.dynamicFormVariables[
                    item.conditionalRenderingConditions.variable
                  ] == checker
                ) {
                  selfIns.$set(selfIns.conditionalRender, item.variable, true);
                } else {
                  selfIns.$set(selfIns.conditionalRender, item.variable, false);
                  delete selfIns.dynamicFormVariables[item.variable];
                }
              } else {
                  if (
                    selfIns.dynamicFormVariables[
                      item.conditionalRenderingConditions.variable
                    ] == item.conditionalRenderingConditions.data
                  ) {
                    selfIns.$set(selfIns.conditionalRender, item.variable, true);
                  } else {
                    selfIns.$set(selfIns.conditionalRender, item.variable, false);
                    delete selfIns.dynamicFormVariables[item.variable];
                  }
                }
                break;
            }
          }
        }
      }
    },
    async collectData() {
      this.showRetryDataSave = false;
      this.dataSaveCollectData = false;
      console.log(this.dynamicFormVariables);
      this.isDisabled = true; //disabling button on click, to prevent multiple endSession Call
      let dataToSend = this.$store.getters.dataToSave;
      let reportInitiationId = sessionStorage.getItem('initiationId')

      try {
          let initiationId = sessionStorage.getItem('initiationId');
          let formData = {};
          formData.afterCallFeedback = this.dynamicFormVariables;
          formData.currentProcess = `end call feedback captured`;
          formData.callId = this.requestId ? this.requestId : "";
          logReportData(this.socket,initiationId,formData);
      }
      catch(err) {
            console.log(err);
      }

      // adding try catch here to debug if API  is being hit
      try {

          dataToSend["user"]["fieldValues"] = this.dynamicFormVariables;
          dataToSend["user"]["internetSpeed"] = this.internetSpeed;
          dataToSend["user"]["REInternetSpeed"] = this.ownInternetSpeed;
          dataToSend["user"]["reportInitiationId"] = reportInitiationId ? reportInitiationId : "" ;
          dataToSend["user"]["misData"] = getMisData(
            dataToSend["user"],
            this.proofData,
            this.customDocuments,
            this.additionalData,
            this.consentData
          );
          // adding logs to sms - complete user session obj
          if (this.isFrontendDebugLogs) {
            sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
            dataToSend: dataToSend, checkFor: "User Session Data" }})
          }
      } catch(err) {
          try {
              let initiationId = sessionStorage.getItem('initiationId');
              let formData = { ...this.logObject.saveError };
              formData.currentProcess = err;
              formData.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,formData);
          }
          catch(err) {
              console.log(err);
          }
      }
      axiosInstance.post(
        vcip_end_points.save_session,
        dataToSend
      ).then(async(res) => {
        console.log(res);
        if (res && res.data && res.data.result && res.data.result.status == 200) {
          //update feedback submit
          await axiosInstance.post(end_points.sessionFeedbackStatus(this.sessionId), {feedbackFormSubmit: true});

          axiosInstance.post(vcip_end_points.end_session, {
            sessionId: this.sessionId,
            email: this.sessionObj.users[0].email,
            abruptCancel: this.abruptCancel,
          });
          if (!this.abruptCancel) {
            //cancel the call
            try{
              let response = await axiosInstance.post(end_points.update_call_status, {
              sessionId: this.sessionId,
              type: this.isVideoConference ? "conference" : "kyc",
              isCancelled: false,
            });
            if(response.data.status == 'failure'){
              //handing submit button failure cases
              this.isDisabled = false;
              this.showGotoDashbaordDialog = true;
            }
            }catch(err){
              this.isDisabled = false;
              console.log(err);
            }

            window.parent.postMessage({ message: "eventOnCallCompleted" }, "*");
          } else {
            try{
              let response = await axiosInstance.post(end_points.update_call_status, {
              sessionId: this.sessionId,
              type: this.isVideoConference ? "conference" : "kyc",
              isCancelled: true,
              data: {
                ...dataToSend["user"],
                ...{
                  misData: {
                    ...dataToSend["user"]["misData"],
                    ...this.additionalData,
                    callDroppedAt: getHoomanDate(Date.now()),
                  },
                },
              },
            });

              if(response.data.status == 'failure'){
                  //handling case for submit button fail
                  this.isDisabled = false;
                  this.showGotoDashbaordDialog = true;
              }
            }catch(err){
              this.isDisabled = false;
              console.log(err);
            }

          }
          eventBus.$emit("vueLoader", true);
          eventBus.$emit("vueLoader", false);
          store.setData("sessionId", "");
          sessionStorage.videoCallDone = true;
          if (!this.showGotoDashbaordDialog) {
            this.goToDashboard();
            eventBus.$emit("vueSnack", "Succesfully Submited !");
          }
        } else {
          this.showRetryDataSave = true;
          this.dataSaveCollectData = true;
        }
      }).catch((err) => {
        console.log("ERROR in save session", err);
          this.showRetryDataSave = true;
          this.dataSaveCollectData = true;
                try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = { ...this.logObject.saveError };
             formData.currentProcess = err;
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
        }
        catch(err) {
              console.log(err);
        }
      })

    },
    async updloadB64(dataURL) {
      let imageUrl;
      let persist = this.$store.getters.envValues?.persistBase64;
      if(this.$store.getters.envValues?.persistBase64){
        const persistHost = new URL(this.persistHost);
        persist = new URL(this.$store.getters.envValues?.persistBase64);
        persist.hostname = persistHost.hostname;
        persist = persist.href;
      }
      if (this.$store.getters.envValues?.encPersist == "true") {
        imageUrl = await uploadJSONBody(base_url + end_points.upload_files, {
          base64string: dataURL.split("base64,")[1],
          ttl: "3 years",
          mimetype: "image/jpeg",
        });
      } else {
        let formData = new FormData();
        formData.append("base64string", dataURL);
        formData.append("mimetype", "image/jpeg");
        formData.append("ttl", "3 years");
        imageUrl = await uploadFormData(base_url + end_points.upload_files, formData);
      }
      if(imageUrl == "" || !imageUrl) {
        eventBus.$emit("vueSnack", "Uploading failed");
      }
      return imageUrl;
    },
    initiateHearbeat() {
      // sending heartbeat after every 1 second
      // Waiting for 3 seconds with keeping a buffer of .5 seconds
      this.pingPongGenerator = setInterval(() => {
        if(navigator.onLine) {
          if (!this.pingSent) {
            sms.emit("ping" , {
                ping: Date.now(),
            });
            this.pingSent = true;
            this.pingResponse = false;
            this.pingPongTimer = setTimeout(() => {
              if (!this.pingResponse) {
                this.pingSent = false;
                this.initiateHeartbeatDrop();
              }
            }, 3000 + 500)
          }
        }
      }, 1000)
    },
    initiateHeartbeatDrop() {
      // stop pixel check if heartbeat fails
      if (!this.stopChecks) {
        this.allowPixelCheck = false;
        if (this.initialHeartbeatFailure) {
          this.videoErrorMessage = "Please wait while we try to connect with the user";
        } else  {
          //check if RE is offline/Online and throw message accordingly
          if(navigator.onLine){
            this.videoErrorMessage = "Facing connectivity issues, Please wait…";
            // this.videoErrorMessage = "";
            // if(!this.presenterCame && !this.stayOnCall && !this.hangUpCall) {
            //   this.showInactivePopup = true;
            // } else {
            //   this.showInactivePopup = false;
            // }
          }
          else{
            this.videoErrorMessage = "It looks like your are Offline, Please check your internet connection.";
            // this.showInactivePopup = false;
          }
        }
        if(this.enableChime)
          this.videoErrorMessage = ""; //dont show socket failures

        if (this.heartbeatFailed) {
          this.showDropPromptHeartbeat = true;
          sms.emit("heartbeatFailed");
        } else {
          this.heartbeatFailed = true;
          console.log("End user didn't respond to heartbeat");
          // this.showInactivePopup = true;
          // this.stayOnCall = false;
        }
      } else {
        console.log("Checks have been stopped");
      }

    },
    processCrop() {
      try {
        this.croppedImageUrl = cropper.getCroppedCanvas().toDataURL("image/jpeg");
        cropper.destroy();
        this.cropperCheck = false;
        this.screenshotPreviewSrc = this.croppedImageUrl;
        this.croppingFinished = true;
        this.zoomFun = true;
        wheelzoom(document.getElementById("capturedSS"), {zoom: 0.05, maxZoom: 2.0})
      } catch (err) {
        console.log(err);
        eventBus.$emit("vueSnack", "Someting went wrong in processing image! Please Try again.");
        this.screenshot = false;
        this.croppingFinished = false;
        this.cropperCheck = false
      }
    },
    async saveScreenshot() {
      try {
        var canvas = document.getElementById("sheet");
        var ctx = canvas.getContext("2d");
        var image = new Image();
        image.src = this.croppedImageUrl;
        this.disableSaveBtn= true;

        image.onload = function () {
          canvas.height = image.height + 135;
          canvas.width = image.width+12;
          ctx.fillStyle = "white";
          ctx.fillRect(6, image.height, image.width, 128);
          ctx.drawImage(image, 6, 6, image.width, image.height);
        }
        setTimeout( async() => {
        //if (!this.instructions[this.step].hideWatermark) {
          ctx.font = "12px Arial";
          ctx.fillStyle = "black";
          ctx.textAlign = "left";
          var textCord = image.height + 18;
          var leftPadding = 16;
          ctx.fillText(`CAF Number: ${this.$store.getters.cafNumber ?? "N/A"}`, leftPadding, textCord);
          textCord+=15;
          ctx.fillText(`Geo Location (lat, lon): ${this.ipData?.latitude ?? "N/A"}, ${this.ipData?.longitude ?? "N/A"}`, leftPadding, textCord);
          textCord+=15;
          ctx.fillText(`Capture date & time: ${this.getDateTimeString()}`, leftPadding, textCord);
          textCord+=15;
          ctx.fillText(`IP Address: ${this.ipData.ipAddress}`, leftPadding, textCord);
          textCord+=15;
          if(this.browserData && this.browserData.deviceInfo && this.browserData.deviceInfo.complete_device_name
          && this.browserData.deviceInfo.os && this.browserData.deviceInfo.os.name
          && this.browserData.browserName && this.browserData.browserVersion) {
            ctx.fillText(`Device info: ${this.browserData.deviceInfo.complete_device_name} ${this.browserData.deviceInfo.os.name} ${this.browserData.browserName} ${this.browserData.browserVersion}`, leftPadding, textCord);
            textCord+=15;
          }
          ctx.fillText(
            `Location: ${this.ipData.city}, ${this.ipData.regionName}`,
            leftPadding,
            textCord
          );
          textCord+=15;
          if(this.agentData && this.agentData.name) {
              ctx.fillText(`Employee name: ${this.agentData.name}`,leftPadding,textCord);
              textCord+=15;
          }
          if(this.agentData && this.agentData.misData && this.agentData.misData.employeeCode) {
              ctx.fillText(`Employee code: ${this.agentData.misData.employeeCode}`,leftPadding,textCord);
              textCord+=15;
          }
          if(this.agentData && this.agentData.email) {
              ctx.fillText(`Employee email: ${this.agentData.email}`,leftPadding,textCord);
          }
        //}
        let bleh = canvas.toDataURL("image/jpeg");
        let imageUrl = await this.updloadB64(bleh);
        //incase of upload API failure showing error message to RE
        if(!imageUrl) {
          this.isUploadFailed = true;
          eventBus.$emit("vueSnack", "Failed to Fetch Image Url.");
          this.disableSaveBtn= false;
          return;
        }
        this.isUploadFailed = false;
        if(this.step < 0){
          eventBus.$emit("vueSnack", "Screenshot step index out of bound.")
          this.disableSaveBtn=false;
          return;
        }
        this.screenshots[this.step].screenshots.push(imageUrl);
        let imageDataResp = await downloadFile(imageUrl);
        if (imageDataResp.status === 200) {
          let imgSrc = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`;
          this.dataScreenshots[this.step].screenshots.push(imgSrc);
        }
        if (this.instructions[this.step].isFaceMatchRequired) {
          if(!this.$store.getters.userData.customerData.id){
              eventBus.$emit("vueSnack", "Failed to fetch Customer Id. Retry!")
              this.faceMatchFailed=true;
              this.disableSaveBtn=false;
              return;
          }
          let res= await executeFaceMatch(
            imageUrl,
            this.faceMatchDocs,
            this.$store.getters.userData.customerData.id
          );

          if(res.status!=0)
          {
            if(res.faceMatchError && res.faceMatchError.length > 1) {
              let faceMatchErrorString = "Face match failed. " + res.faceMatchError + ". Retry !"
              eventBus.$emit("vueSnack", faceMatchErrorString)
            }else{
            eventBus.$emit("vueSnack", "Face match failed. Retry!");
            }
            this.faceMatchFailed=true;
            this.disableSaveBtn=false;
            return;
          }
          if(res.Percent){
            // if (this.screenshots[this.step].faceMatchScore && this.screenshots[this.step].faceMatchScore.length > 0 ) {
            //    this.screenshots[
            //     this.step
            //   ].faceMatchScore = res.Percent
            // } else {
            //   this.screenshots[
            //     this.step
            //   ].faceMatchScore = res.Percent;
            // }
            // needs to be reviewed
                       this.screenshots[
             this.step
           ].faceMatchScore = res.Percent;
          }
          if(res.facematchDesc){
            if (this.screenshots[this.step].facematchDesc && this.screenshots[this.step].facematchDesc.length > 0 ) {
              this.documentFacematchScore = res.facematchDesc;
              this.screenshots[
                this.step
              ].facematchDesc.push(res.facematchDesc);
            } else {
              this.documentFacematchScore = res.facematchDesc;
              this.screenshots[
                this.step
              ].facematchDesc = [res.facematchDesc];
            }
            this.documentFacematchScore = res.facematchDesc;
            // this.screenshots[
            //   this.step
            // ].facematchDesc = res.facematchDesc;
          }
        }
        this.faceMatchFailed=false;
        this.screenshot = false;
        this.croppingFinished = false;
        document.getElementById("capturedSS").dispatchEvent(new CustomEvent('wheelzoom.destroy'));
        this.zoomFun = false;
        //enabled after completion
        this.disableBtn=false;
        this.disableSaveBtn= false;
        },100)
      } catch (err) {
        console.log(err);
        this.disableSaveBtn= false;
        this.disableBtn=false;
        eventBus.$emit("vueSnack", "Someting went wrong!");
      }
    },
    async possibleFacematchDocuments(documents) {
      this.faceMatchDocs = [];
      this.faceMatchDocModel = {};
      Object.keys(documents).forEach(key => {
        if(documents[key].useForFaceMatch && documents[key].image) {
          this.faceMatchDocs.push({
            documentName: key,
            documentImage: documents[key].image
          });
        }
      });
      if(this.faceMatchDocs.length === 0) {
        for (let i in documents) {
          //debugger;
          let currentImage =
            documents[i].image ||
            (documents[i].images ? documents[i].images[0] : "");
          if (currentImage) {
            if (!currentImage.includes("https://")) {
              //updalod persit image and process
              currentImage = await this.updloadB64(currentImage);
            }
            this.$set(this.faceMatchDocModel, i, false);
            this.faceMatchDocs.push({
              documentName: i,
              documentImage: currentImage,
            });
          }
        }
      }
    },
    selectMaxFaceMatchImage() {
      //bydefault selecting the max facematch score image on image picker page
      try {
        for(let i = 0 ; i < this.screenshots.length ; i++) {
          if(this.instructions[i].isFaceMatchRequired) {
            let maxFaceMatchScore = -1;
            if(this.screenshots[i].facematchDesc) {
              for(let j = 0 ; j < this.screenshots[i].facematchDesc.length ; j++) {
                let facematchScore = 0;
                for(let k = 0 ; k < this.screenshots[i].facematchDesc[j].length ; k++) {
                  facematchScore += this.screenshots[i].facematchDesc[j][k].facematchScore;
                }
                if(facematchScore > maxFaceMatchScore) {
                  maxFaceMatchScore = facematchScore;
                  this.screenshots[i].finalImage = this.screenshots[i].screenshots[j];
                }
              }
            }
          }
        }
      } catch(err) {
        console.error(err);
      }
    },
    confirmAck()  {
      try {
        let initiationId = sessionStorage.getItem('initiationId');
        let formData = {};
        if(this.errorSendingFirstChunk) {
          formData.currentProcess = `Due to banker's unstable network condition, the first video chunk was missed, the recorded video maybe corrupted.`;
        } else {
          formData.currentProcess = `Due to banker's unstable network condition, the recorded video may contain issues.`;
        }

        formData.callId = this.requestId ? this.requestId : "";
        logReportData(this.socket,initiationId,formData);
      }
      catch(err) {
        console.log("Error while logging confimation acknowledgement", err);
      }

      this.ackDialog = false;
      this.checkForScreenshots();
    },
    async preValidatingChunks() {
      if(this.isRecording){
        if(this.successfullySentChunks.indexOf(1) === -1) {
          if(this.firstSequenceBlob) {
            eventBus.$emit("persistedLoader", true, "Please stand by until the first video chunk is uploaded!");

            const fd = new FormData();
            fd.append("vchuck", this.firstSequenceBlob);

            let firstBlobAxiosRetryInstance = axios.create({
              validateStatus: function (status) {
                return status == 200;
              },
            });

            axiosRetry(firstBlobAxiosRetryInstance, { 
              retries: 10,
              retryDelay: (retryCount) => {
                console.log(`Custom retry initiated for sequence Id: 1 | current attempt: ${retryCount}`);
                return 800; // time interval between retries
              },
              retryCondition: (error) => {
                // if retry condition is not specified, by default idempotent requests are retried
                return error.response.status !== 200;
              },
            });

            multiStreamRecorder?.pause();
            eventBus.$emit("vueSnack", "Recording has been paused temporarily, please stand by!");
            let retryFailed = true;

            await firstBlobAxiosRetryInstance.post(vcip_end_points.send_chunks,
              fd, 
              {
                headers: {
                  sessionid: this.sessionId,
                  sequenceid: 1,
                  senttimestamp: Date.now()
                }
              }
            ).then((res) => {

              this.successfullySentChunks.push(1);
              this.errorSendingFirstChunk = false;
              retryFailed = false;

              try {
                let initiationId = sessionStorage.getItem('initiationId');
                let formData = {};
                formData.currentProcess = `Retry successful! First chunk data was sent successfully!`;
                formData.callId = this.requestId ? this.requestId : "";
                logReportData(this.socket,initiationId,formData);
              }
              catch(err) {
                console.log("Error while logging first chunk retry", err);
              }

            }).catch((err) => {
              retryFailed = true;
              console.log("Something went wrong while sending first chunk: ", err);
            }).finally(()=> {
              if(retryFailed){
                try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let formData = {};
                  formData.currentProcess = `Retry failed! Couldn't send the first chunk due to network issues, the video may be corrupted!`;
                  formData.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,formData);
                }
                catch(err) {
                  console.log("Error while logging first chunk retry", err);
                }
              }

              eventBus.$emit("persistedLoader", false);
              multiStreamRecorder?.resume();
              eventBus.$emit("vueSnack", "Recording resumed!");
            })
          } else {
            this.errorSendingFirstChunk = true;
          }
        } else {
          this.errorSendingFirstChunk = false;
        }

        if((this.successfullySentChunks.length < Math.ceil(0.75 * this.sequenceId)) || this.errorSendingFirstChunk){
          this.ackDialog = true;
        } else {
          this.checkForScreenshots();
        }
      } else {
        this.checkForScreenshots();
      }
    },
    checkForScreenshots() {
      let screenshotsNumber = 0;
      this.selectMaxFaceMatchImage();
      if (
        this.instructions[this.step].isScreenshotRequired &&
        this.screenshots[this.step].screenshots.length === 0
      ) {
        this.showMissedError = true;
      } else if (this.instructions[this.step].isFeedbackRequired) {
          this.feedbackFields = this.instructions[this.step].feedbackFields;
          this.showFeedback = true;
          this.checkFeedback();
      } else {
        this.instructions.forEach((instruction) => {
          if (instruction.isScreenshotRequired) {
            screenshotsNumber += 1;
          }
        });
        if (screenshotsNumber > 0) {
          this.screenshots.forEach((obj, idx) => {
            if(obj.screenshots?.length === 1) {
              this.screenshots[idx].finalImage = obj.screenshots[0];
            }
          })
          this.imagePicker = true;
        } else {
          this.finish();
        }
      }

      this.$tours['reJoinTour'].stop();
    },
    submitAgentFeedback(){
      eventBus.$emit("vueLoaderTextChange", "Please wait..");
      eventBus.$emit("vueLoader", true);
      sms.emit("agentFeedbackCaptured", this.agentFeedbackData);
      setTimeout(()=> {
        eventBus.$emit("vueLoaderTextChange", "It is taking longer than expected..Please wait..");
      }, 5000);
      setTimeout(()=> {
      console.log("Ack NOT received from the end user. timed out");
      eventBus.$emit("vueLoader", false);
      // this.agentFinalFeedbackPicker=false;
      this.checkForScreenshots();
      },15000)
    },
    muteAudioView() {
      this.isMute = !this.isMute;
      this.isMute
        ? signzyStreamService.muteTrack("audio")
        : signzyStreamService.unMuteTrack("audio");
      sms.emit("agent audio muted", this.isMute)
    },
    muteVideoView() {
      this.isVideoEnabled = !this.isVideoEnabled;
      if(this.enableChime) {
        let cameraState = this.isVideoEnabled;
        sms.emit("agentCameraChange",cameraState);
      }
      this.isVideoEnabled
        ? signzyStreamService.muteTrack("video")
        : signzyStreamService.unMuteTrack("video");
    },
    initiateRecording(arrayStreams) {
      this.isRecordingInProgress = true;
       try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = `Recording Initiated at ${new Date(Date.now())}`;
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
        }
        catch(err) {
              console.log(err);
        }
      console.log("Recording Initiated!!");
      if (this.isFrontendDebugLogs) {
        sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId, type: "recordingInitiated"}})
      }
      let aspectRatio = 0.564971751;
      let videos = document.getElementById("vid2");
      try {
        aspectRatio = videos.videoHeight / videos.videoWidth;
        if (Number.isNaN(aspectRatio)) {
          aspectRatio = 0.564971751;
        }
      } catch (error) {
        aspectRatio = 0.564971751;
      }
      console.log("Aspect Ratio: ", aspectRatio);
      if(this.browserData && this.browserData.deviceInfo && this.browserData.deviceInfo.complete_device_name
          && this.browserData.deviceInfo.os && this.browserData.deviceInfo.os.name
          && this.browserData.browserName && this.browserData.browserVersion)
      {this.deviceInfoString = this.browserData.deviceInfo.complete_device_name
                              +this.browserData.deviceInfo.os.name
                              +this.browserData.browserName
                              +this.browserData.browserVersion;
      }
      //video watermarking
      let watermarkDetails = {
        location: this.info? this.info:"",
        ip:(this.ipData && this.ipData.ipAddress)? this.ipData.ipAddress:"",
        deviceInfo: this.deviceInfoString? this.deviceInfoString: "" ,
        employeeName: (this.agentData && this.agentData.name)?this.agentData.name:"",
        employeeCode: (this.agentData && this.agentData.misData && this.agentData.misData.employeeCode)? this.agentData.misData.employeeCode : "",
        employeeEmail: (this.agentData && this.agentData.email)?this.agentData.email:"",
        cafNumber: this.$store.getters.cafNumber ?? "N/A"
      };

       multiStreamRecorder = new MultiStreamRecorder(watermarkDetails, arrayStreams, {
        mimeType: "video/webm;codecs=vp9",
        frameInterval: 200,
        video: {
          width: 600,
          height: 600 * aspectRatio,
        },
      });

      multiStreamRecorder.start(4500, (event) => {
        if (this.isFrontendDebugLogs) {
          sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
          info: {"eventDataSize": event.data.size}, type: "eventDataSize"}})
        }
        if (typeof event.data === "undefined") {
          this.recordingStopped = true;
           try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = `Recording stopped at ${new Date(Date.now())} due to undefined data`;
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
            }
           catch(err) {
              console.log(err);
            }
          return;
        }
        if (event.data.size === 0) {
          this.recordingStopped = true;
           try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = `Recording stopped at ${new Date(Date.now())} due to zero size data`;
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
            } catch(err) {
              console.log(err);
            }
          return;
        }

        // preview of chunk data on the html video element
        // pip mode will work in chrome but now mozilla
        // need to handle the pip for mozilla separately
        try {
          if(this.enableRecordingPreview && (!this.recordingPreviewHtml || !this.recordingPreviewHtml.srcObject)) {
            this.recordingPreviewHtml = document.getElementById('recordingPreview');
            this.recordingPreviewHtml.srcObject = event.srcElement.stream;
          }
        } catch(err) {
          console.log("Error previewing the recording", err);
        }

        let fd = new FormData();
        fd.append("vchuck", event.data);
        this.sequenceId++;

        if(this.sequenceId === 1){
          console.log("Preserving first blob data!");
          this.firstSequenceBlob = event.data;
        }

        let customAxiosInstance = axios.create({
          validateStatus: function (status) {
            return status == 200;
          },
        });

        axiosRetry(customAxiosInstance, { 
          retries: 5,
          retryDelay: (retryCount) => {
            console.log(`Retry initiated for sequence Id: ${this.sequenceId} | current attempt: ${retryCount}`);
            return 650; // time interval between retries
          },
          retryCondition: (error) => {
            // if retry condition is not specified, by default idempotent[200-300 are valid] requests are retried
            return error.response.status !== 200;
          },
        });

        customAxiosInstance.post(vcip_end_points.send_chunks,
          fd, 
          {
            headers: {
              sessionid: this.sessionId,
              sequenceid: this.sequenceId,
              senttimestamp: Date.now()
            }
          }
        ).then((res) => {
            this.successfullySentChunks.push(this.sequenceId);
            if(this.sequenceId === 1){
              this.errorSendingFirstChunk = false;
            }
            this.recorderColor = "#FF4343";
            this.recordingStopped = false;
          }).catch((err) => {
            console.log("Error in video recording", err);
            this.recorderColor = "#E2DB34";
            this.recordingStopped = true;
            if (this.isFrontendDebugLogs) {
              sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId, type: "errorPushingChunks"}})
            }
            try {
              let initiationId = sessionStorage.getItem('initiationId');
              let formData = {};
              formData.currentProcess = `Recording stopped at ${new Date(Date.now())} due to ${err}`;
              formData.callId = this.requestId ? this.requestId : "";
              logReportData(this.socket,initiationId,formData);
            } catch(err) {
              console.log(err);
            }
          });
      });
    },
    restartRecording(arrayStreams) {
      try {
        if (this.isRecordingInProgress) {
          console.log("Restarting Recording");
          multiStreamRecorder.resetVideoStreams(arrayStreams);
           try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = `Recording restarted at ${new Date(Date.now())}`;
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
        }
        catch(err) {
              console.log(err);
        }
        }
      } catch (error) {
         try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = `Recording restarted failed at ${new Date(Date.now())}`;
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
        }
        catch(err) {
              console.log(err);
        }
        console.log("Error at restart recordings", error);
      }
    },
    async startVcip() {
      console.log("[!] Presenter came recording!");
      if (this.isFrontendDebugLogs) {
        sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
        info: {"message": "Presenter came recording!"}, type: "InitiatingRecording"}})
      }
      if(this.disableRejoinInCall) {
        this.allowRejoin = false;
      }
      this.hideVCIPBtn = true;
      signzyStreamService.workOnArrayStream(this.startRecording);
      sms.emit("showTimerButton",{showTimer:true})
      if (this.isVideoConference) {
        let timeElapsed = this.maxRecording * 60;
        this.conferenceRecordingCountdown = setInterval(() => {
          this.stopRecordingTime = convertToRinger(timeElapsed);
          timeElapsed -= 1;
        }, 1000);

        //Stopping the recoder after 3 minutes
        this.conferenceRecordingTimeoutInstance = setTimeout(() => {
          if (this.isRecording) {
            multiStreamRecorder.stop();
          }
          window.clearInterval(this.conferenceRecordingCountdown);
          this.stopRecordingTime = "done";
        }, timeElapsed * 1000);
        sms.emit("startVideoConference",{
          showClock: this.isRecording
        });
      } else {
        sms.emit("changeInstruction", {
          instructionNumber: 0,
        });
      }
      //push session data on begin vkyc
      // data which is collected before call
      let dataObj = {
        geo: this.dataHelper("geo"),
        browserData: this.dataHelper("browser"),
        vcipAdminDetails: this.$store.getters.agentData,
        forensicsData: this.dataHelper("forensicsData")
      }

      await this.submitCallSessionData(dataObj);

    },
    startRecording(arrayStreams) {
      if (this.isFrontendDebugLogs) {
        sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
        info: {"arrayStreamLength": arrayStreams.length, "message": "Start Recording callback executing !"}, type: "InitiatingRecording"}})
      }
      if (this.isRecording) {
        if (this.isFrontendDebugLogs) {
          sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
          info: {"arrayStreamLength": arrayStreams.length, "message": "Call is getting initiated !"}, type: "InitiatingRecording"}})
        }
        if(this.recorderCounter) {
          window.clearInterval(this.recorderCounter);
        }
        this.recorderCounter = setInterval(() => {
          this.timeFromStart += 1;
          if (!this.recordingStopped) {
            this.displayTimer = convertToRinger(this.timeFromStart);
          }
        }, 1000);
        if (arrayStreams && arrayStreams.length == 4) {

          setTimeout(() => {
            if (!this.isRecordingInProgress) {
              this.initiateRecording(arrayStreams);
            }
          }, 1000);
        } else{
            this.recordingStopped = true;
              try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = `Recording start failed at ${new Date(Date.now())}`;
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
            }
            catch(error) {
              console.log(error);
            }
            if (this.isFrontendDebugLogs) {
            sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
            info: {"arrayStreamLength": arrayStreams.length, "message": "Call recording failed !"}, type: "recordingFailed"}})
            }
        }
      }
    },
    checkStringValInObject(object){
      for(let key in object){
        if(typeof(object[key]) == 'string'){
          return object;
        }else{
          checkStringValInObject(object[key])
        }
      }
    },
    async hangUp() {
      try {
        this.showRetryDataSave = false;
        this.dataSaveCollectData = false;
        if (this.isRecording) {
            try {
             let initiationId = sessionStorage.getItem('initiationId');
             let formData = {};
             formData.currentProcess = `Recording ended at ${new Date(Date.now())}`;
             formData.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,formData);
           }
           catch(error) {
              console.log(error);
        }
          if(multiStreamRecorder) {
            multiStreamRecorder.stop();
          }
        }
        if(this.enableChime) {
          signzyStreamService.stopLocalVideo();
        } else {
          await signzyStreamService.leaveRoom();
        }
      } catch (error) {
        console.log("error in hangup", error);
      }
      sms.emit("hangUp", {
        success: !this.abruptCancel,
        currentProcess: this.isRecording ? `Call completed, succesfully sent ${this.successfullySentChunks} chunks out of ${this.sequenceId} chunks` : false,
      });
      if (!this.abruptCancel) {
        // Sending call completed event
        try {
          await axiosInstance.post(
            end_points.update_call_timestamp(this.requestId),
            {
              callEnded: Date.now() + offset * 3600000,
            }
          );
        } catch (err) {
          console.log("Something went wrong in emitting the callEnd event", err);
          if (this.isFrontendDebugLogs) {
            sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
            error: err, checkFor: "Call Time stamps request" }})
          }
        }

      }
      try {
        let fields = this.$store.getters.userDetails.fields;
        if (fields && fields.length != 0) {
          this.formDialog = true;
          this.showSteps = false;
        } else {
          let dataToSend = this.$store.getters.dataToSave;
          axiosInstance.post(
            vcip_end_points.save_session,
            dataToSend
          ).then(async(res) => {
            console.log(res);
            if (res && res.data && res.data.result && res.data.result.status == 200) {
              if (this.isFrontendDebugLogs) {
                sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
                resp: res, checkFor: "inFlow Summary post to server" }})
              }
              axiosInstance.post(vcip_end_points.end_session, {
                sessionId: this.sessionId,
                email: this.sessionObj.users[0].email,
                abruptCancel: this.abruptCancel,
              });
              if (!this.abruptCancel) {
                try {
                  let response = await axiosInstance.post(end_points.update_call_status, {
                    sessionId: this.sessionId,
                    type: this.isVideoConference ? "conference" : "kyc",
                    isCancelled: false,
                  });
                  if (response.data.status == 'failure') {
                    //handling case for submit button fail
                    this.showGotoDashbaordDialog = true;
                  }
                } catch(err) {
                  console.log(err);
                }

              }
              eventBus.$emit("vueLoader", true);
              eventBus.$emit("vueLoader", false);
              this.formDialog = false;
              sessionStorage.videoCallDone = true;
              this.goToDashboard();
              store.setData("sessionId", "");

              eventBus.$emit("vueSnack", "Succesfully Submited !");
            } else {
              this.showRetryDataSave = true;
              this.dataSaveCollectData = false;
            }
          }).catch((err) => {
              if (this.isFrontendDebugLogs) {
                sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
                error: err, checkFor: "inFlow Summary post to server" }})
              }
              console.log("Error in storing data!!!!", err);
              this.showRetryDataSave = true;
            this.dataSaveCollectData = false;
          })
        }
      } catch (error) {
        console.log(error);
        eventBus.$emit("vueSnack", "Something went wrong!");
      }
    },
    setLanguage(language) {
      this.language = language;
      sms.emit("changeLanguage", {
        language,
      });
    },
    muteRemoteVideo(muted) {
      this.removeVideoMuted = muted;
    },
    navigate(a) {
      /// Checking if SS is taken
      if (
        this.instructions[this.step].isScreenshotRequired &&
        this.screenshots[this.step].screenshots.length === 0 &&
        a > 0
      ) {
        this.showMissedError = true;
      } else {
        if (this.instructions[this.step].isFeedbackRequired && a > 0) {
          this.feedbackFields = this.instructions[this.step].feedbackFields;
          this.showFeedback = true;
          this.checkFeedback();
        } else {
          this.moveToNext(a);
        }
      }
    },
    redoInstruction() {
      this.resetFeedback();
    },
    completedFeedback() {
      this.feedback[this.step] = this.feedbackVariables;
      if (this.isEmpty(this.feedback[this.step]) && this.instructions[this.step].isFeedbackRequired) {
        let dummyObj = {};
        for(let i = 0; i < this.instructions[this.step].feedbackFields.length; i++) {
          Object.keys(this.instructions[this.step].feedbackFields[i]).forEach(key => {
            if(key === "variable") {
              dummyObj[this.instructions[this.step].feedbackFields[i][key]] = ""
            }
          });
        };
        this.feedback[this.step] = dummyObj;
      }
      if(this.step === this.instructions.length - 1) {
      console.log("this is last feedback ")

      // here taking a final image of all the captured screenshots
      let screenshotsNumber = 0;
        this.instructions.forEach((instruction) => {
          if (instruction.isScreenshotRequired) {
            screenshotsNumber += 1;
          }
        });
        if (screenshotsNumber > 0) {
          this.screenshots.forEach((obj, idx) => {
            if(obj.screenshots?.length === 1) {
              this.screenshots[idx].finalImage = obj.screenshots[0];
            }
          })
          this.imagePicker = true;
          this.resetFeedback();
        } else {
          this.finish();
        }
      } else {
      // not the final page. More to come ahead.
        this.resetFeedback();
        this.moveToNext(1);
      }
    },
    retainFeedback() {
      if (this.instructions[this.step].isFeedbackRequired) {
        if (this.feedback[this.step]) {
          this.feedbackVariables = this.feedback[this.step];
        }
      }
      let inflowSummary = this.processInflowData(this.step);
      this.stepWiseFlowSummary.set(this.step,inflowSummary);
      let flowSummaryWrapper = []
      for (const value of this.stepWiseFlowSummary.values()) {
        flowSummaryWrapper.push(value)
      }
      let finalDataObj = {inFlowSummary:flowSummaryWrapper,screenshots: this.screenshots, agentFeedbackData: this.agentFeedbackData}
      this.submitCallSessionData(finalDataObj)
    },
    closePicker() {
      this.imagePicker = false;
      this.retainFeedback()
    },
    moveToNext(a) {
      this.step += a;
      this.retainFeedback();
      this.isStart = false;
      this.isFinish = false;
      if (this.step == 0) {
        this.isStart = false;
        this.isFinish = false;
      } else if (this.step == this.instructions.length - 1) {
        this.isStart = true;
        this.isFinish = true;
      } else {
        this.isStart = true;
        this.isFinish = false;
      }

      if (this.instructions[this.step].isIDCardBoxRequired) {
        this.isBoxRequired = true;
      } else {
        this.isBoxRequired = false;
      }

      sms.emit("changeInstruction", {
        instructionNumber: this.step,
      });
    },
    resetFeedback() {
      this.isDisabled = true;
      this.showFeedback = false;
      this.feedbackVariables = {};
    },
    isEmpty(obj) {
      for(var key in obj) {
        if(obj.hasOwnProperty(key))
          return false;
      }
      return true;
    },
    generateFeedback( feedback = {}, instr = {} ){
      if (this.isEmpty(feedback) || !instr.isFeedbackRequired) {
        return {};
      }

      const feedbackObj = {};

      instr.feedbackFields?.forEach(({ variable, optionalValuesRequired, optionalValues }) => {
        const value = feedback[variable];

        if (!value) {
          return;
        }

        if (optionalValuesRequired) {
          if (Array.isArray(value)) {
            feedbackObj[variable] = value.map(itr => optionalValues?.[itr] ?? itr);
          } else {
            feedbackObj[variable] = optionalValues?.[value] ?? value;
          }
        } else {
          feedbackObj[variable] = value;
        }
      });

      return feedbackObj;
    },
    dataHelper(prop) {
      let dummy;
      try {
        dummy = JSON.parse(store.getData(prop))
      } catch(err) {
        console.log("Error", err);
        dummy = {};
      }
      return dummy;
    },
    processInflowData(instructionNumber){
      let index = instructionNumber === 0 ? instructionNumber : instructionNumber - 1;
      let inFlowSummary = {};
      let instruction = this.instructions[index];
      let Adminfields = this.$store.getters.userDetails.fields;
      if (Adminfields && Adminfields.length != 0) {
        let jsonData = this.$store.getters.userDetails.fields;
        jsonData.forEach((item) => {
          this.dynamicFormVariables[item.variable] = "";
          this.$set(
            this.conditionalRender,
            item.variable,
            !item.conditionalRendering
          );
        });
        this.forms = jsonData;
      }
      // getting apt facematch for corresponding screenshot
      let dumVarFmS = null;
      let dumVarFmDs = null;
      if (this.screenshots[index] && this.screenshots[index].finalImage && instruction.isFaceMatchRequired) {
        let x = this.screenshots[index].screenshots.indexOf(this.screenshots[index].finalImage);
        //dumVarFmS =  this.screenshots[index].faceMatchScore ? this.screenshots[index].faceMatchScore[x] ? this.screenshots[index].faceMatchScore[x] : "" : "";
        dumVarFmDs = this.screenshots[index].facematchDesc ? this.screenshots[index].facematchDesc[x] ? this.screenshots[index].facematchDesc[x] : "" : "";
        dumVarFmS = 0;
        if(dumVarFmDs && dumVarFmDs.length>0){
          dumVarFmDs.forEach((ele) => {
            //fms is a number and not a string here
            if(ele["facematchScore"] &&
              ele["facematchScore"] > dumVarFmS
              ){
              dumVarFmS = ele["facematchScore"];
            }
          })
        }
        if(dumVarFmS == 0 && x > 0 && this.screenshots[index].facematchDesc && this.screenshots[index].facematchDesc.length == 1){ //facematch desc not filled error temp solution, screenshot has two image but facematchdesc has only one
            x = 0;
            dumVarFmDs = this.screenshots[index].facematchDesc ? this.screenshots[index].facematchDesc[x] ? this.screenshots[index].facematchDesc[x] : "" : "";
            dumVarFmS = 0;
            if(dumVarFmDs && dumVarFmDs.length>0){
              dumVarFmDs.forEach((ele) => {
                //fms is a number and not a string here
                if(ele["facematchScore"] &&
                  ele["facematchScore"] > dumVarFmS
                  ){
                  dumVarFmS = ele["facematchScore"];
                }
              })
          }
        }
      }
      //setting up the inFlowSummary
      inFlowSummary= {
        imageTaken: instruction.isScreenshotRequired
          ? this.screenshots[index].finalImage
          : "",
        facematchScore: instruction.isFaceMatchRequired
          ? dumVarFmS + ""
          : "",
        facematchDesc: instruction.isFaceMatchRequired
          ? dumVarFmDs
          : {},
        question: instruction.text,
        feedback: this.generateFeedback(this.feedback[index], instruction)
      };

      return inFlowSummary;
    },

    async unloadEvent() {
      for (let i = 1; i <= this.instructions.length; i++) {
        let inflow = this.processInflowData(i);
        this.stepWiseFlowSummary.set(i, inflow);
      }
      let flowSummaryWrapper = []
      for (const value of this.stepWiseFlowSummary.values()) {
        flowSummaryWrapper.push(value)
      }
      let finalDataObj = { inFlowSummary: flowSummaryWrapper, screenshots: this.screenshots, agentFeedbackData: this.agentFeedbackData, abruptCancel: true }
      await this.submitCallSessionData(finalDataObj)
    },
    
    async finish(isHangUp, errStr) {
      // this.hangUpCall = true;
      this.submitInProgress = true;
      for(let i=1; i<= this.instructions.length; i++){
        let inflow = this.processInflowData(i);
        this.stepWiseFlowSummary.set(i,inflow);
      }
      let flowSummaryWrapper = []
      for (const value of this.stepWiseFlowSummary.values()) {
        flowSummaryWrapper.push(value)
      }
      let finalDataObj = { inFlowSummary: flowSummaryWrapper, screenshots: this.screenshots, agentFeedbackData: this.agentFeedbackData, abruptCancel: isHangUp || false }
      await this.submitCallSessionData(finalDataObj)

      if (this.isVideoConference) {
        isHangUp = false;
      }
      let dataToPush = this.$store.getters.userDetails.users[0];
      this.callDropInitiated = true;
      const inFlowSummary = [];
      if (!isHangUp) {
        let screenshotCount = 0,
          instructionCount = 0;
        if (this.instructions) {
          this.instructions.forEach(async (instruction, index) => {
            if (instruction.isScreenshotRequired) {
              instructionCount += 1;
            }

              let Adminfields = this.$store.getters.userDetails.fields;
              if (Adminfields && Adminfields.length != 0) {
                let jsonData = this.$store.getters.userDetails.fields;
                jsonData.forEach((item) => {
                  this.dynamicFormVariables[item.variable] = "";
                  this.$set(
                    this.conditionalRender,
                    item.variable,
                    !item.conditionalRendering
                  );
                });
                this.forms = jsonData;
              }
            // getting apt facematch for corresponding screenshot
            let dumVarFmS = null;
            let dumVarFmDs = null;
            if (this.screenshots[index] && this.screenshots[index].finalImage && instruction.isFaceMatchRequired) {
              let x = this.screenshots[index].screenshots.indexOf(this.screenshots[index].finalImage);
              //dumVarFmS =  this.screenshots[index].faceMatchScore ? this.screenshots[index].faceMatchScore[x] ? this.screenshots[index].faceMatchScore[x] : "" : "";
              dumVarFmDs = this.screenshots[index].facematchDesc ? this.screenshots[index].facematchDesc[x] ? this.screenshots[index].facematchDesc[x] : "" : "";
              dumVarFmS = 0;
              if(dumVarFmDs && dumVarFmDs.length>0){
                dumVarFmDs.forEach((ele) => {
                  //fms is a number and not a string here
                  if(ele["facematchScore"] &&
                    ele["facematchScore"] > dumVarFmS
                    ){
                    dumVarFmS = ele["facematchScore"];
                  }
                })
              }
              if(dumVarFmS == 0 && x > 0 && this.screenshots[index].facematchDesc && this.screenshots[index].facematchDesc.length == 1){ //facematch desc not filled error temp solution, screenshot has two image but facematchdesc has only one
                  x = 0;
                  dumVarFmDs = this.screenshots[index].facematchDesc ? this.screenshots[index].facematchDesc[x] ? this.screenshots[index].facematchDesc[x] : "" : "";
                  dumVarFmS = 0;
                  if(dumVarFmDs && dumVarFmDs.length>0){
                    dumVarFmDs.forEach((ele) => {
                      //fms is a number and not a string here
                      if(ele["facematchScore"] &&
                        ele["facematchScore"] > dumVarFmS
                        ){
                        dumVarFmS = ele["facematchScore"];
                      }
                    })
              }
            }
            }
           //setting up the inFlowSummary
            inFlowSummary.push({
              imageTaken: instruction.isScreenshotRequired
                ? this.screenshots[index].finalImage
                : "",
              facematchScore: instruction.isFaceMatchRequired
                ? dumVarFmS + ""
                : "",
              facematchDesc: instruction.isFaceMatchRequired
                ? dumVarFmDs
                : {},
              question: instruction.text,
              feedback: this.generateFeedback(this.feedback[index], instruction)
            });
          });

          this.screenshots.forEach((screenshot) => {
            if (screenshot.finalImage) {
              screenshotCount += 1;
            }
          });

          if (screenshotCount != instructionCount) {
            eventBus.$emit("vueSnack", "Must capture/select all the images!");
            return;
          }
        }
        console.log("agent feedback to be shown here");
        if (this.agentFeedbackData && !this.agentFeedbackData.answer){
          this.agentFinalFeedbackPicker = true;
          return;
        }
      }
      this.abruptCancel = isHangUp || false;
      try {
        dataToPush["inFlowSummary"] = inFlowSummary;
        dataToPush["screenshots"] = this.screenshots;
        // dataToPush["geo"] = this.dataHelper("geo");
        // dataToPush["browserData"] =  this.dataHelper("browser");
        // dataToPush["vcipAdminDetails"] = this.$store.getters.agentData;
        // dataToPush["forensicsData"] = this.dataHelper("forensicsData");
        dataToPush["callDuration"] = {
          startTime: parseInt(store.getData("startTime") || Date.now()),
          endTime: Date.now(),
        };
        if (this.isFrontendDebugLogs) {
          sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
          dataToPush: dataToPush, checkFor: "inFlow Summary" }})
        }
        try{

          console.log("here updating last report element");
          let initiationId = sessionStorage.getItem('initiationId');
          let data = {};
          data.callDuration = dataToPush.callDuration;
          data.vcipAdminDetails = dataToPush.vcipAdminDetails;
          data.inFlowSummary = dataToPush.inFlowSummary;
          let { customerData, userId } = this.$store.getters.userData;
          if(isHangUp){
            if(!sessionStorage.getItem("RE_CALL_LOGGING_DONE")){

              this.socket.emit("activityChecker", {customerData, userId, activity: "callCompletedUnsuccessfully", loggerId: localStorage.getItem("loggerId")});

              // this.socket.emit("activityChecker", {...this.$store.getters.userData, activity: "callCompletedUnsuccessfully", loggerId: localStorage.getItem("loggerId")});
              sessionStorage.setItem("RE_CALL_LOGGING_DONE", true);

              this.isCallStatusSent = true;
              data.status = this.logObject.rgbCheckFailed.status;
              if(errStr === 'rgbCheckFailed') {
                data.subStatus = this.logObject.rgbCheckFailed.subStatus; 
              } else if(errStr === 'networkTooSlow') {
                data.subStatus = this.logObject.networkTooSlow.subStatus;
              } else {
                data.subStatus = this.logObject.manualDisconnect.subStatus;
              }
              data.currentProcess = "Re hangup function called due to "+ errStr ? errStr : "REASON NOT MENTIONED";
            }
          }
          else{
            if(!sessionStorage.getItem("RE_CALL_LOGGING_DONE")){

              this.socket.emit("activityChecker", {customerData, userId, activity: "callCompletedSuccessfully", loggerId: localStorage.getItem("loggerId")});

              // this.socket.emit("activityChecker", {...this.$store.getters.userData, activity: "callCompletedSuccessfully", loggerId: localStorage.getItem("loggerId")});
              sessionStorage.setItem("RE_CALL_LOGGING_DONE", true);

              this.isCallStatusSent = true;
              data.status = this.logObject.processingData.status;
              data.subStatus = this.logObject.processingData.subStatus;
              if(this.isRecording){
                data.currentProcess = `RE finish function called, succesfully sent ${this.successfullySentChunks} chunks out of ${this.sequenceId} chunks`
              } else {
                data.currentProcess = "RE finish function called with is hangup false"
              }
            }
          }
          data.callId = this.requestId ? this.requestId : "";
          logReportData(this.socket,initiationId,data);
        }
        catch(err){
          console.log(err);
        }
      } catch (error) {
        if (this.isFrontendDebugLogs) {
          sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId || "",
          dataToPush: dataToPush || {}, checkFor: "inFlow Summary fallback default values", error: error }})
        }
        try {
          let initiationId = sessionStorage.getItem('initiationId');
          let formData = {};
          formData.errorBlock = error;
          formData.currentProcess = `Error in collecting call data, using default values for geo, screenshots,browserData`;
          logReportData(this.socket,initiationId,formData);
        }
        catch(err) {
          console.log(err);
        }

        console.log("Error in populationg dataToPush, using fallback pre defined values", error);
        dataToPush["inFlowSummary"] = inFlowSummary;
        dataToPush["screenshots"] = [];
        dataToPush["geo"] = {};
        dataToPush["browserData"] = {};
        dataToPush["forensicsData"] = {
          status: "failed",
        };
        dataToPush["vcipAdminDetails"] = this.$store.getters.agentData;
        dataToPush["callDuration"] = {
          startTime: parseInt(store.getData("startTime") || Date.now()),
          endTime: Date.now(),
        };
        if (this.isFrontendDebugLogs) {
          sms.emit("Error Logger##" , {VKYClogger: {scope:this.FrontDebugLabel,timeStamp: Date.now(), for: this.sessionId,
          dataToPush: dataToPush, checkFor: "inFlow Summary Catch" }})
        }
        try{
            let initiationId = sessionStorage.getItem('initiationId');
            let data = {};
            data.callDuration = dataToPush.callDuration;
            data.vcipAdminDetails = dataToPush.vcipAdminDetails;
            data.inFlowSummary = dataToPush.inFlowSummary;
            if(isHangUp){
              data.status = "Unsuccessful";
              data.subStatus = errStr ? `error while ending call at Banker end ${errSt}` : "Call ended with error";
              data.currentProcess = "catch block of finish function RE side "+error;
              data.callId = this.requestId ? this.requestId : "";
            }
            data.callId = this.requestId ? this.requestId : "";
             logReportData(this.socket,initiationId,data);

          }
          catch(err){
            console.log(err);
          }
      }

      //Adding faceMatchScore in the MISData
      dataToPush ["faceMatchScore"] ={
        faceMatchScore:  getFaceMatchScoreFromInflow(inFlowSummary)
      }
       dataToPush ["faceMatchScoreDetails"] ={
        faceMatchScoreDetails:  getFaceMatchScoreFromInflow(inFlowSummary,"desc")
      }

      try {
        this.$store.commit("setDataToSave", {
          user: dataToPush,
          sessionId: this.sessionId,
          email: this.sessionObj.users[0].email,
        });
        if (errStr) {
          switch (errStr) {
            // route to end user error page
            // this.initiateDropOut("streamNotVisible");
            case "rgbCheckFailed" :
              sms.emit("streamNotVisibleDropOff");
              break;
            case "heartbeatFailed" :
              sms.emit("heartbeatFailedDropOff");
              break;
            case "networkTooSlow" :
              sms.emit("networkSpeedSlowDropOff");
              break;
            default :
              console.log(errStr);
              break;
          }
        }
        this.hangUp();
      } catch (error) {
        console.log(error);
        eventBus.$emit("vueSnack", "Something went wrong!");
      }
    },
    //function to submit call session data
    async submitCallSessionData(dataObj) {
      try{
        await axiosInstance.post(end_points.updateCallSessionData(this.sessionId), dataObj);
      }catch(error){
        console.log(error)
      }
    },
    previewImage(image, ins, id, index, jIndex) {
      this.viewImageDetails.imageSrc = image;
      this.viewImageDetails.instruction = ins;
      this.viewImageDetails.id = id;
      this.viewImageDetails.index = index
      this.viewImageDetails.jIndex = jIndex;
      this.viewImage = true;
    },
    selectImageFromView() {
      document.getElementById(this.viewImageDetails.id).checked = true;
      this.screenshots[this.viewImageDetails.index].finalImage = this.screenshots[this.viewImageDetails.index].screenshots[this.viewImageDetails.jIndex];
      this.viewImage = false;
      this.viewImageDetails.imageSrc = '';
      this.viewImageDetails.instruction = '';
      this.viewImageDetails.id = '';
      this.viewImageDetails.jIndex = '';
    },
    padValue(value) {
      return value < 10 ? "0" + value : value;
    },
    switchView(num) {
      if (this.tab == 1) {
        this.changeDisplay();
      }
    },
    async changeDisplay() {
      let dataToFeed = this.proofData[
        this.currentProof == "N/A" ? undefined : this.currentProof
      ];
      if (dataToFeed) {
        let imgSrc;
        if (dataToFeed["image"]) {
          if(dataToFeed["image"].indexOf('base64,') != -1) {
            imgSrc = dataToFeed["image"];
          } else {
            let imageDataResp = await downloadFile(dataToFeed["image"]);
            if (imageDataResp.status === 200) {
              imgSrc = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`;
            }
          }
        }
        this.imageLink = dataToFeed["image"] ? imgSrc : "";
        this.imageArray = dataToFeed["images"] ? dataToFeed["images"] : [];
        this.documents = dataToFeed["data"];
        this.docPos = 0;
        if (this.imageArray && this.imageArray.length != 0) {
          this.imageSource = this.imageArray[0];
          this.loadDocumentWrap();
        }
      }
    },
    locationRetry(){
      console.log("Location Retry before begin VKYC")
      this.locationFail = false;
      sms.emit("retryLocation");
      this.waitForLocation(15000);
    },
    getAverageRGB() {
      if (!this.stopChecks) {
        try {
          let drawFrame = document.getElementById("vid2");

          // only visiting every 5 pixels
            let blockSize = 5,
                defaultRGB = {r:0,g:0,b:0}, // for non-supporting envs
                canvas = document.createElement('canvas'),
                context = canvas.getContext && canvas.getContext('2d'),
                data, width, height,
                i = -4,
                length,
                rgb = {r:0,g:0,b:0},
                count = 0;

            if (!context) {
                console.log("Unable to process canvas")
            } else {

              height = canvas.height = drawFrame.naturalHeight || drawFrame.offsetHeight || drawFrame.height;
              width = canvas.width = drawFrame.naturalWidth || drawFrame.offsetWidth || drawFrame.width;

              context.drawImage(drawFrame, 0, 0);
              if(width !== 0 && height !== 0){
                console.log("got canvas size non zero")
                data = context.getImageData(0, 0, width, height);
              }
              else{
                console.log("canvas size zero returning rgb check")
                return
              }


              length = data.data.length;
              // console.log(length)
              // console.log(rgb)

              while ( (i += blockSize * 4) < length ) {
                  ++count;
                  rgb.r += data.data[i];
                  rgb.g += data.data[i+1];
                  rgb.b += data.data[i+2];
              }

              // Flooring values
              rgb.r = ~~(rgb.r/count);
              rgb.g = ~~(rgb.g/count);
              rgb.b = ~~(rgb.b/count);
              // console.log(rgb);
              if (rgb.r === 0 && rgb.g === 0 && rgb.b === 0) {
                console.log("got a black screen");
                this.videoErrorMessage = "Please wait while we try to access end user's camera feed / microphone.";
                if (this.rgbCheckFailed) {
                  this.showDropPrompt = true;
                  sms.emit("streamNotVisible");
                } else {
                  this.rgbCheckFailed = true;
                }
              } else {
                this.rgbCheckFailed = false;
                this.showDropPrompt = false;
                sms.emit("streamIsVisible");
              }
            }
        } catch (err) {
          // The only scenario where this may fail is when vid2 element has no source
          // or some issues with canvas processing
          // this will happen only if there was some trouble in receving video stream from end user
          // hence, considering this as a failure in stream detection
          console.log("Error in black stream detection:::::", err);
          this.videoErrorMessage = "Please wait while we try to access end user's camera feed / microphone.";
          if (this.rgbCheckFailed) {
            this.showDropPrompt = true;
            sms.emit("streamNotVisible");
          } else {
            this.rgbCheckFailed = true;
          }
        }
      } else {
        console.log("Checks have been stopped");
      }

      },
    getDateTimeString() {
      let newDate = new Date();
      let sMonth = this.padValue(newDate.getMonth() + 1);
      let sDay = this.padValue(newDate.getDate());
      let sYear = newDate.getFullYear();
      let sHour = newDate.getHours();
      let sMinute = this.padValue(newDate.getMinutes());
      let sSecond = this.padValue(newDate.getSeconds());
      let sAMPM = "AM";
      let iHourCheck = parseInt(sHour);
      if (iHourCheck > 12) {
        sAMPM = "PM";
        sHour = iHourCheck - 12;
      } else if (iHourCheck === 0) {
        sHour = "12";
      }
      sHour = this.padValue(sHour);
      return (
        sMonth +
        "-" +
        sDay +
        "-" +
        sYear +
        " " +
        sHour +
        ":" +
        sMinute +
        ":" +
        sSecond +
        " " +
        sAMPM
      );
    },
    sleep(delay) {
      return new Promise((resolve) => {
        setTimeout(resolve, delay);
      });
    },
    initiateDropOut(reason) {
      try {
        window.clearInterval(this.pingPongGenerator);
        window.clearInterval(this.pixelDetectInterval);
        window.clearInterval(this.REInternetSpeedChecker);
      } catch (err) {
        console.log("something went wrong in clearing interval");
      }
      this.rgbCheckFailed = false;
      this.heartbeatFailed = false;
      this.speedThresholdFailed = false;
      this.allowPixelCheck = false;
      this.presenterLeft = true;
      this.stopChecks = true;
      this.videoErrorMessage = 'End user might have closed the window.';
    },
    getUpperCaseLabel(label){
      if(label === "skillTag"){
        return this.getSkillLabel();
      }
      return label.toUpperCase();
    },
    getSkillLabel(){
      let renameSkillLabel = ""
      if(this.$store.getters.userData?.customerData?.config?.introConf?.renameSkillLabelRequired) {
        renameSkillLabel = this.$store.getters.userData?.customerData?.config?.introConf?.renameSkillLabel ?? "";
      }
      return renameSkillLabel?.toUpperCase() || 'SKILLTAG';
    },
  },

  beforeDestroy() {

    if (this.REInternetSpeedChecker) {
      clearInterval(this.REInternetSpeedChecker);
    }
    this.REThresholdFailed = false;
    eventBus.$emit("unFreezeAll", "");
    if (this.pingPongTimer) {
        window.clearTimeout(this.pingPongTimer);
    }
    if (this.pingPongGenerator) {
      window.clearInterval(this.pingPongGenerator)
    }
    if (!this.callDropInitiated) {
      window.location.reload();
    }
    if(this.EUInternetSpeedChecker) {
      clearInterval(this.EUInternetSpeedChecker);
    }
  },
  mounted() {
    // calculateSpeed('RE', (res) => {
    //   try {
    //     if (res && res.status && res.status === "failed") {
    //       console.log("Internet speed failed");
    //       try {
    //         let initiationId = sessionStorage.getItem('initiationId');
    //         let formData = {};
    //         formData.reInternetSpeed = {speedMbps : 0};
    //         formData.currentProcess = "RE internet speed capture failed";
    //         formData.callId = this.requestId ? this.requestId : "";
    //         logReportData(this.socket,initiationId,formData);
    //       }
    //       catch(err) {
    //         console.log(err);
    //       }
    //       this.ownInternetSpeed = res;
    //       this.reSpeedFailed = true;
    //       this.reSpeedLoading = false;
    //     } else {
    //       //RE threshold set to 200kbps
    //       try {
    //         let initiationId = sessionStorage.getItem('initiationId');
    //         let formData = {};
    //         formData.reInternetSpeed = res;
    //         formData.currentProcess = "RE internet speed is logged";
    //         formData.callId = this.requestId ? this.requestId : "";
    //         logReportData(this.socket,initiationId,formData);
    //       }
    //       catch(err) {
    //         console.log(err);
    //       }
    //       this.ownInternetSpeed = res;
    //       this.changeStyle(res, 'RE');
    //       this.reSpeedLoading = (res && res.Download) ? false : true;
    //       if (res && res.speedKbps && parseInt(res.speedKbps) < 200 ) {
    //         this.REThresholdFailed = true;
    //         this.videoErrorMessage = "Your network is too slow. Please wait while we try to reconnect you.";
    //       }
    //       else {
    //         this.REThresholdFailed = false;
    //       }
    //     }
    //   } catch(err) {
    //       console.log("Something went wrong", err);
    //       this.REThresholdFailed = false;
    //   }
    // });
    this.$tours['reJoinTour'].start();
    if(this.allowRejoin) {
      this.$tours['reJoinTour'].start();
      setTimeout(() => {
        this.$tours['reJoinTour'].stop();
      },60000);
    }
    // gets the text of the element that is clicked
    document.addEventListener('click', function(e) {
      try {
        e = e || window.event;
        var target = e.target || e.srcElement,
        text = target.textContent || target.innerText;
        this.currentClickedElement = text;
      } catch(err) {
        console.error("Some error while fetching clicked element");
      }
    }, false);

    //This is to stop RE from spamming rejoin and causing jitsi stream issues
    this.guardRejoinButton();
  },
  async created() {
    this.agentData = this.$store.getters.agentData;
    window.addEventListener("popstate", (e) => {
      this.goToDashboard();
    });
    this.keepREOnline = this.$store.getters.userData.customerData.config?.global?.keepREOnline ? true : false;
    this.client = this.$store.getters.envValues?.client ? this.$store.getters.envValues?.client : false;
    this.videoCallDetailsHeader = this.$store.getters.userData.customerData.config.introConf && this.$store.getters.userData.customerData.config.introConf.videoCallDetailsHeader ? this.$store.getters.userData.customerData.config.introConf.videoCallDetailsHeader : 'KYC Data';

    if(this.$store.getters.userData.customerData.config?.introConf?.startButtonTextRequired){
      this.startButtonText = this.$store.getters.userData.customerData.config?.introConf?.startButtonText || "Begin Video KYC"; 
    }

    //precache host
    if(sessionStorage.host){
      this.host = sessionStorage.host;
    }
    if (!this.$store.getters.initiated) {
      //window.open(`${this.host}dashboard`, "_self");
      this.goToDashboard();
    } else {
      try {
        this.isVideoConference = this.$route.path.includes(
          "conference/initiator"
        );
        window.numOfVideos = 0;

        // setting the call configuration as per the flow here
        if (this.$route.params.sessionId) {
          this.sessionId = this.$route.params.sessionId;
          this.sessionObj = await getSession(this.sessionId);
          let userData = this.$store.getters.userData;
          if(this.sessionObj && userData && userData.customerData && userData.customerData.config) {
            userData.customerData.config.introConf = this.sessionObj.configuration;
            this.$store.commit("setUserData", userData);
          }
          this.agentFeedbackData = this.sessionObj.users[0].agentFeedback || null;
        }
        let customerData = this.$store.getters.userData.customerData;
        if (
          customerData.config &&
          customerData.config.introConf &&
          customerData.config.introConf.isScreenshare
        ) {
          this.isScreenshare = customerData.config.introConf.isScreenshare;
        } else {
          this.isScreenshare = false;
        }

        //get global configs
        let globalConfigs = await axiosInstance.get(end_points.get_global_configs(customerData.id));
        await this.setGlobalConfigurations(globalConfigs?.data);

        this.isRecording = customerData.config.introConf.isRecording;
        this.isFrontendDebugLogs = customerData.config.introConf.isFrontendDebugLogs;
        if (customerData.config.global.customREDomain) {
          this.host =
            customerData.config.global.customREDomain +
            (customerData.config.global.customREPath || "");
          this.vbind = customerData.config.global.customREDomain;
        }
        sessionStorage.host = this.host;
        this.useStunTurn = customerData.config.introConf.useStunTurn || false;
        this.persistHost = customerData.config?.global?.persistHost || this.$store.getters.envValues?.persistBase64;
        if (customerData.maxRecordRequired) {
          this.maxRecording = customerData.maxRecordingTime || 3; // taking 3 mins if max value is missing
        }
        this.stopRecordingTime = convertToRinger(this.maxRecording * 60);
        this.displayName = customerData.config.introConf.displayName || "";
        if(customerData &&
           customerData.config &&
           customerData.config.introConf
        ) {
          this.allowRejoin = customerData.config.introConf.allowRejoin;
          this.disableRejoinInCall = customerData.config.introConf.disableRejoinInCall;
          this.disableJitsiLogs = customerData.config.introConf.disableJitsiLogs === true ? true : false;
          this.enableRecordingPreview = customerData.config.introConf.enableRecordingPreview;

          //admin form headers
          this.adminFormHeaderText = customerData.config.introConf.adminFeedbackFormHeaderText || 'CLASSIFY THE USER APPLICATION BASED ON THE BELOW CRITERIA';
          this.adminFormSubHeaderText = customerData.config.introConf.adminFeedbackFormSubHeaderText || 'Kindly select the application status and leave your comments based on the interaction with user';
        }

        //setting chat configuration
        if(customerData &&
           customerData.config &&
           customerData.config.introConf &&
           customerData.config.introConf.enableChat
        ) {
          this.enableChat = true;
          this.colors.message.others.bg = customerData.config.introConf.endUserMessageBgColor || '#fb4141';
          this.colors.message.myself.bg = customerData.config.introConf.bankerMessageBgColor || '#fb4141';
          this.colors.message.others.text = customerData.config.introConf.endUserMessageTextColor || '#fff';
          this.colors.message.myself.text = customerData.config.introConf.bankerMessageTextColor || '#fff';
        }

        eventBus.$emit("freezeAll", "");
        let selfIns = this;
        eventBus.$on("multiSession", (data) => {
          this.multiSessionDialog = true;
          this.isMultipleSessions = true;
        });
        eventBus.$on("clearMultiSession", (data) => {
          this.multiSessionDialog = false;
          this.isMultipleSessions = false;
        });
        eventBus.$on("screenSharingActive", (data) => {
          this.isScreenshareActive = true;
          this.screenShareText = "Stop sharing";
        });
        eventBus.$on("screenSharingInactive", (data) => {
          this.isScreenshareActive = false;
          this.screenShareText = "Screen Share";
        });
        //sessionId, requestId
        if (this.$route.params.sessionId && this.$route.params.requestId) {
          this.sessionId = this.$route.params.sessionId;
          this.requestId = this.$route.params.requestId;
          sms = new SignzyMessagingService(this.sessionId);
          //await fetchSession(this.sessionId);
          //Jistsi Meet start heres
          //conferenceName, userInformation, htmlElement, conferenceOptions
          console.log("lolp", this.sessionId.toLowerCase());
          this.sessionObj = !this.sessionObj ? await getSession(this.sessionId) : this.sessionObj;

          if (this.sessionObj && this.sessionObj.users) {
            this.name = this.sessionObj.users[0].name;
            this.additionalData = this.sessionObj.users[0].additionalData;
            this.isRecording = this.sessionObj.configuration.isRecording || false;
            this.speedThreshold = this.sessionObj.configuration.minSpeedRequired || 200;
            this.videoCallDetailsHeader = this.sessionObj.configuration && this.sessionObj.configuration.videoCallDetailsHeader ? this.sessionObj.configuration.videoCallDetailsHeader : 'KYC Data';
            this.consentData["consentRequired"] = this.sessionObj.configuration && this.sessionObj.configuration.consentRequired ? this.sessionObj.configuration.consentRequired : false;
            this.consentData["consentText"] = this.sessionObj.configuration && this.sessionObj.configuration.consentText ? this.sessionObj.configuration.consentText : "";
            this.consentData["consentTimestamp"] = (this.sessionObj.configuration && this.sessionObj.configuration.consentRequired && this.sessionObj.configuration.consentRequired == true) ? this.consentData["consentTimestamp"] : "";
          }
          if(!this.enableChime) {
            signzyStreamService = new SignzyVideoService({
              domain: this.virtualHost || this.$store.getters.envValues?.vhttpBind,
              vbind: this.virtualHost || this.$store.getters.envValues?.vhttpBind,
              conferenceName: this.sessionId.toLowerCase(),
              userInformation: {
                email: this.agentData.email,
                displayName: this.agentData.name+Math.floor(Math.random() * 100),
              },
              useStunTurn: this.useStunTurn,
              htmlElement: "audioTracks",
              directLocal: "reflection",
              directRemote: "vid2",
              // remoteUserCallback: this.startRecording, //Not used anywhere
              unMuteCb: this.restartRecording,
              isAdmin: true,
              remoteVideoMuteCB: this.muteRemoteVideo,
              videoQuality: this.sessionObj.configuration.videoQuality,
              jitsiLogLevel:this.sessionObj.configuration.jitsiLogLevel,
              jitsiEvents: this.jitsiEvents,
              disableJitsiLogs: this.disableJitsiLogs
            });
          } else {
            signzyStreamService = new SignzyChimeVideoService(
              this.sessionId.toLowerCase(),
              chime_end_points.initiate_meeting(this.sessionId.toLowerCase().substr(1,64)),
              // `http://localhost:4000/api/meetings/${}`,
              customerData.name,
              customerData.email,
              document.getElementById('reflection'),
              document.getElementById('vid2'),
              undefined,
              document.getElementById('audioTracks'),
              this.restartRecording
            );
            const currentAttendee = await signzyStreamService.initialise();

            const videoDevices = await signzyStreamService.getVideoInputDevices();
            const speakers = await signzyStreamService.getAvailableSpeakers();
            const microphones = await signzyStreamService.getAvailableMicrophones();


            if (videoDevices && videoDevices.length) {
              console.log("video devices:", videoDevices);
              this.videoDevices = videoDevices;
              const firstVideoDeviceId = videoDevices[0].deviceId;
              this.selectedVideoDevice = videoDevices[0];
              await signzyStreamService.setVideoInputDevice(firstVideoDeviceId);
            }
            if (microphones && microphones.length) {
              console.log("microphone devices:", microphones);
              this.microphones = microphones;
              const firstMicrophone = microphones[0].deviceId;
              this.selectedMicrophone = microphones[0];
              await signzyStreamService.setMicrophone(firstMicrophone);
            }
            if (speakers && speakers.length) {
              console.log("speaker devices:", speakers);
              this.speakers = speakers;
              const firstSpeaker = speakers[0].deviceId;
              this.selectedSpeaker = speakers[0];
              await signzyStreamService.setSpeaker(firstSpeaker);
            } else {
              await signzyStreamService.setSpeaker();
            }
            const observers = signzyStreamService.createMeetingObservers(currentAttendee);
            signzyStreamService.start(observers);
          }

          if (this.sessionObj.isCompleted == 0) {
            this.instructions = this.sessionObj["users"][0]["instructions"];
            let dataToParse = await parseData(this, this.sessionId);
            this.sessionData = dataToParse;
            if (this.instructions) {
              for (var i = 0; i < this.instructions.length; i++) {
                this.screenshots.push({ finalImage: "", screenshots: [] });
                this.dataScreenshots.push({screenshots: []});
              }


              const docData = await generateDocuments(
                dataToParse["users"][0].documents
              );
              this.proofData = docData.proofData;
              this.proofs = docData.proofs;
              this.customDocuments = docData.customDocuments;
              this.possibleFacematchDocuments(this.proofData);
              this.currentProof = this.proofs[0] || "N/A";
              this.changeDisplay();
              this.languages = this.sessionObj.languagesRequired || [];
              this.languages.push("ENGLISH");

              let finalArray = [];
              for (let i = 0; i < this.instructions.length; i++ ) {
                if (this.instructions[i].feedbackFields) {
                  this.instructions[i].feedbackFields.forEach(async (field, index) => {
                    this.isLegacySetting = false;
                      if(field.elementType === "dropdown" || field.elementType === "multiSelectDropdown") {
                        if(typeof field.options != 'object') {
                        try {
                          const options = await axiosInstance.get(base_url + end_points.dropDownFields+field.options);
                          field.options = options.data.data;
                        } catch (error) {
                          console.log("Error occured while fetching dropdown :::::", error);
                          eventBus.$emit("vueSnack", "Something went wrong, ask user to rejoin !");
                        }
                      } else {
                        this.isLegacySetting = true;
                      }
                      field.options.forEach(option => {
                        let newObj = {};
                        if(typeof option == 'string'){
                          newObj['text'] = option;
                        }else{
                          newObj['text'] = option.value
                        }
                        // let newObj = { text: option };
                        if((field.optionalValuesRequired && !field.optionalValues) || !field.optionalValuesRequired) {
                          if(typeof option == 'string'){
                            newObj[`value_${option}`] = option;
                          }else{
                            newObj[`value_${option}`] = option.value
                          }
                          // newObj["value"] = option;
                        }
                        else {
                          if(typeof option == 'string'){
                            if(Object.keys(field.optionalValues).includes(option)) {
                              newObj[`value_${option}`] = field.optionalValues[option];
                            }
                            else {
                              newObj[`value_${option}`] = option
                            }
                          }else{
                             if(Object.keys(field.optionalValues).includes(option.value)) {
                              newObj[`value_${option}`] = field.optionalValues[option.value];
                            }
                            else {
                              newObj[`value_${option}`] = option.value
                            }
                          }

                        }
                        finalArray.push(newObj);
                      });

                      // Created an alternative logic for the above code, keeping it commented for future reference
                      // if((field.optionalValuesRequired && !field.optionalValues) || !field.optionalValuesRequired){
                      //   field.options.forEach(option =>{
                      //     let newObj= {}
                      //     newObj['text'] = option
                      //     newObj[`value_${option}`] = option
                      //     finalArray.push(newObj)
                      //   })

                      // } else{
                      //   field.options.forEach(option =>{
                      //     let newObj= {}
                      //     newObj['text'] = option
                      //     newObj[`value_${option}`] = field.optionalValues.hasOwnProperty(option) ? field.optionalValues[option] : option
                      //     finalArray.push(newObj)
                      //   })
                      // }

                      if(this.isLegacySetting) {
                        this.instructions[i].feedbackFields[index].options = finalArray;
                        finalArray = [];
                       } else {
                         this.instructions[i].feedbackFields[index].options = this.instructions[i].feedbackFields[index].optionsValue;
                         this.dropDownOptions[this.instructions[i].feedbackFields[index].optionsValue] = finalArray;
                         finalArray = [];
                       }
                     }
                  });
                }
              }

              let AdminfinalArray = [];
              let fields = this.$store.getters.userDetails.fields;
              if (fields && fields.length != 0) {
                let jsonData = this.$store.getters.userDetails.fields;
                console.log(jsonData);
                jsonData.forEach((item) => {
                  this.dynamicFormVariables[item.variable] = "";
                  this.$set(
                    this.conditionalRender,
                    item.variable,
                    !item.conditionalRendering
                  );
                });
                this.forms = jsonData;
                for (let i = 0; i < this.forms.length; i++ ) {
                   this.isLegacySetting = false;
                   if (this.forms[i].elementType === "dropdown" || this.forms[i].elementType === "multiSelectDropdown") {
                      if (typeof this.forms[i].options !== 'object') {
                        let dummyArr = [];
                        if (typeof(this.forms[i].options) === "string" ) {
                          dummyArr.push(this.forms[i].options);
                          this.forms[i].options = dummyArr;
                        }
                        try {
                            const options = await axiosInstance.get(base_url + end_points.dropDownFields+ this.forms[i].options[0] );
                            this.forms[i].options = options.data.data;
                          } catch (error) {
                            console.log("Error occured while fetching dropdown ::", error);
                            eventBus.$emit("vueSnack", "Something went wrong, ask user to rejoin !");
                          }
                        } else {
                          this.isLegacySetting = true;
                        }
                        this.forms[i].options.forEach(option => {
                          if(typeof(option) == 'object'){
                            let valuedObject = this.checkStringValInObject(option);
                            let newObj = {};
                            if(valuedObject.text){
                              newObj['text'] = valuedObject.text;
                            }else{
                              newObj['text'] = valuedObject.value;

                            }
                            if(!this.forms[i].optionalValuesRequired || (this.forms[i].optionalValuesRequired && !this.forms[i].optionalValues)) {
                              newObj["value"] = valuedObject.value;
                            }
                            else {
                              if(Object.keys(this.forms[i].optionalValues).includes(valuedObject['text']) && this.forms[i].optionalValues[valuedObject['value']].trim().length > 0) {
                                newObj["value"] = this.forms[i].optionalValues[valuedObject['value']];
                              }
                              else {
                                newObj["value"] = valuedObject['value']
                              }
                            }
                            AdminfinalArray.push(newObj);
                          }else{
                            let newObj = { text: option };
                            if(!this.forms[i].optionalValuesRequired || (this.forms[i].optionalValuesRequired && !this.forms[i].optionalValues)) {
                              newObj["value"] = option;
                            }
                            else {
                              if(Object.keys(this.forms[i].optionalValues).includes(option) && this.forms[i].optionalValues[option].trim().length > 0) {
                                newObj["value"] = this.forms[i].optionalValues[option];
                              }
                              else {
                                newObj["value"] = option
                              }
                            }
                            AdminfinalArray.push(newObj);
                          }
                        });
                        if(this.isLegacySetting) {
                          this.forms[i].options = AdminfinalArray;
                          AdminfinalArray = [];
                        } else {
                          this.dropDownOptions[this.forms[i].optionsValue] = AdminfinalArray;
                          this.forms[i].options = this.forms[i].optionsValue;
                          AdminfinalArray = [];
                        }
                      }
                }
              }


              //Setting the initial isBoxRequired
              if (this.instructions[0].isIDCardBoxRequired) {
                this.isBoxRequired = true;
              } else {
                this.isBoxRequired = false;
              }

              //Checking for autoTimeoutForRE
              if (this.instructions[0].automate) {
                //Clearing the dialog if already present
                this.userOverlayRequired = false;
                let ttl = parseInt(this.instructions[0].waitTime);
                if (parseInt(ttl)) {
                  ttl = ttl * 1000;
                } else {
                  ttl = 10 * 1000;
                }
                if (this.instructions[0].isScreenshotRequired) {
                  let noOfScreens = Math.floor(ttl / 8000);
                  for (let i = 0; i < noOfScreens; i++) {
                    setTimeout(() => {
                      this.processScreen();
                      setTimeout(() => {
                        this.saveScreenshot();
                      }, 2000);
                    }, 5000);
                  }
                }
                setTimeout(() => {
                  this.navigate(1);
                }, ttl);
              }
            }

            //Checking for instruction count
            if (this.instructions && this.instructions.length == 1) {
              this.isStart = false;
              this.isFinish = true;
            }

            // Starting the heatbeat initially only
            if (!this.heartbeatStarted) {
              this.heartbeatStarted = true
              this.initiateHearbeat();
              setTimeout(() => {
                this.initialHeartbeatFailure = false;
              }, 1000 * 10);
            }
            //Wokring on sms listeners
            sms.on("setLocation", function (data) {
              const geoProperties = Object.keys(data.geo);
              const browserProperties = Object.keys(data.browser);
              if (geoProperties.length && browserProperties.length) {
                selfIns.info = data.geo.city;
                eventBus.$emit("vueLoader", false);
                store.setData("geo", JSON.stringify(data.geo));
                store.setData("browser", JSON.stringify(data.browser));
                selfIns.browserData = data.browser;

                sms.emit("locationDataAck", true);
              }
            });

            sms.on("fromAndroidApp", ()=>{
              this.androidApp = true;
            });

            sms.on('endUserPing', (data) => {
              sms.emit('REPong', {timeStamp: Date.now()});
            })

            // enabling the rejoin button again
            sms.on("rejoined", (data) => {
              this.rejoining = false;
              eventBus.$emit("vueLoader", false);
              this.guardRejoinButton();
            })

            sms.on("endUserLeftRoom", () => {
              signzyStreamService.reconnectRoom().then(()=>{
                sms.emit("RERejoined");
              }).catch((err)=>{
                console.error("Error while reconnecting to the room from RE's end! ", err);
                this.addLogToReport("Error while reconnecting to the room for p2p rejoin!");
              })
            })

            sms.on("endUserMessage", (data) => {
              try {
                data.uploaded = true;
                data.viewed = true;
                this.chatMessages.push(data);
                if(this.currentClickedElement != 'Chat') {
                  this.showNotificationIcon = true;
                }
                sms.emit("endUserMsgAck",data.index);
              } catch(err) {
                console.error("Error in receiving message", err);
              }
            });

            sms.on("bankerMsgAck", (index) => {
              try {
                this.chatMessages[index].uploaded = true;
                this.notViewedMsgs.push(index);
              } catch(err) {
                console.error("Error ack", err);
              }
            });

            sms.on("bankerMessageSeenAck", () => {
              try {
                for(let i = 0 ; i < this.notViewedMsgs.length ; i++) {
                  this.chatMessages[this.notViewedMsgs[i]].viewed = true;
                }
                this.notViewedMsgs = [];
              } catch(err) {
                console.error("Error ack", err);
              }
            });

            sms.on("reporting", (data)=>{
                sessionStorage.setItem('initiationId', data.initiationReportId)
                console.log("initiation Id",data.initiationReportId)
                try {
                  let initiationId = data.initiationReportId
                  let formData = { ...this.logObject.initiationIdReceived };
                  formData.currentProcess = "Call connected, RE recieved the id";
                  formData.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,formData);
                }
                catch(err) {
                  console.log(err);
                }
            });

            sms.on("capturingScreenshotFailed", () => {
              this.imageFailed = true
              this.croppingFinished = true;
              window.clearTimeout(this.screenshotTimeout);
              eventBus.$emit("vueLoader", false);
              this.screenshot = true;
            });

            sms.on("capturedScreenshot", async (data) => {
              console.log("image link from end user", data);
              this.screenshotPreviewSrc = "";
              if(cropper){
                cropper.destroy();
              }
              if(this.zoomFun) {
                document.getElementById("capturedSS").dispatchEvent(new CustomEvent('wheelzoom.destroy'));
              }
              this.cropperCheck = false;
              this.imageFailed = false;
              this.croppingFinished = false;
              this.waitForClientToSendImage = false;
              let imageDataResp = await downloadFile(data);
              if (imageDataResp.status === 200) {
                this.screenshotPreviewSrc = `data:${imageDataResp.headers["content-type"].split(";")[0]};base64, ${imageDataResp.data.file}`;
                this.imageFailed = false;
                this.croppingFinished = false;
              }
              window.clearTimeout(this.screenshotTimeout);
              eventBus.$emit("vueLoader", false);
                // this.screenshot = true;
              this.screenshot = true;
              setTimeout(()=>{
                this.enableCrop();
              },300);
            });
            sms.on("pong", (data) => {

              // Adding a buffer of 3 seconds in heartbeat
              if(data.pong  <= (Date.now() + 3000)) {
              // Clearing ping pong checker timer
                window.clearTimeout(this.pingPongTimer);
                this.pingSent = false;
                this.pingResponse = true;
                this.heartbeatFailed = false;
                this.showDropPromptHeartbeat = false;
                this.isEndUserCameraDisabled ? this.allowPixelCheck = false : this.allowPixelCheck = true;
                sms.emit("heartbeatWorking");
              } else {
                this.pingSent = false;
                this.initiateHeartbeatDrop();
              }
            })
            sms.on("locationDenied", (data) =>{
                if(data.check){
                  this.locationDenied = true;
                  this.videoErrorMessage = "It looks like the End user has turned off the location sharing."
                }
                else{
                  this.locationDenied = false;
                }
            });

            //when customer mutes mic showing notification to banker
            sms.on("customerAudioMuted", (data) => {
              this.isCustomerMuted = data;
              if(this.isCustomerMuted) {
                setTimeout(() => {
                  this.isCustomerMuted = false;
                  this.showMicMuted = true;
                }, 2000);
                setTimeout(() => {
                  this.showMicMuted = false;
                }, 4000);
              }
            });

            sms.on("presenterAlive", (data) => {
              // Checking if heartbeat is working after user enters
              // if not, then start it.
              this.initialHeartbeatFailure = false;
              if (!this.heartbeatStarted) {
                this.heartbeatStarted = true
                this.initiateHearbeat();
              }
              this.handshakeSucessfull = true;
              // clearTimeout(this.handshakeChecker);
              clearInterval(this.adminALiveInterval);
              console.log("Presenter came!!");
              if (!this.pixelDetectInterval) {
                this.pixelDetectInterval = setInterval(() => {
                  if (this.allowPixelCheck) {
                    this.getAverageRGB();
                  }
                }, 5000)
              }
              this.presenterCame = true;
              this.disableMuteBtn = false;
              if (this.disableBeginVCIP) {
                setTimeout(() => {
                  if(this.ipData.city==="Loading.."){
                    this.waitForLocation(10000); //wait 5 sec more considering if location is still fetching
                  }else{
                    this.disableBeginVCIP = false;
                  }
                }, 4000);
              }
              store.setData("startTime", Date.now());
              sms.emit("startRecording", {});
              sms.emit("giveMeSpeed", {});
              sms.emit("giveMeLocation", {});
              sms.emit("giveMeLanguage", {});
              //get consent timestamp
              sms.emit("getConsentTimestamp", {});

              //Handling restarts
              if (this.isRecordingInProgress) {
                console.log("[!] Presenter reloaded, restarting recording!");
                setTimeout(() => {
                  signzyStreamService.workOnArrayStream(this.restartRecording);
                }, 500);
              }
            });

            sms.on("internetSpeed", (data) => {
              if (false) {//speed test blocked as it is cloaking the network in chrome
                this.internetSpeed = data;
                if (data && data.status && data.status === "failed") {
                  // console.log("Error in getting internet speed for end user");
                  this.speedThresholdFailed = false;
                  this.showKickoutMessage = false;
                  this.endUserSpeedFailed = true;
                  this.endUserSpeedLoading = false;
                  sms.emit("networkSpeedFine");
                } else {
                  this.changeStyle(data, 'EU');
                  this.endUserSpeedLoading = (data && data.Download) ? false : true;
                  if (parseInt(this.internetSpeed.speedKbps) < parseInt(this.speedThreshold)) {
                     console.log("Got end users internet speed");
                     try {
                           let initiationId = sessionStorage.getItem('initiationId');
                           let formData = {};
                           formData.currentProcess = "End user's connection is too slow (notification sent)";
                           formData.callId = this.requestId ? this.requestId : "";
                           logReportData(this.socket,initiationId,formData);
                      }
                      catch(err) {
                      console.log(err);
                    }
                    if (this.speedThresholdFailed) {
                      this.showKickoutMessage = true;
                      sms.emit("networkSpeedTooSlow");
                    } else {
                      this.speedThresholdFailed = true;
                      this.videoErrorMessage = "End user's connection is too slow, please wait while we try to reconnect.";
                    }
                  } else {
                    this.speedThresholdFailed = false;
                    this.showKickoutMessage = false;
                    sms.emit("networkSpeedFine");
                  }
                }
              } else {
                console.log("All checks have been stopped");
              }
            });

            sms.on("ipData", function (data) {
              // removing this to avoid conjestion and decrese load time
              // TODO : console.log() should be configurable everywhere
              // console.log("Location Data::", data);
              if (data) {
                if(data.regionName==="N/A"){
                  console.log("End User geolocation API failed");
                  selfIns.ipData.city = "Loading.."; //take it as a failure
                }else{
                  selfIns.ipData = data;
                }
              }
            });
            sms.on("remoteCameraTweak", (data) => {
              this.allowPixelCheck = !data.flag;
              this.isEndUserCameraDisabled = data.flag;
              if(this.enableChime) {
                this.removeVideoMuted = data.flag;
              }
            })

            sms.on("cameraToggling", (data) => {
               try {
                  this.disableBtn = true;
                  setTimeout(()=>{
                    this.disableBtn = false;
                  },2000)
                 if(data.check){
                  //TODO: Add this pop up later
                  // eventBus.$emit("vueSnack", "End user switched their camera!");
                  let initiationId = sessionStorage.getItem('initiationId');
                  let formData = {};
                  formData.currentProcess = "camera toggle";
                  formData.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,formData);}
                }
                catch(err) {
                  this.disableBtn = false;
                  console.log(err);
                }
              this.allowPixelCheck = !data.check
            })

            sms.on("language", function (data) {
              // console.log("---language", data);
              selfIns.language = data.language;
            });

            sms.on("minimizedWindow", (data) => {
              console.log("End users hidden", data);
              if (data.minimized) {
               try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let formData = {};
                  formData.currentProcess = "end user has minimized Window ";
                  formData.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,formData);
                }
                catch(err) {
                  console.log(err);
                }


                this.videoErrorMessage = "It looks like the user is away from the application... Please wait while we notify the user.";
              }else{
                 try {
                  let initiationId = sessionStorage.getItem('initiationId');
                  let formData = {};
                  formData.currentProcess = "end user window was not minimized ";
                  formData.callId = this.requestId ? this.requestId : "";
                  logReportData(this.socket,initiationId,formData);
                }
                catch(err) {
                  console.log(err);
                }
              }
              this.endUserMinimized = data.minimized;
              this.allowPixelCheck = !data.minimized;
            });

            sms.on("screenSharePermission", function (data) {
              if (data.email) {
                selfIns.showPermissionDialog = true;
                selfIns.permissionEmail = data.email;
              }
            });
            sms.on("presenterLeft", (data) => {
              this.initiateDropOut("presenterLeft");

            });

            sms.on("sendConsentTimestamp", (data) =>{
              if(data){
                this.consentData["consentTimestamp"] = data;
              }
            });

            sms.on("forensicsData", function (data) {
              const forensicProperties = Object.keys(data);
              if (forensicProperties.length) {
                selfIns.forensicsData = data;
                store.setData("forensicsData", JSON.stringify(data));

                sms.emit("forensicsDataAck", true);
              }
            });

            sms.on("agentFeedbackCapturedAck", ()=> {
              eventBus.$emit("vueLoader", false);
              console.log("Ack received from the end user for agent feedback");
              // this.agentFinalFeedbackPicker=false;
              this.finish();
            });

            // sms.on("callDequeued", (data) => {
            //   if(data) {
            //     this.showInactivePopup = false;
            //     this.stayOnCall = true;
            //   }
            // })

            //hav a look below
            window.onbeforeunload = () => {
              //Hit API CALL;
              if (!this.callDropInitiated) {
                sms.emit("adminLeft", {});
                
                this.unloadEvent()
                .then(()=>{
                  if(this.isCallStatusSent) {
                  this.socket.emit("RElogout", {...this.$store.getters.userData, loggerId: localStorage.getItem("loggerId")});
                  localStorage.removeItem("loggerId");
                  this.isCallStatusSent = false;
                } else { //if status is not sent it will update the status(unsuccessful call) and mark RE as logout
                  let { customerData, userId } = this.$store.getters.userData;
                  this.socket.emit("RELeftTheCall", {customerData, userId, loggerId: localStorage.getItem("loggerId")});
                  // this.socket.emit("RELeftTheCall", {...this.$store.getters.userData, loggerId: localStorage.getItem("loggerId")});
                  // eventBus.$emit("RELeftTheCall", {...this.$store.getters.userData, activity: "RELeftTheCall", loggerId: localStorage.getItem("loggerId")});
                  localStorage.removeItem("loggerId");
                }
                }).catch((error)=>{
                  console.log("Error during unloadEvent:", error);
                });
                //checks if the call status is already sent to report worker or not
              }
              return null;
            };

            if (!this.isVideoConference) {
              this.adminALiveInterval = setInterval(() => {
                console.log("Sending Admin Alive Envent !!");
                sms.emit("adminAlive", {});
              }, 1000);
            }

          } else {
            this.$router.replace({
              path: "/error/expired",
            });
          }
        } else {
          this.noActiveSession = false;
        }
      } catch (error) {
        console.log(error);
        //redirect to random page
      }
    }
  },
  watch: {
    disableSaveBtn(value){
      if(value === true){
        eventBus.$emit("persistedLoader", true);
      }
      if(value === false){
        eventBus.$emit("persistedLoader", false);
      }
    }
  },
  props:["socket"]
};
</script>
<style scoped>
.activeMenu {
  background: #212121;
  border-right: 4px solid #0184c4;
}
.hide{
  display: none;
}
.x:hover + .hide{
  display: block;
}
.quickActions {
  width: 50px;
  height: 50px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.hide{
  display: none;
}

.x:hover + .hide {
  display: block;
}

.captureImageHover {
  display: none;
}
.captureImageIn:hover .captureImageHover {
  display: block;
}
.helperBar {
  /* background: #242835; */
  color: white;
}

.input_hidden {
  position: absolute;
  left: -9999px;
}

.selected {
  background-color: #323545;
}

.ticker {
  transition: 0.5s ease;
  opacity: 0.5;
  text-align: center;
}

#sites label {
  display: inline-block;
  cursor: pointer;
}

#sites label:hover {
  background-color: #878a96;
}

#sites label img {
  padding: 3px;
  height: 200px;
}
#reflection {
  width: 100%;
}
#recordingPreview {
  width: 100%;
}



.v-list-item__title{
  white-space: pre-line !important;
}


.removeVideo {
  background: black;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  position: relative;
}
.recorderIndicator {
  position: absolute;
  top: 2%;
  left: 1.5%;
  background-color: rgba(49 ,53 ,69 ,0.75);
  border-radius: 10px;
}
.indicatorContainer {
  display: flex;
  align-items: center;
}
.roundIndicator {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0 0pt 2pt 1pt white;
  animation: glowShadow 1.5s linear infinite alternate;
}
@keyframes glowShadow{
  to {
      box-shadow: 0 0pt 0pt 0pt white;
  }
}
.video-error-message {
  position: absolute;
  left: 2%;
  top: 2%;
}
#vid2 {
  height: 90vh;
}

/* .v-dialog__content {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: 0.2s cubic-bezier(0.25, 0.8, 0.25, 1), z-index 1ms;
  width: 75%;
} */

/* .v-dialog__content .v-card__title {
  word-break: break-word;
} */
.inactiveMessage {
  margin-bottom: 16px;
  color: white;
  position: absolute;
  left: 25%;
  font-size: 20px;
}
.v-sheet.v-list {
    border-radius: 0;
    word-wrap: break-word;
}
.loader {
  color: #ffffff;
  font-size: 30px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background:transparent;
}

::-webkit-scrollbar-thumb {
  background: #CCC;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#speedBarTitle, #videoPreview{
  font-family: 'Arial';
  font-style: normal;
  font-weight: 550;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.63);
  text-decoration: underline;
  text-align: left;
}

#speedBarSubtitle {
  font-family: 'Arial';
  font-style: normal;
  font-weight: 550;
  font-size: 13px;
  color: #000000;
}

#speedBarMsg {
  font-family: 'Arial';
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  margin: auto;
  padding-top: 5%
}

#cancelRejoin {
  width: 300px;
  margin-top: 125px;
  background-color: black;
}

#cancelRejoin:hover {
  background-color: red;
}


.good {
    filter: none;
    filter : invert(50%) sepia(14%) saturate(1527%) hue-rotate(100deg) brightness(120%) contrast(90%);
    margin: 0;
    font-size: 12px;
  font-weight: 550;
}

.average {
    filter: none;
    filter : invert(50%) sepia(89%) saturate(631%) hue-rotate(10deg) brightness(120%) contrast(100%);
    margin: 0;
    font-size: 12px;
  font-weight: 550;
}

.poor {
    filter: none;
    filter :invert(36%) sepia(59%) saturate(6582%) hue-rotate(348deg) brightness(85%) contrast(150%);
    margin: 0;
    font-size: 12px;
  font-weight: 550;
}

.loading {
    filter: none;
    filter : invert(37%) sepia(40%) saturate(2696%) hue-rotate(174deg) brightness(75%) contrast(101%);
    margin: 0;
}
.videoPreview {
  text-align: center;
}

.deviceSelection {
  display : flex;
  padding: 0px;
  height: 76px;
  z-index: 100000;
}

.faceMatchTitle {
  font-weight : bold;
  text-align : left;
  padding-left: 12px;
  font-family: Roboto,sans-serif;
}

.faceMatchValues {
  text-align : left;
  padding-left: 12px;
  font-family: Roboto,sans-serif;
}

.faceMatchDetails {
  max-width : 170px;
}

.imageSelector {
  display : flex;
  align-items: center;
  justify-content: center;
}

.agent-feedback-text{
  font-size: 24px;
  padding: 0px 10px;
}

</style>
<style>
.viewer-button {
    -webkit-app-region: no-drag;
    background-color: rgb(228,80,66);
    border-radius: 50%;
    cursor: pointer;
    height: 80px;
    overflow: hidden;
    position: absolute;
    right: -40px;
    top: -40px;
    transition: background-color 0.15s;
    width: 80px;
}

.hangup {
  transform: rotate(225deg);
}
.pdf-app #outerContainer {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0 !important;
}
</style>
