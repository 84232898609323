var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"align-content-end flex-wrap login-bg hidden-sm-and-down d-none d-md-flex d-lg-flex d-xl-flex",attrs:{"lg":"8","md":"8","sm":"0"}},[_c('div',{staticClass:"lower-description"},[_c('p',{staticClass:"display-2"},[_vm._v("Welcome to Video Conference")]),_c('p',{staticClass:"headline"},[_vm._v("Update your password to proceed further")])])]),_c('v-col',{attrs:{"lg":"4","md":"4","sm":"12","align":"center"}},[_c('div',{staticClass:"login-panel"},[_c('div',{staticClass:"login-form"},[_c('label',{attrs:{"for":"password1"}},[_vm._v(" Enter new Password "),_c('Password',{attrs:{"label":"Enter new Password","name":"password1","toggle":true,"badge":false,"secureLength":7,"outlined":""},model:{value:(_vm.password1),callback:function ($$v) {_vm.password1=$$v},expression:"password1"}})],1),(_vm.password1.length < 8)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Minimum of 8 characters required")]):_vm._e(),(!/[a-z]/g.test(_vm.password1))?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Lowercase characters are required")]):_vm._e(),(!/[A-Z]/g.test(_vm.password1))?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Uppercase characters are required")]):_vm._e(),(!/[0-9]/g.test(_vm.password1))?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Digits are required")]):_vm._e(),(!/[\_\$\#\@\^\!\*\+\)\(\{\}\'\\\?\/\<\>\.\, \^\[\]]/g.test(_vm.password1))?_c('p',{staticStyle:{"color":"red"}},[_vm._v("Symbols _$#@^!*+)({}'\\?/<>.,^[] are required")]):_vm._e(),_c('v-btn',{attrs:{"rounded":"","color":"primary","block":"","disabled":_vm.password1.length < 8 ||
            !/[a-z]/g.test(_vm.password1) ||
            !/[A-Z]/g.test(_vm.password1) ||
            !/[0-9]/g.test(_vm.password1) ||
            !/[\_\$\#\@\^\!\*\+\)\(\{\}\'\\\?\/\<\>\.\, \^\[\]]/g.test(
              _vm.password1
            )},on:{"click":_vm.updatePass}},[_vm._v("Proceed")])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }